<div class="container">
  <div class="footer">
    <div class="d-flex h-100 justify-content-center justify-content-md-between no-gutters row text-center text-md-left">
      <div class="pt-4">
        <span class="footer-rep">  بإشراف  <img src="assets/images/real-estate-logo.png" class="pr-2" alt="thiqah-logo"></span>
        <div class="footer__copy-rights  pt-2"> جميع الحقوق محفوظة &copy; الهيئة العامة لعقارات الدولة {{date}} </div>
      </div>
      <div class="pt-4">
        <span class="footer__social-title"> يمكنك متابعتنا عبر قنوات التواصل </span>

        <div class="footer__social-icons pt-2">
          <!-- <a href="">
              <i class="icon icon-at"></i>
          </a> -->
          <a href="https://youtu.be/Y6lEVwIMw4c" target="_blank">

            <i class="icon icon-youtube"></i>
          </a>
          <a href="https://twitter.com/ehkaam_sa?s=11" target="_blank">
            <i class="icon icon-twitter"></i>
          </a>
          <!-- <a href="">
              <i class="icon icon-linkedin"></i>
          </a>
          <a href="">
              <i class="icon icon-instagram"></i>
          </a> -->


        </div>
      </div>

    </div>
  </div>
</div>
