import { mapEnumToOptions } from '@abp/ng.core';

export enum LogLevel {
  Fatal,
  Errors,
  Warnings,
  Notification,
  Info,
  Debug,
}

export enum LanguageEnum {
  English = 'en',
  Arabic = 'ar',
}
export enum RequestPreviewTabs {
  InspectionMember = "InspectionMember",
  TechnicalMember = "TechnicalMember",
  ShariaMember = "ShariaMember",
  SPGAPublicUser = "SPGAPublicUser",
  AdminMember = "AdminMember",
  MOJ = "MOJ",
  EngineeringOffice = "EngineeringOffice",
  CommitteeSecretary = "CommitteeSecretary",
  AuditCommitteeSecretary = "AuditCommitteeSecretary",
  SpecializationStudyMember = "SpecializationStudyMember",
  PreliminaryStudiesCommitteeSupervisor = "PreliminaryStudiesCommitteeSupervisor",
  RequestsStudyingMember = "RequestsStudyingMember",
  FormalGovernamentalNotes = "FormalGovernamentalNotes",
  Objection = "Objection",
  RequestTechnicalLegitimateNotes = "RequestTechnicalLegitimateNotes",
}
export enum CommitteeSecretary{
  FormalCommitteeSecretary,
  GovernmentCommitteeSecretary,
  AwqafCommitteeSecretary,
  NormalCommitteeSecretary
}

export enum isStatementanswered{
  Answered = "true",
  NotAnswered = "false"
}

export enum InternalRequestStatus{
  Draft,
  Pending
}
export enum AdminEngOfficeStatementInternalRequestStatus{
  Draft,
  Pending,
  IsThisAttachementIssue
}
export enum EngOfficeStatementInternalRequestStatus{
  Pending,
  IsThisAttachementIssue
}

export enum CompleteTechnicalRequests{
  Complete=1
}

export enum CompleteShariaRequests{
  Complete=1
}


export enum RequestAreaFilter{
        From0To2500 = 1,
        From0To5000 = 2,
        From2500 = 3,
        From5000 = 4
}

export enum StatementAdministrationNotesEnum{
        InspectionMemberNotes = 1000,
        InspectionSupervisorNotes = 1001,
        TechnicalMemberNotes = 1002,
        ShariaMemberNotes = 1003,
        FormalCommitteeSecretaryNotes = 1004,
        GovernmentCommitteeSecretaryNotes = 1005,
        AwqafCommitteeSecretaryNotes = 1006,
        NormalCommitteeSecretaryNotes = 1007,
        AuditCommitteeSecretaryNotes = 1008,
        SpecializationStudyMemberNotes = 1009,
        SupervisorOfThePreliminaryStudiesCommitteeNotes = 1010,
        RequestsStudyingMemberNotes = 1011
}

export enum StatementAdministrationInternalEnum{
        InspectionSupervisor = 2000,
        FormalCommitteeSecretary = 2001,
        GovernmentCommitteeSecretary = 2002,
        AwqafCommitteeSecretary = 2003,
        NormalCommitteeSecretary = 2004,
        AuditCommitteeSecretary = 2005,
        SupervisorOfThePreliminaryStudiesCommittee = 2006
}

export enum AttachmentsDescriptions {
  NationalId = 'الهوية الوطنية لممثل صاحب الطلب',
  LegalEntityCopy  = 'صورة من سجل الجهة الاعتبارية',
  AgentOrAttorney = 'بيانات الوكيل او المحامي',
  LegalAgency = 'الوكالة الشرعية',
  SurveyReport = 'التقرير المساحي',
  DeedOfAllegiance = 'صك مبايعة',
  ProofOfRubble = 'صك اثبات الأنقاض',
  deedDeed = 'صك خصومة',
  DeedOfPeace = 'صك صلح',
  ProofOfCompetence = 'صك اثبات الاختصاص',
  Settlement = 'الاستيطان',
  AdjacentInstrument = 'صك مجاور',
  TemporaryTitleDeed = 'صك ملكية مؤقتة',
  GrantOrder = 'أمر منح/إقطاع',
  AgriculturalDecision = 'قرار زراعي',
  ServiceReceiptNotice = 'اشعار إيصال خدمات',
  MunicipalLicense = 'ترخيص البلدية',
  GiftDocument = 'وثيقة هبة',
  SaleDocument = 'وثيقة بيع',
  WaiverDocument = 'وثيقة تنازل',
  PartitionDocument = 'وثيقة تقسيم',
  TribalPeaceDocument = 'وثيقة صلح قبلي',
  DivisionPapers = 'أوراق قسمة',
  Other = 'أخرى'
}

export const isStatementansweredOptions = mapEnumToOptions(isStatementanswered);
export const internalRequestStatusOptions = mapEnumToOptions(InternalRequestStatus);
export const adminEngOfficeStatementInternalRequestStatusOptions = mapEnumToOptions(AdminEngOfficeStatementInternalRequestStatus);
export const completeTechnicalRequestsOptions = mapEnumToOptions(CompleteTechnicalRequests);
export const completeShariaRequestsOptions = mapEnumToOptions(CompleteShariaRequests);
export const engOfficeStatementInternalRequestStatusOptions = mapEnumToOptions(EngOfficeStatementInternalRequestStatus);
export const requestAreaFilterOptions = mapEnumToOptions(RequestAreaFilter);
export const statementAdministrationNotesEnumOptions = mapEnumToOptions(StatementAdministrationNotesEnum);
export const statementAdministrationInternalEnumOptions = mapEnumToOptions(StatementAdministrationInternalEnum);
export const RolesOptions = mapEnumToOptions(CommitteeSecretary);
export const attachmentsDescriptions = mapEnumToOptions(AttachmentsDescriptions);
