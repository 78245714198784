
export interface BillPaymentRequest {
  accessToken: string;
  applicationReference: string;
  billReference: string;
  requestTimeStamp: number;
  returnUrl: string;
  signature: string;
}

export interface BillSettingsDto {
  generateBillsForPublicUsers: boolean;
  generateBillsForEngineeringOffices: boolean;
}

export interface BillsSettingsDto extends BillSettingsDto {
  engineeringOfficeBillDto: EngineeringOfficeBillDto;
  publicUserBillDto: PublicUserBillDto;
}

export interface EngineeringOfficeBillDto {
  billAmount: number;
}

export interface PublicUserBillDto {
  formalRequestsBillAmount: number;
  agriculturalRealEstateBillAmount: number;
  minimumSizeToIssueAnInvoice: number;
  minimumBillAmount: number;
  maximumBillAmount: number;
  increaseValuePerMeter: number;
}
