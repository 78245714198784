<div class="card published-card">
    <div class="published-card__date-type">
        <div class="published-card__date">
            <i class="icon icon-calendar"></i>
            <div>{{getFormattedDate(singleAd?.publishingDate)}}</div>
            <div>تاريخ الاعلان</div>
        </div>
        <div class="published-card__type">
            <i class="icon icon-calendar"></i>
            <div>{{getFormattedDate(singleAd?.adsEndDate)}}</div>
            <div> تاريخ انتهاء الاعلان</div>
        </div>
    </div>
    <img class="card-img-top" src="assets/images/ad-bg.svg" alt="Card image cap">
    <div class="card-body">
        <h2 class="published-card__id">رقم الطلب: {{singleAd?.requestId}}</h2>
        <p class="published-card__time">المدة المتبقية: {{singleAd?.remainingDays}} يوم</p>
        <p class="published-card__buildingtype">صاحب الطلب: {{singleAd?.ownerName}}</p>
        <p class="published-card__location">
            <span class="region d-inline-block mr-5">المنطقة: {{singleAd?.regionName}}</span>
            <span class="city">المدينة: {{singleAd?.cityName}}</span>
        </p>
        <p class="published-card__buildingtype">نوع العقار: {{ ('::Enum:RealEstateTypeEnum:' + singleAd?.realEstateType)|abpLocalization }}</p>
        <p class="published-card__area" *ngIf="singleAd?.area">مساحة العقار: {{singleAd?.area}} متر</p>
        <!-- <a href="" class="download-btn btn"  (click)="openGoogleMapTab()" target="_blank">استعراض العقار على الخريطة</a> -->
        <!-- <br> -->
        <button class="download-btn btn" (click)="downloadRealestateLimitsAttachment()">الإطلاع على حدود وموقع العقار</button>
        <br>
        <button class="download-btn btn" (click)="downloadArealReportAttachment()">للإطلاع على الرفع المساحي</button>
        <a class="btn btn-primary card-btn" (click)="openObjection()">اعتراض</a>
        <!-- <div class="alert alert-warning" role="alert" *ngIf="!isLoggedIn">لتقديم طلب اعتراض يجب ان تقوم بتسجيل الدخول</div> -->
    </div>
</div>
