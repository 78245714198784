import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ABP, ConfigStateService, LocalizationService, PagedAndSortedResultRequestDto, PagedResultDto, PermissionService } from '@abp/ng.core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Repository } from '@data/repository/repository';
import { Observable, Subscription } from 'rxjs';
import { filter as rxjsFilter, finalize } from 'rxjs/operators';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import Status = Confirmation.Status;
import {  UserRepository } from '@data/repository';
import { AdminRoles } from '../../proxy/enums';
import { InputRestrictionDirective } from '@spga-shared/directives';
import { SpgaCaptcha } from '../../models/spgaCaptcha';
import { CaptchaActions, CaptchaNotifier } from '../../services/captchaNotifier';
export enum ColumnTypes {
  Text = 'text',
  Email = 'email',
  Number = 'number',
  Boolean = 'Boolean',
  Enum = 'Enum',
  Lookup = 'Lookup',
  Date = 'Date',
  Icon = 'Icon',
  Captcha= 'Captcha'
}

export interface ILookup<T, L = any> {
  getFn?: () => Observable<any>;
  onChangeFn?: (id: string | number) => Observable<any>;
  enum?: ABP.Option<L>[];
  enumName?: string;
  labelField?: Extract<keyof L, string>;
  valueField?: Extract<keyof L, string>;
  childLookup?: IChildLookup<T>[];
  placeholder?: string;
}
export interface IChildLookup<T>{
  enumValue?:number;
  childLookup?:Extract<keyof T, string>;
}

export interface IFilter<T, F, L = any> extends ILookup<T, L> {
  type?: 'single' | 'multi' | 'date';
  field: Extract<keyof F, string> | (Extract<keyof F, string>)[];
  title?: string;
  childFilter?: Extract<keyof F, string>;
  defaultValue?: any | any[];
  isEditable?: boolean;
  requiredPermission?: string;
  condition?:boolean;
  isDateSingle?:boolean;
}

interface IComponentLookup {
  data: any[];
  onChange?: (input: any) => Observable<any>;
}

// tslint:disable-next-line:no-empty-interface
interface IComponentFilter extends IComponentLookup {
}

interface ITableColumn<T> {
  field: Extract<keyof T, string>;
  formControlField?: Extract<keyof T, string>;
  title: string;
  iconClass?: string;
  extraClass?: string;
  type?: ColumnTypes;
  lookup?: ILookup<T>;
  placeholder?: string;
  gridSize?: number;
  order?: number;
  required?: boolean;
  requiredCondition?: { parentField: Extract<keyof T, string>, value: any, hideIfNotMeet?: boolean, isArray?: boolean };
  input?: Partial<HTMLInputElement> & { numbersOnly?: boolean };
  validators?: ValidatorFn[];
  requiredPermission?: string;
  hideFromTable?: boolean;
  iconTagClass?: string;
  iconClassFn?: (item: T) => string;
}

interface IBulkAction<T> {
  title?: string;
  iconClass?: string;
  requiredPermission?: string;
  actionFn?: (input?: any) => Observable<any>;
  onSelected?: boolean;
  confirmation?: {
    title: string;
    message: string;
    yesText?: string;
    cancelText?: string;
  };
  buttonClass?: string;
}

export interface IActionButton<T> {
  title?: string;
  titleFn?: (item: T) => string;
  iconClass?: string;
  route?: string;
  paramField?: Extract<keyof T, string>;
  requiredPermission?: string;
  conditionFn?: (item: T) => boolean;
  actionFn?: (input?: any) => Observable<any>;
}

export interface IListComponentOptions<T, F = any> {
  title?: string;
  subTitle?: string;
  actions?: {
    canCreate?: boolean | string;
    canEdit?: boolean | string;
    canDelete?: boolean | string;
    buttons?: IActionButton<T>[];
    exportToExcel?: (input: any) => Observable<any>
    hasExportPermission?: boolean | string;
  };
  bulkActions?: {
    selectedCountText?: string;
    actions: IBulkAction<T>[];
    requiredPermission?: string;
  };
  modalTitles?: {
    add: string;
    edit: string;
  };
  buttons?: {
    iconClass?: string;
    add: string;
    edit: string;
  };
  baseButton?: IActionButton<T>;
  idField?: Extract<keyof T, string>;
  params?: any;
  isIdEditable?: boolean;
  hasCaptcha?: boolean;
  columns: ITableColumn<T>[];
  formGridSize?: number;
  search?: {
    field: Extract<keyof F, string>;
    placeholder: string;
    requiredPermission?: string;
    mustSearch?: boolean;
    mustSearchText?: string;
    maxLength?: number;
  };
  filters?: IFilter<T, F>[];
  filtersRequiredPermission?: string;
  hideFilters?: boolean;
  iconsGuides?: {
    requiredPermission?: string;
    guides: {
      iconClass: string;
      title: string;
    }[];
    hideFromTable?: boolean;
  };
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent<T, I, C, F extends PagedAndSortedResultRequestDto> implements OnInit {
  @Input() repository!: Repository<T, I, C, F>;
  @Input() pageSize = 10;
  @Input() options!: IListComponentOptions<T, F>;
  @Input() notFoundMessage = null;
  @Output() checkedIdsList = new EventEmitter<any[]>();
  @Output() isGetListDone = new EventEmitter<boolean>();
  genercNotFound = ' لا يوجد بيانات'
  @ViewChild('popupRef') popupRef: any;
  stopedId: any;
  toSupervisorId: any;
  oldItem: any;
  orderedColumns?: ITableColumn<T>[];
  isSupervisorsModalOpen = false;
  isSupervisor = false;
  isDeleteModalOpen = false;
  pagedResult = { items: [], totalCount: 0 } as PagedResultDto<T>;
  page = 1;
  isModalOpen = false;
  params = new PagedAndSortedResultRequestDto({ maxResultCount: this.pageSize }) as F;
  form?: UntypedFormGroup;
  lookups: { [key: string]: IComponentLookup } = {};
  filters: { [key: string]: IComponentFilter } = {};
  searchForm?: UntypedFormGroup;
  isEditMode = false;
  columnTypes = ColumnTypes;
  idToEdit?: string | number;
  onGoingRequest?: Subscription;
  isLoaded = false;
  checkedIds: any[] = [];
  isLoadingBulkAction = false;
  selectedCountText = 'عدد الطلبات المحددة';
  supervisorUsers: any[] = [];
  isCaptchaReady? = false;
  captcha: SpgaCaptcha | undefined;
  constructor(
    private config: ConfigStateService,
    private fb: UntypedFormBuilder,
    private confirmationService: ConfirmationService, private userRepository: UserRepository,
    private localizationService: LocalizationService,
    private permissionService: PermissionService,
    private captchaNotifier: CaptchaNotifier
  ) {
    //this.form!.addControl('toSupervisorId1', new FormControl('',null));
  }

  get f(): UntypedFormGroup['controls'] {
    return this.form!.controls;
  }

  get isEdit(): boolean {
    return this.options.isIdEditable ? this.isEditMode : !!this.idToEdit;
  }

  get hasActions(): boolean {
    return (this.options.actions && (this.options.actions.canEdit || this.options.actions.canDelete)) as boolean;
  }

  get hasEditableFilters(): boolean | undefined {
    return !this.options.hideFilters && this.options.filters && this.options.filters.some(x => x.isEditable === undefined || x.isEditable);
  }
  onCaptchaChanges($event: SpgaCaptcha) {
    this.captcha = $event;
    this.isCaptchaReady = $event.isCaptchaReady;
  }
  ngOnInit(): void {
    this.checkPermissions();
    this.params.maxResultCount = this.pageSize;
    if (this.options.isIdEditable === undefined) {
      this.options.isIdEditable = this.options.columns.some(x => (x.formControlField || x.field) === this.options.idField);
    }
    this.orderColumns();
    this.buildSearchForm();
    this.initFilters();
    this.getList();
  }

  checkPermissions(): void {
    if (this.options.actions) {
      const { canCreate, canEdit, canDelete } = this.options.actions;
      const actions: any = { canCreate, canEdit, canDelete };
      for (const action in actions) {
        if (actions.hasOwnProperty(action)) {
          if (typeof actions[action] === 'string') {
            (this.options.actions as any)[action] = this.permissionService.getGrantedPolicy(actions[action] as string);
          }
        }
      }
    }
  }

  orderColumns(): void {
    const columns = [...this.options.columns];
    columns.forEach((x, i) => {
      if (!x.order) {
        x.order = i + 1;
      }
    });
    this.orderedColumns = columns.sort((a, b) => {
      return a.order! > b.order! ? 1 : -1;
    });
  }

  isInputType(type?: ColumnTypes): boolean {
    return !type || [ColumnTypes.Text, ColumnTypes.Number, ColumnTypes.Email].indexOf(type) > -1;
  }

  isSelectType(type?: ColumnTypes): boolean {
    return type ? [ColumnTypes.Enum, ColumnTypes.Lookup].indexOf(type) > -1 : false;
  }

  getList(parm: F = this.params): void {
    if (this.repository && this.repository.getList && (!this.options.search?.mustSearch || (
      this.options.search?.mustSearch && this.searchForm?.controls.searchText.value
    ))) {
      if (this.onGoingRequest) {
        this.onGoingRequest.unsubscribe();
      }
      this.isLoaded = false;
      this.onGoingRequest = this.repository.getList({...this.options.params,...parm})
        .pipe(finalize(() => {
          this.isLoaded = true;
          this.onGoingRequest = undefined;
        }))
        .subscribe((result) => {          
          this.pagedResult = result;
          this.isGetListDone.emit(true);
        }, error => {
          console.log(error);
          this.isGetListDone.emit(false);
        });
    }
  }

  openCreateModal(item?: T): void {
    this.isEditMode = !!item;
    this.buildForm(item);
    this.loadLookups();
    this.isModalOpen = true;
    let roleId=this.form?.value.role;
    this.userRepository.getActiveInspectionSupervisors(roleId).subscribe((data) => {
      this.supervisorUsers = data;
    });
  }

  openEditModal(row: T): void {    
    if (!this.repository.get) {
      throw Error('Get method is undefined!');
    }
    this.repository.get((row as any)[this.options.idField as string]).subscribe(item => {
      this.oldItem = item;      
      this.openCreateModal(row );
    });
  }
  closePopUp() {
    //  this.isModalOpen=false
    this.oldItem = null;
    this.toSupervisorId = null;
  }


  buildForm(item?: T): void {
    const controlsConfig: { [key: string]: any } = {};
    this.options.columns.forEach(x => {
      const validators = x.validators || [];
      if (x.required) {
        validators.unshift(Validators.required);
      }


      const value = item ? item[x.formControlField || x.field] : undefined;
      controlsConfig[(x.formControlField || x.field)] = new UntypedFormControl(value, validators);
      const control = controlsConfig[(x.formControlField || x.field)] as UntypedFormControl;
      if (x.requiredCondition) {


        const { formControlField, field } = this.options.columns
          .find(y => (y.formControlField || y.formControlField) === x.requiredCondition?.parentField)!;
        const parentControl = controlsConfig[(formControlField || field)] as UntypedFormControl;
        parentControl?.valueChanges.subscribe((newValue: any) => {
          if (newValue && x.requiredCondition?.isArray && newValue === x.requiredCondition?.value.find((f: any) => f === newValue)) {

            control.setValidators([Validators.required, ...validators]);
            x.required = true;
          }
          else if (newValue === x.requiredCondition?.value) {

            control.setValidators([Validators.required, ...validators]);
            x.required = true;
          } else {
            control.setValidators([...validators]);

            x.required = false;
            if( x.formControlField === 'agencyType'){
              this.form?.controls?.agencyType?.clearValidators();
              this.form?.controls?.agencyType?.updateValueAndValidity();
              this.form?.controls?.agencyType?.setValue(undefined);
            }            
          }
        });
        parentControl.setValue(parentControl.value);
      }
    });
    this.idToEdit = item ? (item as any)[this.options.idField] as unknown as string : undefined;
    this.form = this.fb.group(controlsConfig);

  }

  buildSearchForm(): void {
    if (this.options.search) {
      this.searchForm = this.fb.group({
        searchText: ['']
      });
      this.searchForm.controls.searchText.valueChanges.pipe(rxjsFilter(value => !value)).subscribe(() => this.isLoaded = false);
    }
  }

  search(): void {
    (this.params as any)[this.options.search!.field] = this.searchForm!.controls.searchText.value;
    this.pageChange(1);
  }

  save(): void {
    if (this.isEdit) {
      const id = this.idToEdit;
      this.update(id!);
    } else {
      this.create();
    }
  }

  create(): void {
    if (this.form!.invalid || !this.options.actions?.canCreate) {
      return;
    }
    if (!this.repository.create) {
      throw Error('Create method is undefined!');
    }
    this.form!.value.captchaId= this.captcha?.codeId,
      this.form!.value.captchaValue= this.captcha?.code,
    this.repository.create(this.form!.value).subscribe(() => {
      this.reloadList();
      this.isCaptchaReady = false;
      this.captchaNotifier.notifier.next(CaptchaActions.ResetCaptcha);
    }, error => {
      console.log(error);
      this.captchaNotifier.notifier.next(CaptchaActions.ResetCaptcha);
    });
  }

  update(id: string | number): void {
    if (this.form!.invalid) {
      return;
    }
    if (!this.repository.update) {
      throw Error('Update method is undefined!');
    }
    
    if (this.form?.value.permission == 3 && (this.form?.value.role == 1||this.form?.value.role == 2||this.form?.value.role == 3||this.form?.value.role == 5
      ||this.form?.value.role == 21||this.form?.value.role == 23)) {
        if(this.form?.value.role == 2)
          {
            this.isSupervisor=true;
          }
          else
          this.isSupervisor=false;
      this.isDeleteModalOpen = true;
      this.stopedId = id
    }

    else if (this.form?.value.permission == 2 && this.form?.value.role == 2 && this.oldItem.permission==1) {
      this.isSupervisor=true;
      this.isSupervisorsModalOpen = true;
      this.isDeleteModalOpen =false;
      this.stopedId = id
    }
    else {
      this.repository.update(id, this.form!.value as I).subscribe(() => {
        this.reloadList();
      }, error => {
        console.log(error);
      });
    }
  }
  getToSupervisorId(e: any) {
    
    this.toSupervisorId = e.id;
    if (this.form != undefined)
      this.form.value.supervisorId = e.id;
  }
  updateStoped() {
    if (this.form!.invalid) {
      return;
    }
    if (!this.repository.update) {
      throw Error('Update method is undefined!');
    }
    
    this.repository.update(this.stopedId, this.form!.value as I).subscribe(() => {
      this.isSupervisor=false;
      this.isSupervisorsModalOpen = false;
      this.isDeleteModalOpen = false;
      this.toSupervisorId = null;
      this.reloadList();
    }, error => {
      console.log(error);
    });
  }
  updateStopedOnDelet(){
    if(this.isDeleteModalOpen){
      this.confirmationService.warn("هل ترغب بحذف المستخدم بشكل نهائي؟", "تأكيد الحذف", { yesText:"نعم", cancelText:"إلغاء" }).subscribe(value => {
        if (value === Status.confirm) {
          this.updateStoped()
        }
      });
    }
  }
  reloadList(): void {
    this.getList();
    this.isModalOpen = false;
    this.form!.reset();
  }

  pageChange(page: number): void {
    this.page = page;
    this.params.skipCount = (page - 1) * this.params.maxResultCount;
    this.getList();
  }


  loadLookups(): void {
    this.options.columns.filter(x => x.lookup).forEach(x => {
      const field = (x.formControlField || x.field);
      const lookup = this.lookups[field];
      if (!lookup) {
        let onChange: ((id: string | number) => Observable<any>) | undefined;
        if (x.lookup!.childLookup && x.lookup!.onChangeFn) {
          onChange = (id: string | number) => x.lookup!.onChangeFn!(id);
        }
        if (x.lookup!.getFn) {
          x.lookup!.getFn().subscribe(value => {
            this.lookups[field] = { data: value, onChange };
            this.changeLookup(x, true);
          });
        } else if (x.lookup!.enum) {
          this.lookups[field] = { data: x.lookup!.enum, onChange };
          this.changeLookup(x, true);

        }
      } else {
        this.changeLookup(x, true);

      }

    });

  }

  changeLookup(col: ITableColumn<T>, init = false): void {
    const field = (col.formControlField || col.field);

    if (window.location.pathname.indexOf('/users') > -1 && col.field == "permission") {
      let roleId = this.form?.value.role;
      if (this.isEditMode) {
        if (col.lookup!.enum) {
          if (roleId == AdminRoles.ConsiderationCommitteeMember || roleId == AdminRoles.SupervisorOfTheTechnicalDepartment || roleId == AdminRoles.SupervisorOfTheShariaAdministration || roleId == AdminRoles.EmirateRepresentative) {
            this.lookups[field] = { data: col.lookup!.enum?.filter(x => x.value == 3) };
          }
          else
            this.lookups[field] = { data: col.lookup!.enum };
        }
      }
      else {
        
        if (col.lookup!.enum) {
          this.lookups[field] = { data: col.lookup!.enum?.filter(x => x.value == 1) };
        }
      }
    }
   
    const id = this.f[field].value;
    if (id && this.lookups[field].onChange) {
      if (!init) {
        for (let i = 0; i < col.lookup!.childLookup!.length; i++) {

          this.f[col.lookup!.childLookup![i].childLookup as string].setValue(undefined);
        }
      }
      if (this.lookups[field].onChange) {
        this.lookups[field].onChange!(id).subscribe((data: any) => {
          if(col.lookup!.childLookup?.length==1){
            const childLookup = this.lookups[col.lookup!.childLookup![0].childLookup as string];
            childLookup ? childLookup.data = data : this.lookups[col.lookup!.childLookup![0].childLookup as string] = { data };
          }
          else{
            const childLookup = this.lookups[col.lookup!.childLookup?.find(x=>x.enumValue===id)?.childLookup as string];
            childLookup ? childLookup.data = data : this.lookups[col.lookup!.childLookup?.find(x=>x.enumValue===id)?.childLookup as string] = { data };
          }

        });
      }
    }
  }


  initFilters(): void {
    if (this.options.filters && this.options.filters.length) {
      this.options.filters.forEach(x => {
        const field = this.getFilterField(x);
        if (x.defaultValue) {
          (this.params as any)[field] = x.defaultValue;
        }
        const filter = this.filters[field];
        if (!filter) {
          let onChange: ((id: string | number) => Observable<any>) | undefined;
          if (x.childFilter && x.onChangeFn) {
            onChange = (id: string | number) => x.onChangeFn!(id);
          }
          if (x.getFn) {
            x.getFn().subscribe(data => {
              this.filters[field] = { data, onChange };
            });
          } else if (x.enum) {
            this.filters[field] = { data: x.enum, onChange };
          }
        }
      });
    }
  }

  changeFilter(filter: IFilter<T, F>, filterValue: any, extra: { isToDate?: boolean } = {}): void {

    const relatedFilterList = this.options?.filters!.filter(item => item.field === 'selectedRevivalTypeFilter' || item.field === 'selectedRevivalEvidenceFilter' || item.field === 'selectedRevivalDateFilter');
    if (this.getFilterField(filter) === 'realEstateRevivalFilter') {
      if (filterValue[filter.valueField || 'value'] === 1) {
        for (var val of relatedFilterList) val.condition = false
      }
      else {
        for (var val of relatedFilterList) val.condition = true
      }
    }

    const field = this.getFilterField(filter);
    const value = filterValue[filter.valueField || 'value'];
    const { isToDate } = extra;
    let doRefresh = true;
    switch (filter.type) {
      case 'date': {
        const [fromField, toField] = filter.field;
        (this.params as any)[isToDate ? toField : fromField] = filterValue;
        if (!isToDate && !filter.isDateSingle) {
          doRefresh = false;
        }
        if(!isToDate)
        (this.params as any)[toField] = null;
        break;
      }
      case 'multi': {
        if ((this.params as any)[field] && (this.params as any)[field].length) {
          (this.params as any)[field] = this.isActiveFilter(filter, filterValue) ?
            (this.params as any)[field].filter((x: any) => x !== value) : [...(this.params as any)[field], value];
        } else {
          (this.params as any)[field] = [value];
        }
        break;
      }
      case 'single':
      default: {
        (this.params as any)[field] = this.isActiveFilter(filter, filterValue) ? undefined : value;
        const newValue = (this.params as any)[field];
        let childRequest: (Observable<any> | undefined);
        if (newValue && this.filters[field].onChange) {
          childRequest = this.filters[field].onChange!(newValue);
        } else if (!newValue && filter.childFilter) {
          const childFilter = this.options.filters!.find(x => x.field === filter.childFilter);
          childRequest = childFilter ? childFilter.getFn!() : undefined;
        }
        if (childRequest) {
          childRequest.subscribe(data => {
            const childFilter = this.filters[filter.childFilter as string];
            childFilter ? childFilter.data = data : this.filters[filter.childFilter as string] = { data };
          });
        }
        break;
      }
    }
    if (doRefresh) {
      this.pageChange(1);
    }
  }

  isActiveFilter(filter: IFilter<T, F>, filterValue: any): boolean {
    const field = this.getFilterField(filter);
    const value = filterValue[filter.valueField || 'value'];
    switch (filter.type) {
      case 'multi':
        return (this.params as any)[field] ? (this.params as any)[field].includes(value) : false;
      case 'single':
      default:
        return (this.params as any)[field] === value;
    }
  }

  getFilterField(filter: IFilter<T, F>): string {
    return typeof filter.field === 'string' || typeof filter.field === 'undefined' ? filter.field : filter.field[0];
  }

  getFromEnum(col: ITableColumn<T>, row: T): string {
    const value = row[col.formControlField || col.field];
    if (col.lookup!.enumName) {
      return `::Enum:${col.lookup!.enumName}:${value}`;
    }
    const option = col.lookup!.enum!.find(x => +x.value === +value);
    return option ? option.key : value as unknown as string;
  }

  onInputBlur(col: ITableColumn<T>): void {
    const field = (col.formControlField || col.field);
    const control = this.f[field];
    control.markAsDirty();
    control.updateValueAndValidity();
  }

  downloadExcel(): void {
    if (this.options.actions && this.options.actions.exportToExcel) {
      this.options.actions.exportToExcel(this.params).subscribe();
    }
  }

  doBulkAction(action: IBulkAction<T>, isConfirmed = false): void {
    if (action.confirmation && !isConfirmed) {
      const { message, title, yesText, cancelText } = action.confirmation;
      const confirmationMessage = this.localizationService.instant(message) + (action.onSelected ? `<br>${this.localizationService.instant(this.options.bulkActions?.selectedCountText || this.selectedCountText)} (${this.checkedIds.length})` : '');
      this.confirmationService.warn(confirmationMessage, title, { yesText, cancelText }).subscribe(value => {
        if (value === Status.confirm) {
          this.doBulkAction(action, true);
        }
      });
      return;
    }
    if (action.actionFn) {
      this.isLoadingBulkAction = true;
      action.actionFn(action.onSelected ? {requestIds:this.checkedIds,isAll:false} : {requestIds:[],isAll:true})
        .pipe(finalize(() => this.isLoadingBulkAction = false))
        .subscribe(() => {
          this.checkedIds = [];
          this.pageChange(1);
        });
    }
  }

  doButtonAction(button: IActionButton<T>, id: any): void{
    if (button.actionFn)
    {
      button.actionFn({requestId: id})
        .subscribe();
    }
  }

  checkAll(e: Event): void {
    this.pagedResult.items?.forEach(item => {
      this.checkItem(e, item);
    });
  }

  isChecked(item: T): boolean {
    const idField = this.options.idField as string;
    return idField ? this.checkedIds.includes((item as any)[idField]) : false;
  }

  isAllChecked(): boolean {
    if(this.pagedResult.items?.length==0)
      return false;
    return !!this.pagedResult.items?.every(item => this.isChecked(item));
  }

  checkItem(e: Event, item: T): void {
    const checked = (e.target as HTMLInputElement).checked;
    const idField = this.options.idField as string;
    if (idField) {
      const id = (item as any)[idField];
      if (checked) {
        if (!this.isChecked(item)) {
          this.checkedIds.push(id);
        }
      } else {
        this.checkedIds = this.checkedIds.filter(x => x !== id);
      }
    }
    this.checkedIdsList.emit(this.checkedIds)
  }
}
