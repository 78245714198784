import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ConfigStateService } from '@abp/ng.core';
@Injectable({
  providedIn: "root"
})
export class LanguageUpdateService {
  public currentLang: BehaviorSubject<string> = new BehaviorSubject("ar");

  constructor(private config: ConfigStateService) {}

  setLang(lang: string): void {
    this.currentLang.next(lang);
  }

  getCurrentLang(): void {
    this.currentLang.next(this.config.getSetting("Abp.Localization.DefaultLanguage"));
  }
}
