import { mapEnumToOptions } from '@abp/ng.core';

export enum CompleteProceduresType {
  AddLengths = 1,
  AddBorders = 2,
  AddLengthsAndBorders = 3,
  AddLengthsAndArea = 4,
  AddBordersAndArea = 5,
  AddBordersAndLengthsAndArea = 6,
  AdjustingLengthsInExcessStatedInInstrument = 7,
  AdjustingAreaInExcessStatedInInstrument = 8,
  AdjustingLengthsAndAreaInExcessStatedInInstrument = 9,
  AdjustingBordersToAffectNeighbors = 10,
  AdjustingLengthsToAffectNeighbors = 11,
  AdjustingAreaToAffectNeighbors = 12,
  AddressingGovernmentStipulatedInSystem = 13,
  AdvertisingInNewspaper = 14
}

export const completeProceduresTypeOptions = mapEnumToOptions(CompleteProceduresType);
