import { Injectable } from '@angular/core';
import { BillTypes } from '@proxy/enums';
import { Observable } from 'rxjs';
import { BillsSettingsApi } from '../api';
import { BillsSettings , PaymentRequest } from '../model';
import { Repository } from './repository';

@Injectable({
  providedIn: 'root',
})
export class BillsSittingsRepository implements Repository<any, any, any> {

  constructor(private billsSettingsApi: BillsSettingsApi) {
  }

  get(id: number): Observable<BillsSettings> {
    return this.billsSettingsApi.getBillsSettings();
  }

  update(id: number, input: BillsSettings): Observable<boolean> {
    return this.billsSettingsApi.updateBillsSettings(input);
  }
  getBillRequestByRequestNumber(id: string,billType: BillTypes): Observable<PaymentRequest> {
    return this.billsSettingsApi.getBillRequestByRequestNumber(id ,billType);
  }
}
