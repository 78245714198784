import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectionInForm,GetObjectionAdminListRequest,GetObjectionAdminListRes,ObjectionDetailsPerRequest} from '../model';
import { ObjectionService } from '@proxy/objections/objection.service';
import type { PagedResultDto} from '@abp/ng.core';

@Injectable({
  providedIn: 'root'
})
export class AdminObjectionsApi {
  constructor(private objectionService: ObjectionService) {
  }
  getObjectionDetails(input:number):Observable<ObjectionInForm>{
    return this.objectionService.getObjectionDetailsByObjectionId(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getObjectionDetailsList(input: ObjectionDetailsPerRequest): Observable<PagedResultDto<ObjectionInForm>>{
    return this.objectionService.getObjectionDetailsListByRequestId(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getAdminObjectionList(input:GetObjectionAdminListRequest):Observable<PagedResultDto<GetObjectionAdminListRes>>{
    return this.objectionService.getAdminObjectionListByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

}
