import { mapEnumToOptions } from '@abp/ng.core';

export enum AuditRequestType {
  PublishedRequests = 10,
  PendingPublishedRequests = 11,
  RequestsRecords = 12,
  RequestsUnderPreliminaryStudiesCommitteeSupervisor = 13,
  ArchivedRequests = 30,
  Ads = 31
}

export const auditRequestTypeOptions = mapEnumToOptions(AuditRequestType);
