import type { NewPublicUserRegisterDto, PublicUserDto, UpdatePublicUserDto, UserUpdatedDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IdentityUserDto } from '../volo/abp/identity/models';

@Injectable({
  providedIn: 'root',
})
export class SPGAPublicUserService {
  apiName = 'Default';

  GetUpdatedUserInfo = () =>
  this.restService.request<any, UserUpdatedDto>({
    method: 'GET',
    url: `/api/app/sPGAPublicUser/updatedUserInfo`,
  },
  { apiName: this.apiName });
  
  generateConfirmationEmail = () =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/sPGAPublicUser/generateConfirmationEmail`,
    },
    { apiName: this.apiName });

  getPublicUser = () =>
    this.restService.request<any, PublicUserDto>({
      method: 'GET',
      url: `/api/app/sPGAPublicUser/publicUser`,
    },
    { apiName: this.apiName });

  registerPublicUser = (input: NewPublicUserRegisterDto) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: `/api/app/sPGAPublicUser/registerPublicUser`,
      body: input,
    },
    { apiName: this.apiName });

  updatePublicUser = (identificationNumber: string, input: UpdatePublicUserDto) =>
    this.restService.request<any, UpdatePublicUserDto>({
      method: 'PUT',
      url: `/api/app/sPGAPublicUser/publicUser`,
      params: { identificationNumber: identificationNumber },
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
