import { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { TransferPublicRequestApi } from '@data/api';
import { GetReassignPublicUserOperationRequestDto, GetReassignPublicUserOperationRequestsRequestDto, GetReassignPublicUserOperationRequestsResponseDto, GetReassignPublicUserOperationResponseDto, GetRequestsByIdentificationNumberRequestDto, GetRequestsByIdentificationNumberResponseDto, GetRequestsByRequestIdRequestDto, GetRequestsByRequestIdResponseDto, ReassignPublicUserRequestDto } from '@proxy/transfer-public-request/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransferPublicRequestListRepository {

  constructor(private transferPublicRequestApi: TransferPublicRequestApi,
  ) { }

  getList(input: GetReassignPublicUserOperationRequestDto): Observable<PagedResultDto<GetReassignPublicUserOperationResponseDto>> {
    return this.transferPublicRequestApi.getReassignPublicUserOperation(input);
  }
}
