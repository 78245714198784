<ng-container *ngIf="isDateRange;else datePickerInput">
  <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [dayTemplate]="t" [firstDayOfWeek]="7"></ngb-datepicker>
</ng-container>

<ng-template #datePickerInput>
  <form [ngClass]="{'form-inline': isFormInline}">
    <div class="form-group">
      <div class="input-group">
        <input class="form-control" [readOnly]="true" [(ngModel)]="fromDate" [minDate]="minDate" [maxDate]="hasMaxDate? maxDate : null" [disabled]="disabled" (dateSelect)="onDateSelection($event)"
               placeholder="التاريخ" name="dp" ngbDatepicker [dayTemplate]="t" [firstDayOfWeek]="7"
               #d="ngbDatepicker">
        <div class="input-group-append">

          <button class="btn btn-outline-secondary calendar cal-icon border-right-0 btn-auto-width" (click)="d.toggle()" type="button">
            <img src="assets/images/calendar.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #t let-date let-currentMonth="currentMonth" let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.outside]="isOutSide(date,currentMonth)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day | arabicNumbers }}
  </span>
</ng-template>

