import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class EmailValidationService {
    public isEmailUpdated$ = new BehaviorSubject<boolean>(false);

    constructor(
    ) { }


  public setIsEmailUpdated(isEmailUpdated: boolean) {
    this.isEmailUpdated$.next(isEmailUpdated);
  }

  public getIsEmailUpdated() {
    return this.isEmailUpdated$.value
  }


}
