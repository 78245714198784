import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar,NgbCalendarIslamicUmalqura, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { IslamicI18n } from '@spga-shared/providers';
let moment = require('moment-hijri');
moment().format('iYYYY/iM/iD');
@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  providers: [{ provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
  { provide: NgbDatepickerI18n, useClass: IslamicI18n }],
})
export class DatepickerRangeComponent implements OnInit {
  @Input() isDateRange = true;
  @Input() isDateSingleFilter = false;
  @Input() disabled = false;
  @Input() isFormInline = true;
  @Input() minDate = { year: 1320, month: 1, day: 1 }
  @Input() hasMaxDate!: boolean;
  maxDate!: { year: number, month: number, day: number };

  @Input() get fromDate(): NgbDate | null {
    return this._fromDate;
  }

  @Output() fromDateChange: EventEmitter<string> = new EventEmitter<string>();
  private _fromDate: NgbDate | null = null;
  set fromDate(d) {
    if (this.islamicCalender.isValid(d)) {
      this._fromDate = d;
      if (d) {
        this.fromDateChange.emit(this.islamicCalender.toGregorian(d).toISOString());
      }
    }
  }

  @Input() get toDate(): NgbDate | null {
    return this._toDate;
  }

  @Output() toDateChange: EventEmitter<string> = new EventEmitter<string>();
  private _toDate: NgbDate | null = null;
  set toDate(d) {
    this._toDate = d;
    if (d) {
      this.toDateChange.emit(this.islamicCalender.toGregorian(d).toISOString());
    }
  }

  hoveredDate: NgbDate | null = null;

  get islamicCalender() {
    return this.calendar as NgbCalendarIslamicUmalqura;
  }

  constructor(private calendar: NgbCalendar) {
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }
  ngOnInit(): void {
    this.getMaxHijri();
  }

  onDateSelection(date: NgbDate) {
    if (!this.isDateRange || (!this._fromDate && !this._toDate)) {
      this.fromDate = date;
    } else if (this._fromDate && !this._toDate && date.after(this._fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  getMaxHijri() {
    if (this.hasMaxDate) {
      var m = moment(new Date()).add(20, 'iYear').add(1, 'iMonth');
      this.maxDate = ({ year: m.iYear(), month: m.iMonth(), day: m.iDate() });
    }
  }

  isHovered(date: NgbDate) {
    return this.isDateRange && this._fromDate && !this._toDate && this.hoveredDate && date.after(this._fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.isDateRange && this._toDate && date.after(this._fromDate) && date.before(this._toDate);
  }

  isRange(date: NgbDate) {
    return this.isDateRange && date.equals(this._fromDate) || (this._toDate && date.equals(this._toDate)) || this.isInside(date) || this.isHovered(date);
  }

  isOutSide(date: NgbDate, currentMonth: number) {
    return date.month !== currentMonth;
  }
}
