import type { StatementType } from '../enums/statement-type.enum';
import type { EntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import type { AttachmentType } from '../enums/attachment-type.enum';
import { AdminRoles } from '../enums';
import { RequestRealEstateReportMetaDataDto } from '@proxy/real-estate-attachments';

export interface CreateStatementAttachmentResponseDto {
  url: string;
  blobName: string;
}

export interface GetStatementsByRequestIdDto {
  requestId: number;
  statementReply: string;
  types: StatementType[];
}

export interface RequestStatemenDto {
  selectedOptions: number[];
  other: string;
  requestId: number;
  statement: string;
  type: StatementType;
  managerStatement: string;
  attachmentsUrls: StatementAttachmentDto[];
  isChangeEngOffice?: boolean | null
}
export interface SavedBeneficiaryStatementDto {
 requestId: number;
  statementText: string;
  selectedOptions?: number[];
  other?: string;
  attachmentsUrls?: SavedBeneficiaryStatementAttachmentDto[];
// adminRole: AdminRoles;
}
export interface RequestStatementReplyDto {
  requestId: number;
  statementReply: string;
  type: StatementType;
}

export interface RequestStatementReplyResponse {
  requestId: number;
  statementId: number;
}

export interface RequestStatementResponse {
  statementId: number;
}

export interface StatementAttachmentDto extends EntityDto<string> {
  description: string;
  name: string;
  extension: string;
  attachmentType: AttachmentType;
  url: string;
}
export interface SavedBeneficiaryStatementAttachmentDto extends RequestRealEstateReportMetaDataDto {
  url: string;
}

export interface StatementByRequestIdResponse {
  statement: string;
  creationTime: string;
  isAnswered: boolean;
  statementId: number;
  type: StatementType;
  attachmentsUrls: StatementAttachmentDto[];
  engOfficeShapeFileGuideline?:string;
}

export interface StatementReplyHistoryPerDeptRequestDto extends PagedAndSortedResultRequestDto {
  requestId: number;
  type?: StatementType;
}

export interface StatementReplyHistoryPerDeptResponseDto {
  statement: string;
  statementReply: string;
  creationTime: string;
  answeredTime?: string;
  statementId: number;
  isAnswered: boolean;
  type: StatementType;
  attachmentsUrls: StatementAttachmentDto[];
}

export interface  StatementReplyHistoryPerDeptResponseDto {
  statement: string;
  statementReply: string;
  creationTime: string;
  answeredTime?: string;
  statementId: number;
  isAnswered: boolean;
  type: StatementType;
  attachmentsUrls: StatementAttachmentDto[];
}

export interface StatementReplyHistoryForAllDeptRequestDto {
  requestId: number;
  type?: StatementType;
}

export interface StatementReplyHistoryForAllDeptResponse {
    deptName: string;
    statementReplyHistoryPerDeptResponses: StatementReplyHistoryPerDeptResponseDto[]
}
