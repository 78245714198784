import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Admin, GetAllAdminsInput, NewAdminRegister, NewAdminRegisterResponse } from '../model';
import { SPGAAdminService } from '@proxy/account/spgaadmin.service';
import type { PagedResultDto } from '@abp/ng.core';
import { UpdateAdminDto, EmployeeDto } from '@proxy/account';
import { AdminRoles } from '@proxy/enums';

@Injectable({
  providedIn: 'root'
})
export class UserApi {
  constructor(private sPGAAccountService: SPGAAdminService) {
  }

  getAdminsListPageAndSort(input: GetAllAdminsInput): Observable<PagedResultDto<Admin>> {
    return this.sPGAAccountService.getadminsPagedListByInput(input);
  }

  getAdminsList(): Observable<Admin[]> {
    throw('not implemented');
  }

  getSupervisorsByEmployee(employee: number): Observable<EmployeeDto[]> {
    return this.sPGAAccountService.getSupervisorsByEmployee(employee).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createNewAdmin(input: NewAdminRegister): Observable<NewAdminRegisterResponse> {
    return this.sPGAAccountService.registerAdmin(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateAdmin(id: number, input: UpdateAdminDto): Observable<UpdateAdminDto> {
    return this.sPGAAccountService.updateAdmin(id.toString(), input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // getSupervisorUsers(): Observable<EmployeeDto[]> {
  //   return this.sPGAAccountService.getSupervisorUsers().pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  getActiveInspectionSupervisors(role:number): Observable<EmployeeDto[]> {
    return this.sPGAAccountService.getActiveInspectionSupervisors(role).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
