import { Injectable } from '@angular/core';
import { RealEstateRequestStatementsApi } from '../api';
import { Repository } from './repository';
import { RequestStatement, RequestStatementResponse, StatementReplyHistoryPerDeptRequest, StatementReplyHistoryPerDeptResponse, StatementRequest, StatementResponse } from '@data/model';
import { MenuDetailsDto} from '@proxy/statement-administration';
import { Observable } from 'rxjs';
import { ToasterService } from '@abp/ng.theme.shared';
import { tap } from 'rxjs/operators';
import type { PagedResultDto } from '@abp/ng.core';
import { CreateStatementAttachmentResponseDto, RequestStatementReplyDto, RequestStatementReplyResponse, SavedBeneficiaryStatementDto, StatementByRequestIdResponse, StatementReplyHistoryForAllDeptRequestDto, StatementReplyHistoryForAllDeptResponse } from '@proxy/real-estate-ownership-requests-statements';
import { IFormFile } from '@proxy/microsoft/asp-net-core/http';
import { downloadBlobFile } from '@spga-shared/utils';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { StatementType } from '@proxy/enums/statement-type.enum';
@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestStatementsRepository implements Repository<any, any, any> {
  constructor(
    private realEstateRequestStatementsApi: RealEstateRequestStatementsApi,
    private toaster: ToasterService
  ) {
  }
  stateId:any;
  create(input: RequestStatement): Observable<RequestStatementResponse> {
    return this.realEstateRequestStatementsApi.createRequestStatement(input)
      .pipe(tap(() => this.toaster.success('تم ارسال طلب الافادة بنجاح')));
  }

  createRequestStatementReplyByInput(input: RequestStatementReplyDto ):Observable<RequestStatementReplyResponse>{
    return this.realEstateRequestStatementsApi.createRequestStatementReplyByInput(input)
  }
  createSavedBeneficiaryStatement(input: SavedBeneficiaryStatementDto): Observable<number> {
    return this.realEstateRequestStatementsApi.createSavedBeneficiaryStatement(input)
  }
  getSavedBeneficiaryStatement(requestId: number): Observable<SavedBeneficiaryStatementDto> {
    return this.realEstateRequestStatementsApi.getSavedBeneficiaryStatement(requestId)
  }
  createStatementAttachmentByFile(file: IFormFile):Observable<CreateStatementAttachmentResponseDto>{
    return this.realEstateRequestStatementsApi.createStatementAttachmentByFile(file)
  }
  
  get(input: StatementRequest): Observable<StatementResponse[]> {
    return this.realEstateRequestStatementsApi.getStatementsByRequestIdByInput(input);
  }

  downloadStatementAttachmentById(id: string, name:string):Observable<FileContentResult>{
    return this.realEstateRequestStatementsApi.downloadStatementAttachmentById(id)
    .pipe(tap(x => downloadBlobFile(x, '', name)));
  }

  getStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisorByInput(input: number): Observable<StatementByRequestIdResponse[]> {
    return this.realEstateRequestStatementsApi.getStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisorByInput(input)
  }
  getStatement(input: StatementReplyHistoryPerDeptRequest) : Observable<PagedResultDto<StatementReplyHistoryPerDeptResponse>>{
    return this.realEstateRequestStatementsApi.getStatementReplyHistoryPerDeptResponseByInput(input);
  }
  getMenuDetailsWithOptions(input: StatementType) : Observable<MenuDetailsDto[]>{
    return this.realEstateRequestStatementsApi.getMenuDetailsWithOptionsByStatementType(input);
  }

  getStatementReplyHistoryForAllDept(input: StatementReplyHistoryForAllDeptRequestDto): Observable<StatementReplyHistoryForAllDeptResponse[]> {
    return this.realEstateRequestStatementsApi.getStatementReplyHistoryForAllDept(input);
  }
}
