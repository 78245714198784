import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { RequestsList } from '@data/model';
import { RealEstateRequestRepository, TransferPublicRequestRepository } from '@data/repository';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-requests-details-modal',
  templateUrl: './requests-details-modal.component.html',
  styleUrls: ['./requests-details-modal.component.scss']
})
export class RequestsDetailsModalComponent implements OnInit {
  @Input() operationId!: any;
  @Input() requestId!: any;
  requestsList!: any
  page = 1
  params = new PagedAndSortedResultRequestDto({
    maxResultCount: 5
  }) as any;
  isListLoading = false;

  constructor(public activeModal: NgbActiveModal,
    public transferPublicRequestRepository: TransferPublicRequestRepository,
  ) { }

  ngOnInit(): void {
    this.isListLoading = true
    if (this.operationId) {
      this.transferPublicRequestRepository.getOperationRequests({
        maxResultCount: this.params?.maxResultCount,
        operationId: this.operationId
      }).subscribe(value => {
        this.requestsList = value;
        this.isListLoading = false
      }, errors => {
        this.isListLoading = false
      })
    }
    else if (this.requestId) {
      this.transferPublicRequestRepository.getRequestsByRequestId({ requestId: this.requestId }).subscribe(value => {
        this.requestsList = {
          "totalCount": 1,
          "items": []
        }
        this.requestsList.items.push(value);        
        this.isListLoading = false
      }, error => {
        this.isListLoading = false
      })
    }
    else {
      this.activeModal.close();
    }
  }

  pageChange(page: any) {
    if (this.operationId) {
      this.isListLoading = true
      this.params.skipCount = (page - 1) * this.params.maxResultCount;
      this.transferPublicRequestRepository.getOperationRequests({
        maxResultCount: this.params.maxResultCount, skipCount: (page - 1) * this.params.maxResultCount,
        operationId: this.operationId
      }).subscribe(value => {
        this.requestsList = value;
        this.isListLoading = false
      }, errors => {
        this.isListLoading = false
      })
    }
  }

  // passBack() {
  //   this.activeModal.close(this.requestsList);
  //   }

}
