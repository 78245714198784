import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConsiderationCommittee, FormalGovConsiderationCommittee, FormalGovMemeberAttachmentAttachmentResponse, FormlGovRequestMinutes } from '../model';
import { ConsiderationCommitteeService, FormalGovMemeberAttachmentAttachmentResponseDto } from '@proxy/consideration-committee';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { IFormFile } from '@proxy/microsoft/asp-net-core/http';
import { RestService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root'
})
export class ConsiderationCommitteeApi {
  constructor(private considerationCommitteeService: ConsiderationCommitteeService , private restService: RestService) {
  }
  approveConsiderationCommitteeVote(input: ConsiderationCommittee): Observable<void> {
    return this.considerationCommitteeService.approveByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
  approveFormalGovByInput(input: FormalGovConsiderationCommittee): Observable<void>{
    return this.considerationCommitteeService.approveFormalGovByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createObjectionAttachmentByFile(file: IFormFile):Observable<FormalGovMemeberAttachmentAttachmentResponse>{
    return this.restService.request<any, FormalGovMemeberAttachmentAttachmentResponseDto>({
      method: 'POST',
      url: `/api/app/considerationCommittee/memberAttachment`,
      body:file
    },
      { apiName: this.considerationCommitteeService.apiName });
  }

  downloadFormalGovMemeberAttachmentAttachmentById(id: string) : Observable<FileContentResult>{
    return this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/considerationCommittee/${id}/downloadFormalGovMemeberAttachmentAttachment`,
      responseType: 'blob'
    },
    { apiName: this.considerationCommitteeService.apiName });
  }
  
  getConsiderationCommitteeByRequestId(requestId:number): Observable<ConsiderationCommittee> {
    return this.considerationCommitteeService.getByRequestId(requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getFormalGovByRequestId(requestId: number) : Observable<FormalGovConsiderationCommittee>{
    return this.considerationCommitteeService.getFormalGovByRequestId(requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getRequestMinutesByRequestId(requestId:number):Observable<FormlGovRequestMinutes>{
    return this.considerationCommitteeService.getRequestMinutesByRequestId(requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }


  saveConsiderationCommitteeVote(input: ConsiderationCommittee): Observable<void> {
    return this.considerationCommitteeService.saveByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  saveFormalGovByInput(input: FormalGovConsiderationCommittee) : Observable<void>{
    return this.considerationCommitteeService.saveFormalGovByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
