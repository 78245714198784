import { Directive, ElementRef, Host, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbersWithoutComma]'
})
export class numbersWithoutCommaInputDirective {
  constructor(@Optional() @Host()private control:NgControl) {}
  @HostListener('input', ['$event'])
  change($event: any) {
    const item = $event.target
    const value = item.value;   
    console.log(item,value);
    const matchValue = value.replace(/[,٫]/g, '.')
    if (matchValue!=value)
    {
      if (this.control)
        this.control?.control?.setValue(matchValue, { emit: false });
      item.value = matchValue;
    }    
  }
}
