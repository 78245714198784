import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-check-attachments',
  templateUrl: './check-attachments.component.html',
  styleUrls: ['./check-attachments.component.scss']
})
export class CheckAttachmentsComponent implements OnInit, OnDestroy {
  @Input() progress?: number;
  @Output() finished = new EventEmitter<void>();
  visible = true;
  private progressInterval: any;
  private hasProgressInput?: boolean;
  private timeToCount = !environment.production ? 1 : 10; // In Seconds

  constructor() {
  }

  ngOnInit(): void {
    if (this.progress !== undefined) {
      this.hasProgressInput = true;
    } else {
      this.progress = 0;
      this.progressInterval = setInterval(() => {
        this.progress! += 100 / this.timeToCount;
        this.progress = Math.min(this.progress!, 100);
        if (this.progress === 100) {
          this.hide(true);
        }
      }, 1000);
    }
  }

  hide(emitFinish = false): void {
    this.visible = false;
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
    if (emitFinish) {
      setTimeout(() => this.finished.emit(), 100);
    }
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
