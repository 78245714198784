import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DocumentTypeApi } from '../api';
import { DocumentType } from '../model';
import { Repository } from './repository';

@Injectable({
    providedIn: 'root',
})
export class DocumentTypeRepository implements Repository<DocumentType, any, DocumentType> {
    public documentTypes$: BehaviorSubject<DocumentType[]> = new BehaviorSubject<DocumentType[]>([]);

    constructor(private documentTypeApi: DocumentTypeApi) {
    }

    getAll(): Observable<DocumentType[]> {
        return this.documentTypeApi.getDocumentTypes().pipe(
            tap((res) => {
                this.documentTypes$.next(res);
            })
        );
    }

}
