<abp-modal [visible]="visible">
  <ng-template #abpBody>
    <div class="check-attachments">
      <img src="assets/images/check-attachments.svg" alt="check attachments" class="check-attachments__image">
      <div class="check-attachments__progress-wrapper">
        <ngb-progressbar type="success" [value]="progress" [striped]="true" [animated]="true"></ngb-progressbar>
      </div>
      <h2 class="app-title mb-3">جاري التحقق من المرفقات</h2>
      <h4 class="app-sub-title">الرجاء الإنتظار قليلاً </h4>
    </div>
  </ng-template>
</abp-modal>
