import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Court } from '../model';
import { CourtService } from '@proxy/lookup/';

@Injectable({
  providedIn: 'root'
})
export class CourtApi {
  constructor(private courtService: CourtService) {
  }

  getCourts(): Observable<Court[]> {
    return this.courtService.getList().pipe(
      map((res) => {
        return res.items!;
      })
    );
  }
}
