import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OTP, OTPVerificationResponse } from '../model';
import { NationalNotificationService } from '@proxy/notifications/national-notification.service';

@Injectable({
  providedIn: 'root'
})
export class OTPApi {
  constructor(private nationalNotificationService: NationalNotificationService) {
  }

  verifyOtpByOtpVerificationModel(otpModel: OTP): Observable<OTPVerificationResponse> {
    return this.nationalNotificationService.verifyOtpByOtpVerificationModel(otpModel).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
