import type { CityDto, CreateCityDto, UpdateCityDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  apiName = 'Default';

  create = (input: CreateCityDto) =>
    this.restService.request<any, CityDto>({
      method: 'POST',
      url: `/api/app/city`,
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: number) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/city/${id}`,
    },
    { apiName: this.apiName });

  get = (id: number) =>
    this.restService.request<any, CityDto>({
      method: 'GET',
      url: `/api/app/city/${id}`,
    },
    { apiName: this.apiName });

  getCitiesByRegionIdByRegionId = (regionId: number) =>
    this.restService.request<any, PagedResultDto<CityDto>>({
      method: 'GET',
      url: `/api/app/city/citiesByRegionId/${regionId}`,
    },
    { apiName: this.apiName });

    getCitiesByRegionIdByRegionIdForEngOffice = (regionId: number) =>
    this.restService.request<any, PagedResultDto<CityDto>>({
      method: 'GET',
      url: `/api/app/city/citiesByRegionIdForEngOffice/${regionId}`,
    },
    { apiName: this.apiName });

  getList = () =>
    this.restService.request<any, PagedResultDto<CityDto>>({
      method: 'GET',
      url: `/api/app/city`,
    },
    { apiName: this.apiName });

  update = (id: number, input: UpdateCityDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/city/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
