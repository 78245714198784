import { IEnvironment } from './environment.model';

export const environment = {
  production: false,
  
  apis: {
    default: {
      url: 'https://localhost:44360',
      rootNamespace: 'SPGA'
    }
  },
} as IEnvironment;
