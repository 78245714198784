import type { CommitteeVotingDetailsDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommitteeVotingService {
  apiName = 'Default';

  getCommitteeVotingByRequestId = (requestId: number) =>
    this.restService.request<any, CommitteeVotingDetailsDto>({
      method: 'GET',
      url: `/api/app/committeeVoting/committeeVoting/${requestId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
