import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestsList } from '@data/model';
import { BillsSittingsRepository } from '@data/repository';
import { ChangeEngOfficeStatuses } from '@proxy/change-eng-office';
import { RequestStatus, BillStatus, BillTypes } from '@proxy/enums';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss']
})
export class RequestCardComponent implements OnInit {
  @Input() request!: RequestsList;
  @Output() isChangeEngOfficeClicked = new EventEmitter<boolean>();
  date?: string;
  billExpiryDate?:string;
  requestStatus = RequestStatus;
  billStatus = BillStatus;
  billType = BillTypes
  changeEngOfficeStatuses = ChangeEngOfficeStatuses

  constructor() {
  }

  ngOnInit(): void {
    if (this.request?.requestCreatedDate) {
      this.date = this.request.requestCreatedDate.split('T')[0].split('-').reverse().join('/');
    }
    if(this.request?.sadadExpiryDate)
    {
      this.billExpiryDate = this.request.sadadExpiryDate.split('T')[0].split('-').reverse().join('/');
    }
  }

  isEditMode(request: RequestsList, isFragment = false): boolean {
    if (!!request.isChangeEngOffice) {
      return !isFragment && !!request.isUnderStatementBeneficiary && !!request.isAssignedToNewEngOffice;
    }
    return [
      RequestStatus.Draft,
      RequestStatus.PendingEngineeringOfficeReview,
      RequestStatus.UnderProcedureEngineeringOffice
    ].indexOf(request.requestStatus) > -1 || (!isFragment && !!request.isUnderStatementBeneficiary);

  }

  onChangeEngOffice(){    
    this.isChangeEngOfficeClicked.emit(true)
  }
}
