import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators'


@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // console.log('INTERCEPTOR ::', request, next);
    request = request.clone({
      headers: request.headers.set("X-Frame-Options", "DENY"),
    });
   
    return next.handle(request);
  }
}