import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestAreaReport, RequestAreaReportDetails, RequestReportRes,UpdateAreaReportCoordinates } from '../model';
import { RealEstateOwnershipRequestService } from '@proxy/real-estate-ownership-requests/real-estate-ownership-request.service';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { RestService } from '@abp/ng.core';
import { TaqniaAreaReportDto } from '@proxy/real-estate-ownership-requests';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestReportApi {
  constructor(
    private realEstateOwnershipRequestService: RealEstateOwnershipRequestService,
    private restService: RestService
  ) {
  }

  createRealEstateRequestReport(input: RequestAreaReport): Observable<RequestReportRes> {
    return this.realEstateOwnershipRequestService.createRequestReport(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getRequestReport(requestId: number): Observable<RequestAreaReport> {
    return this.realEstateOwnershipRequestService.getRequestReport(requestId);
  }

  getRequestAreaReportDetails(requestId: number): Observable<RequestAreaReportDetails> {
    return this.realEstateOwnershipRequestService.getRequestAreaReportDetails(requestId);
  }

  cancelRequest(requestId: number): Observable<void> {
    return this.realEstateOwnershipRequestService.cancelRequest(requestId);
  }

  downloadAreaReportAttachmentById(id: string): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/${id}/downloadAreaReportAttachment`,
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  updateAreaReportCoordinates(input: UpdateAreaReportCoordinates): Observable<boolean> {
    return this.realEstateOwnershipRequestService.updateAreaReportCoordinatesByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  checkTaqniaToolData(taqniaAreaReportDto: TaqniaAreaReportDto): Observable<boolean> {
    return this.realEstateOwnershipRequestService.checkTaqniaToolData(taqniaAreaReportDto);
  }
}
