export * from './cr-number.validator';
export * from './mobile-number.validator';
export * from './email.validator';
export * from './area.validator';
export * from './arabic-characters-only.validator';
export * from './user-name.validator';
export * from './instrument-no.validator';
export * from './agency-no.validator';
export * from './arabic-letters-numbers-only.validator';
export * from './no-english-letters.validator';
export * from './whitespace.validator';
export * from './no-special-characters.validator'
