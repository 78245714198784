import { Injectable } from '@angular/core';
import { MenuDetailsDto} from '@proxy/statement-administration';
import { Observable } from 'rxjs';
import { RestService } from '@abp/ng.core';
import { NotesTypes } from '@proxy/enums/notes-types.enum';
import { NotesService } from '@proxy/notes/notes.service';

@Injectable({
  providedIn: 'root'
})
export class NotesApi {
  apiName = 'Default';
  constructor(private notesService: NotesService , private restService: RestService) {
  } 
  getMenuDetailsWithOptionsByStatementType(input: NotesTypes): Observable<MenuDetailsDto[]>{
    return this.notesService.getMenuDetailsWithOptionsForNotesByNoteType(input);    
  }
}
