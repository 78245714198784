import { mapEnumToOptions } from '@abp/ng.core';

export enum StatementType {
  FromInspectionMemberToSPGAPublicUser = 1,
  FromInspectionMemberToMOJ = 2,
  FromInspectionMemberToEngineeringOffice = 3,
  FromTechnicalMemberToSPGAPublicUser = 11,
  FromTechnicalMemberToMOJ = 12,
  FromTechnicalMemberToEngineeringOffice = 13,
  FromShariaMemberToSPGAPublicUser = 21,
  FromShariaMemberToMOJ = 22,
  FromShariaMemberToEngineeringOffice = 23,
  FromInspectionSupervisorToSPGAPublicUser = 31,
  FromInspectionSupervisorToMOJ = 32,
  FromInspectionSupervisorToEngineeringOffice = 33,
  FromInspectionSupervisorToInspectionMember = 34,
  FromSpecializationStudyMemberToSPGAPublicUser = 41,
  FromPreliminaryStudiesCommitteeSupervisorToSPGAPublicUser = 51,
  FromPreliminaryStudiesCommitteeSupervisorToSpecializationStudyMember = 54,
  FromPreliminaryStudiesCommitteeSupervisorToRequestsStudyingMember = 55,
  FromRequestsStudyingMemberToSPGAPublicUser = 61,
  FromCommitteeSecretaryToPreliminaryStudiesCommitteeSupervisor = 74,
  FromAuditCommitteeSecretaryToInspectionMember = 85,
  FromAuditCommitteeSecretaryToTechnicalMember = 86,
  FromAuditCommitteeSecretaryToShariaMember = 87,
  FromAuditCommitteeSecretaryToCommitteeSecretary = 88,
  FromCommitteeSecretaryToInspectionMember = 95,
  FromCommitteeSecretaryToTechnicalMember = 96,
  FromCommitteeSecretaryToShariaMember = 97,
  FromInspectionSupervisorToSPGADepartments = 2000,
  FromFormalCommitteeSecretaryToSPGADepartments = 2001,
  FromGovernmentalCommitteeSecretaryToSPGADepartments = 2002,
  FromAwqafCommitteeSecretaryToSPGADepartments = 2003,
  FromNormalCommitteeSecretaryToSPGADepartments = 2004,
  FromAuditCommitteeSecretaryToSPGADepartments = 2005,
  FromPreliminaryStudiesCommitteeSupervisorToSPGADepartments = 2006,
  FromAuditCommitteeSecretaryToSPGAPublicUser = 81,
  FromCommitteeSecretaryToSPGAPublicUser = 91,
  FromAuditCommitteeSecretaryToEngineeringOffice = 83,
  FromCommitteeSecretaryToEngineeringOffice = 93,
  FromNormalCommitteeSecretaryToEngineeringOffice= 301,
  FromAwqafCommitteeSecretaryToEngineeringOffice= 302,
  FromEngineeringOfficeToSPGAPublicUser= 400

}

export const statementTypeOptions = mapEnumToOptions(StatementType);
