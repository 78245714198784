import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.scss']
})
export class StaticMapComponent implements OnInit {
  apiKey = ((window as any).dynamic_admin_env ?? (window as any).dynamic_public_env).googleAPIKeys?.dynamicMapsJS;
  url?: string;
  directionsUrl?: string;
  mapUrl?: string;

  markerImage = encodeURI('https://i.ibb.co/f4r1WK1/map-pin.png');
  @Input() location?: { lng: number, lat: number };

  constructor() {
  }

  ngOnInit(): void {
    if (!this.location) {
      this.location = { lat: 0, lng: 0 };
    }
    this.url = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=roadmap&markers=icon:${this.markerImage}|${this.location.lat},${this.location.lng}&key=${this.apiKey}`
      .replace(/\|/g, '%7C');
    this.directionsUrl = `http://maps.google.com/maps?daddr=${this.location.lat},${this.location.lng}`;
    this.mapUrl = `https://www.google.com/maps/@${this.location.lat},${this.location.lng},8z`;
  }

}
