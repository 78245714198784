import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicNumbers'
})
export class ArabicNumbersPipe implements PipeTransform {
  transform(value: number | string, ...args: unknown[]): string {
    const str = value.toString();
    return str.replace(/[0-9]/g, (d) => {
      return String.fromCharCode(d.charCodeAt(0) + 1584); // Convert To Arabic Numbers
    });
  }
}
