import { mapEnumToOptions } from '@abp/ng.core';

export enum RequestStatus {
  Draft = 1,
  PendingEngineeringOfficeReview = 2,
  UnderProcedureEngineeringOffice = 3,
  UnderReviewExaminationManagement = 4,
  Expired = 5,
  Canceled = 6,
  UnderReviewTechnicalAndShariaManagement = 7,
  WaitingForPaid = 8,
  UnderReviewConsiderationCommitteeRequests = 9,
  UnderReviewManagementCommittees = 10,
  Completed = 11,
  UnderReviewInspectionSupervisor = 12,
  UnderReviewSpecializationStudyMember = 13,
  UnderReviewPreliminaryStudiesCommitteeSupervisor = 14,
  UnderReviewRequestsStudyingMember = 15,
  Outside = 16,
  UnderReviewTechnicalManagement = 100,
  UnderReviewShariaManagement = 200,
  UnderReviewBillStoped = 300,
}

export const requestStatusOptions = mapEnumToOptions(RequestStatus);
