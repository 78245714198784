import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot , Router} from '@angular/router';
import { AppAuthService } from '@spga-shared/providers/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(private authService: AppAuthService,private router:Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const routerNeededRoles: string[]=next.data.roles;
    if (routerNeededRoles && !routerNeededRoles.some(x=>this.authService.currentLoggedInUser$.roles.includes(x))) {
      // role not authorised so redirect to home page
      this.router.navigate(['/404']);
      return false;
  }
  else
    return this.checkLogin('', []);
  }

  checkLogin(role: string, permissions?: string[]): boolean {
    if (this.authService.checkIfLoggedIn()) {
      return this.authService.checkIfAuthorized(role, permissions);
    } else {
      this.authService.navigateToAbpLogin();
      return false;
    }
  }
}
