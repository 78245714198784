import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CaptchaNotifier {
  notifier: Subject<CaptchaActions> = new Subject();
}

export enum CaptchaActions{
    ResetCaptcha
}
