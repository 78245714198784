import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { NotesTypes } from '../enums/notes-types.enum';
import type { MenuDetailsDto } from '../statement-administration/models';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  apiName = 'Default';

  getMenuDetailsWithOptionsForNotesByNoteType = (noteType: NotesTypes) =>
    this.restService.request<any, MenuDetailsDto[]>({
      method: 'GET',
      url: `/api/app/notes/menuDetailsWithOptionsForNotes`,
      params: { noteType: noteType },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
