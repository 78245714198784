import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Region } from '../model';
import { RegionService } from '@proxy/lookup/';

@Injectable({
  providedIn: 'root'
})
export class RegionApi {
  constructor(private regionService: RegionService) {
  }

  getRegions(): Observable<Region[]> {
    return this.regionService.getList().pipe(
      map((res) => {
        return res.items!;
            })
        );
    }

    getRegionById(regionId: number): Observable<Region> {
        return this.regionService.get(regionId).pipe(
            map((res) => {
                return res;
            })
        );
    }
}
