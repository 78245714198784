import type { DocumentTypeDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeService {
  apiName = 'Default';

  getList = () =>
    this.restService.request<any, PagedResultDto<DocumentTypeDto>>({
      method: 'GET',
      url: `/api/app/documentType`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
