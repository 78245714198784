import { mapEnumToOptions } from '@abp/ng.core';

export enum ObjectorAdjactives {
  AuthenticityForHimself = 1,
  Agent = 2,
  Representative = 3,
  Headmaster = 4,
  Heir = 5,
  Partner = 6,
  LegalLiquidatorOfLegacies = 7,
  CorporateJudicialLiquidator = 8,
  JudicialGuard = 9,
  Other = 10,
}

export const objectorAdjactivesOptions = mapEnumToOptions(ObjectorAdjactives);
