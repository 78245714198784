import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransferPublicRequestService } from '@proxy/transfer-public-request';
import { GetReassignPublicUserOperationRequestDto, GetReassignPublicUserOperationRequestsRequestDto, GetReassignPublicUserOperationRequestsResponseDto, GetReassignPublicUserOperationResponseDto, GetRequestsByIdentificationNumberRequestDto, GetRequestsByIdentificationNumberResponseDto, GetRequestsByRequestIdRequestDto, GetRequestsByRequestIdResponseDto, ReassignPublicUserRequestDto } from '@proxy/transfer-public-request/models';
import { PagedResultDto } from '@abp/ng.core';

@Injectable({
  providedIn: 'root'
})
export class TransferPublicRequestApi {

  constructor(private transferPublicRequestService: TransferPublicRequestService) {
  }

  getReassignPublicUserOperation(input: GetReassignPublicUserOperationRequestDto): Observable<PagedResultDto<GetReassignPublicUserOperationResponseDto>> {
    return this.transferPublicRequestService.getReassignPublicUserOperation(input);
  }

  getReassignPublicUserOperationRequests(input: GetReassignPublicUserOperationRequestsRequestDto): Observable<PagedResultDto<GetReassignPublicUserOperationRequestsResponseDto>> {
    return this.transferPublicRequestService.getReassignPublicUserOperationRequests(input);
  }

  getRequestsByIdentificationNumber(input: GetRequestsByIdentificationNumberRequestDto): Observable<PagedResultDto<GetRequestsByIdentificationNumberResponseDto>> {
    return this.transferPublicRequestService.getRequestsByIdentificationNumber(input);
  }

  getRequestsByRequestId(input: GetRequestsByRequestIdRequestDto): Observable<GetRequestsByRequestIdResponseDto> {
    return this.transferPublicRequestService.getRequestsByRequestId(input);
  }

  postReassignPublicUserRequests(input: ReassignPublicUserRequestDto): Observable<void> {
    return this.transferPublicRequestService.postReassignPublicUserRequests(input);
  }

}
