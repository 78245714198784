import type { AdsRequestDto, AdsResponseDto, PublishAdsRequestDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdsAppServicesService {
  apiName = 'Default';

  getAdsList = (input: AdsRequestDto) =>
    this.restService.request<any, PagedResultDto<AdsResponseDto>>({
      method: 'GET',
      url: `/api/app/adsAppServices/adsList`,
      params: { filter: input.filter, regionFilter: input.regionFilter, cityFilter: input.cityFilter, adsAreaFilter: input.adsAreaFilter, realEstateTypeFilter: input.realEstateTypeFilter, requestTypeFilter: input.requestTypeFilter, dateFromFilter: input.dateFromFilter, dateToFilter: input.dateToFilter, remainingDays: input.remainingDays, publishedByFilter: input.publishedByFilter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  publish = (input: PublishAdsRequestDto) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/adsAppServices/publish`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
