import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private appConfig?: ConfigStateService;

  constructor(private config: ConfigStateService, private http: HttpClient) {
  }

  loadAppConfig() {
  const config = this.config.getAll();
  }

  getConfig() {
    return this.appConfig;
  }
}
