import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { LanguageUpdateService } from '../language/language.service';
import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import { Constants } from '@spga-shared/data/common/constant';

@Injectable({
  providedIn: 'root'
})
export class AppAuthService {
  public currentLoggedInUser$: any = null;
  public currentConfig$: BehaviorSubject<any> = new BehaviorSubject([]);
  tokenRefreshmentSubscription?: Subscription;
  tokenRefreshmentInterval = interval(180000);
  private isLoggedIn = false;
  private isAuthorized = false;
  private permissions?: string[];
  private env: any;

  constructor(
    private oAuthService: OAuthService,
    private languageUpdateService: LanguageUpdateService,
    private http: HttpClient,
    private abpAuthService: AuthService,
    private config: ConfigStateService,
    private permissionService: PermissionService
  ) {

    this.languageUpdateService.getCurrentLang();
    this.currentConfig$.next(this.config.getAll());
    this.currentLoggedInUser$ = this.config.getOne('currentUser');

  }

  checkIfLoggedIn(): boolean {
    this.isLoggedIn = this.oAuthService.hasValidAccessToken();
    if (this.currentLoggedInUser$ == null) {
      this.getUserInf();
    }
    return this.isLoggedIn;
  }

  navigateToAbpLogin(): any {
    if (!this.isLoggedIn) {
      this.abpAuthService.navigateToLogin();
    }
  }

  getUserInf(): void {
    this.currentLoggedInUser$ = this.config.getOne('currentUser');
  }

  checkIfAuthorized(role: string, reqPermissions?: string[]): boolean {
    // return true;
    // TODO when permission matrix avilable
    // let issues: number = 0;
    // this.permissions = localStorage.getItem("user")
    //   ? JSON.parse(localStorage.getItem("user"))["permissions"]
    //   : null;

    // if (reqPermissions && reqPermissions.length > 0) {
    //   reqPermissions.filter((permission, i, arr) => {
    //     if (this.permissions.indexOf(permission) === -1) {
    //       issues++;
    //       return false;
    //     }
    //   });

    //   this.isAuthorized = issues === 0;
    // } else {
    //   this.isAuthorized = true;
    // }
    if (this.oAuthService.clientId === 'SPGA_App') {
      this.isAuthorized = Constants.adminSiteAllowedRoles.some(element => this.currentLoggedInUser$.roles.includes(element));
      return this.isAuthorized;
    } else {
      this.isAuthorized = true;
    }
    return true;
  }

  checkIfIsEngineeringOffice(): boolean {
    return !!(this.currentLoggedInUser$.roles.includes('engineeringOfffice') || this.currentLoggedInUser$.roles.includes('engineering offices') || this.currentLoggedInUser$.roles.includes('EngineeringOffice'));
  }

  checkIfUserHavePermission(permission: string): boolean {
    return this.permissionService.getGrantedPolicy(permission);
  }

  logout(): any {
    
    this.oAuthService.logOut();
  }

  logoutABP() {
    this.abpAuthService.logout()
  }
}
