import { AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, ElementRef, EmbeddedViewRef, Injector, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { SubscriptionService } from '@abp/ng.core';
import { debounceTime, filter } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'spga-default-error-page',
  templateUrl: './default-error-page.component.html',
  styleUrls: ['./default-error-page.component.scss'],
  providers: [SubscriptionService],
})
export class defaultErrorPage implements OnInit {
  constructor(private router: Router) { }
  errorCode = ''

  ngOnInit(): void {
    this.errorCode = this.router.url
    console.log(this.router.url === '/error403');
  }
}