<ng-container *ngIf="office">
  <div class="eng-card" >
    <div class="eng-card__data">
      <div class="row">
        <div *ngIf="office" class="col-4">
          <img src="assets/images/eng-office.svg" alt="">
        </div>
        <div class="col-md-8">
          <h2 class="eng-card__id"></h2>
          <p [ngClass]="office ? 'eng-card__status':'card__label'">{{office?.name ? office?.name : (statment?.creationTime|date)}}
          </p>
          <ng-container >
            <p class="eng-card__location">
              <i class="icon icon-location"></i> {{office?.regionName}} - {{office?.cityName}}
            </p>
            <p class="eng-card__phone-number">رقم الجوال : {{office?.phoneNumber}}
            </p>
          </ng-container>
        </div>
        <div class="col-md-3 d-flex align-items-center justify-content-center">
        </div>
      </div>
    </div>
  </div>
  <ng-template #noEng>
    <div class="alert alert-warning">عفوا لا يوجد مكاتب هندسية بهذة المدينة</div>
  </ng-template>  
</ng-container>
<ng-container *ngIf="statment">
  <div class="eng-card" >
    <div class="eng-card__data">
      <div class="row">
        <div class="col-md-8">
          <h2 class="card__label card__label-mongoose">الإفادة</h2>
          <h2 class="eng-card__id"></h2>
          <ng-container>
            <p class="card__label">{{statment?.creationTime|date}}
            <p class="card__label card__label-hippie-green">{{statment?.statement}}</p>
          </ng-container>
          <ng-container *ngTemplateOutlet="attachments"></ng-container>
          <ng-container *ngIf="statment?.isAnswered">
            <h2 class="card__label card__label-mongoose">رد الإفادة</h2>
            <p class="card__label">{{statment?.answeredTime|date}}
              <p class="card__label card__label-cloudy"> {{statment?.statementReply ? statment?.statementReply : 'لا يوجد رد'}} </p>
          </ng-container>  
        </div>
        <div class="col-md-3 d-flex align-items-center justify-content-center">
        </div>
      </div>
    </div>
  </div>  
</ng-container>
<ng-template #attachments>
    <div *ngIf="statment?.attachmentsUrls?.length>0;else noAttachments">
        <button (click)="showAttachement = !showAttachement" class="tertiary linkish">{{!showAttachement ? 'المرفقات': 'اخفاء المرفقات'}}</button>
        <div *ngIf="showAttachement" class="row">
            <div *ngFor="let attachmentInput of statment?.attachmentsUrls" class="col-md-4">
                <app-custom-file-upload [attachmentInput]="attachmentInput" [attachmentType]="attachmentInput.attachmentType" [downloadFn]=" downloadAttachmentFn" [desc]="attachmentInput.description">
                </app-custom-file-upload>
            </div>
        </div>
    </div>
    <ng-template #noAttachments>
        <div class="alert alert-info">لا توجد مرفقات</div>
    </ng-template>

</ng-template>