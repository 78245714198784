import { Component, OnInit } from '@angular/core';
import { AppAuthService } from '@spga-shared/providers/auth/app-auth-service.service';
import { Router } from '@angular/router';
import { PublicUserRepository,RealEstateRequestOwnerRepository } from '@data/repository';

@Component({
  selector: 'app-profile-kit',
  templateUrl: './profile-kit.component.html',
  styleUrls: ['./profile-kit.component.scss']
})
export class ProfileKitComponent implements OnInit {
  userData = {
    identificationNumber: '',
    emailAddress: '',
    phoneNumber: '',
    name: ''
  };
  isCreateRequestEnabled:boolean=false;
  constructor(private router: Router, private publicUserRepository: PublicUserRepository,private realEstateRequestOwnerRepository:RealEstateRequestOwnerRepository) {

  }

  ngOnInit(): void {
    // this.userData =this.appAuthService.currentLoggedInUser$;
    this.publicUserRepository.get().pipe().subscribe((result) => {
      this.userData = result;
    }, error => {
      console.log(error);
    });
    this.realEstateRequestOwnerRepository.isCreateNewRequestEnabled().pipe().subscribe((res)=>{
      this.isCreateRequestEnabled=res;
    });
  }

  navigateToEdit(): void {
    this.router.navigate(['/', 'edit-profile']);
  }

  navigateToMyObjections(): void {
    this.router.navigate(['/', 'my-objections']);
  }
}
