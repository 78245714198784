<div class="emailCard-wrapper" *ngIf="showCard">
    <div class="container">
        <div class="emailCard justify-content-center justify-content-lg-between row text-center">
            <div class="emailCard-msg mb-lg-0 mb-3">يرجى تفعيل بريدك الإلكتروني ( {{UpdatedUserInfo.userEmail}} ) لتتمكن من استلام الاشعارات الخاصة
                بطلبك.
            </div>
            <div class="emailCard-actions">
                <button class="btn btn-primary emailCard-btn" (click)="onActivateEmail()">
                    <img src="assets/images/validate-icon.svg">
                    تفعيل</button>
                <a class="emailCard-link" [routerLink]="'/edit-profile'">
                    <img src="assets/images/edit-icon.svg">
                    تعديل البريد الإلكتروني </a>
            </div>
        </div>
    </div>
</div>