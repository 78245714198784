import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentType } from '../model';
import { DocumentTypeService } from '@proxy/lookup/';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeApi {
  constructor(private documentTypeService: DocumentTypeService) {
  }

  getDocumentTypes(): Observable<DocumentType[]> {
    return this.documentTypeService.getList().pipe(
      map((res) => {
        return res.items!;
      })
    );
  }
}
