import { mapEnumToOptions } from '@abp/ng.core';

export enum RealEstateTypeEnum {
  Residential = 1,
  Agricultural = 2,
  WithinBoundariesAlHaramain = 3,
  other = 200,
}

export const realEstateTypeEnumOptions = mapEnumToOptions(RealEstateTypeEnum);
