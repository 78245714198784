import { mapEnumToOptions } from '@abp/ng.core';

export enum AbstainingReason {
  RelativeRelationship = 1,
  DirectOrIndirectInterest = 2,
  ExistingDispute = 3,
  Other = 4,
}

export const abstainingReasonOptions = mapEnumToOptions(AbstainingReason);
