import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { BlobStorageApi } from '@data/api/blob-storage.api';
import { AiResult, AttachmentType } from '@proxy/enums';
import { IAttachmentInfo } from '@spga-shared/components';
import { AreaReportService } from 'projects/spga-admin/src/app/area-report/area-report.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface IAttachment extends IAttachmentInfo {
  url?: string;
  extension?: string;
  name?: string;
  attachmentType?: AttachmentType;
  contentType?: string;
  id?: string;
  aiResult?: AiResult;
}

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss']
})
export class CustomFileUploadComponent implements OnChanges {
  @Input() title?: string;
  @Input() desc?: string;
  @Input() attachmentType!: AttachmentType;
  @Input() attachmentExtensions?: string;
  @Input() attachmentInput?: IAttachment;
  @Input() uploadFn?: (formData: FormData) => Observable<any>;
  @Input() removeFn?: (input: IAttachment) => Observable<any>;
  @Input() downloadFn?: (input: IAttachment) => Observable<any>;
  @Input() showRemove?: boolean;
  @Input() hasDate = true;
  @Input() hasDescription = true;
  @Input() hasDDL = false;
  @Input() isShpfile = false

  @Output() uploadRes = new EventEmitter<IAttachment>();

  file?: File;
  attachment?: IAttachment;
  isUploading?: boolean;
  isDownloading?: boolean;
  isModalOpen = false;
  shpFile!: any

  constructor(private blobStorageApi: BlobStorageApi,
    private areaReportService: AreaReportService,
  ) {
    this.areaReportService.isValidFile$.subscribe((v) => {
      if (v) {
        if (this.shpFile && v) {
          this.handleFileInput(this.shpFile)
        }
      }
    });
  }

  get allowedExtensions(): string {
    if (this.attachmentExtensions) {
      return this.attachmentExtensions;
    }
    switch (this.attachmentType) {
      case AttachmentType.SHAPEFILE:
        return '.shp';
      case AttachmentType.ZIPFile:
        return '.zip';
      case AttachmentType.ObjectorIdentity:
      case AttachmentType.Instrument:
      case AttachmentType.ObjectorRepresentativeIdentity:
      case AttachmentType.Agency:
      case AttachmentType.SupportingDocuments:
      case AttachmentType.ArealReport:
        return '.png,.jpg,.jpeg,.bmp,.gif,.pdf,.xlsx,.xls,.docx,.doc';
      case AttachmentType.AutoCADFile:
        return '.dwg';
      case AttachmentType.SupportiveAdditionalAttachment:
        return '.pdf,.xlsx,.xls,.shp';
      case AttachmentType.SecondSupportiveAdditionalAttachment:
      case AttachmentType.ThirdSupportiveAdditionalAttachment:
        return '.pdf,.xlsx,.xls';
      case AttachmentType.ExcelFile:
        return '.xlsx,.xls';
      case AttachmentType.RealestateLimits:
        return '.png';
      case AttachmentType.TechnicalReport:
        return '.pdf';
      default:
        return '.png,.jpg,.jpeg,.bmp,.gif,.pdf';
    }
  }

  ngOnChanges(): void {
    this.attachment = this.attachmentInput;
  }


  @HostListener('window:onUploadFile', ['$event'])
  updateNodes($event: any) {
    if ($event.detail)
      this.shpFile = $event.detail
  }

  handleFileInput($event: Event): void {
    const files: FileList | null = ($event.target as HTMLInputElement).files;
    if (files && files.length) {
      this.file = files[0];
      if (!this.isValidExtension()) {
        return;
      }
      this.isModalOpen = true;
      // @ts-ignore
      ($event.target as HTMLInputElement).value = null; // Clear input to listen to changes if choose same file again
    }
  }

  isValidExtension(): boolean {
    const file = this.file;
    if (!file) {
      return true;
    }
    const extension = (file.name.split('.').pop() || '').toLowerCase();
    const allowedExtensions = this.allowedExtensions.split(',');
    return allowedExtensions.some(x => x.endsWith(extension));
  }

  removeAttachment(): void {
    if (!this.removeFn) {
      this.attachment = undefined;
      this.uploadRes.emit();
      return;
    }
    this.isUploading = true;
    this.removeFn(this.attachment!)
      .pipe(finalize(() => this.isUploading = false))
      .subscribe(() => {
        this.uploadRes.emit();
      });
  }

  preview(): void {
    if (this.isImage(this.attachment)) {
      window.open(this.attachment?.url, '_blank');
    }
    else if (this.attachment?.name) {
      this.blobStorageApi.getObjectUrlByFileName(this.attachment?.name).subscribe((value: any) => {
        if (value) {
          let result = value.replace(/\%/g, "%25");
          result = result.replace(/\&/g, "%26",);
          result = result.replace(/\=/g, "%3D");
          window.open("https://docs.google.com/viewerng/viewer?url=" + result, '_blank');
        }
      })
    }
  }

  handleAttachmentInfo(info: IAttachmentInfo): void {
    this.isModalOpen = false;
    if (!info) {
      return this.file = undefined;
    }
    const formData = new FormData();
    formData.append('file', this.file!);
    formData.append('attachmentType', `${this.attachmentType}`);
    if (info.description) {
      formData.append('description', info.description);
    }
    if (info.attachmentDate) {
      formData.append('attachmentDate', info.attachmentDate);
    }
    const extension = this.file!.name.split('.').pop();
    if (!this.uploadFn) {
      throw new Error('Upload function not provided');
    }
    this.isUploading = true;
    this.uploadFn(formData)
      .pipe(finalize(() => this.isUploading = false))
      .subscribe(value => {
        const res: IAttachment = {
          ...info,
          attachmentType: this.attachmentType,
          extension,
          contentType:
            this.file!.type ||
            (extension === 'dwg'
              ? 'image/x-dwg'
              : extension === 'shp'
                ? 'application/octet-stream'
                : extension),
          ...value,
          name: value.blobName
        };
        this.attachment = res;
        this.uploadRes.emit(res);
      });
  }

  isImage(attachment?: IAttachment): boolean {
    return attachment ? /(png|gif|jpg|jpeg)$/i.test(attachment.extension || '') : false;
  }

  isPdf(attachment?: IAttachment): boolean {
    return attachment ? /(pdf)$/i.test(attachment.extension || '') : false;
  }

  downloadAttachment(): void {
    if (this.isDownloading || !this.downloadFn) {
      return;
    }
    this.isDownloading = true;
    this.downloadFn(this.attachment!)
      .pipe(finalize(() => this.isDownloading = false))
      .subscribe();
  }
}
