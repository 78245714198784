import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  @Output() navItemClick = new EventEmitter<boolean>();
  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  onLoginClick(e: MouseEvent) {

    
  }
  navigatToEngOffice() {
    this.router.navigate(['/','engineering-office-list']);
  }

  onNavItemClick() {
    this.navItemClick.emit(true)
  }

}
