import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { City } from '../model';
import { CityService } from '@proxy/lookup/';

@Injectable({
  providedIn: 'root'
})
export class CityApi {
  constructor(private cityService: CityService) {
  }

  getCites(): Observable<City[]> {
    return this.cityService.getList().pipe(
      map((res) => {
        return res.items!;
      })
    );
  }

  getCityById(cityId: number): Observable<City> {
    return this.cityService.get(cityId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCitiesByRegionIdByRegionId(regionId: number): Observable<City[]> {
    return this.cityService.getCitiesByRegionIdByRegionId(regionId).pipe(
      map((res) => {
        return res.items!;
      })
    );
  }
  getCitiesByRegionIdByRegionIdForEngOffice(regionId: number): Observable<City[]> {
    return this.cityService.getCitiesByRegionIdByRegionIdForEngOffice(regionId).pipe(
      map((res) => {
        return res.items!;
      })
    );
  }
}
