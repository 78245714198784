import type { ConsiderationCommitteeDto, FormalGovConsiderationCommitteeDto, FormalGovMemeberAttachmentAttachmentResponseDto, FormlGovRequestMinutesDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';
import type { FileContentResult } from '../microsoft/asp-net-core/mvc/models';

@Injectable({
  providedIn: 'root',
})
export class ConsiderationCommitteeService {
  apiName = 'Default';

  approveByInput = (input: ConsiderationCommitteeDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/considerationCommittee/approve`,
      body: input,
    },
    { apiName: this.apiName });

  approveFormalGovByInput = (input: FormalGovConsiderationCommitteeDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/considerationCommittee/approveFormalGov`,
      body: input,
    },
    { apiName: this.apiName });

  createMemberAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, FormalGovMemeberAttachmentAttachmentResponseDto>({
      method: 'POST',
      url: `/api/app/considerationCommittee/memberAttachment`,
    },
    { apiName: this.apiName });

  downloadFormalGovMemeberAttachmentAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/considerationCommittee/${id}/downloadFormalGovMemeberAttachmentAttachment`,
    },
    { apiName: this.apiName });

  getByRequestId = (requestId: number) =>
    this.restService.request<any, ConsiderationCommitteeDto>({
      method: 'GET',
      url: `/api/app/considerationCommittee`,
      params: { requestId: requestId },
    },
    { apiName: this.apiName });

  getFormalGovByRequestId = (requestId: number) =>
    this.restService.request<any, FormalGovConsiderationCommitteeDto>({
      method: 'GET',
      url: `/api/app/considerationCommittee/formalGov/${requestId}`,
    },
    { apiName: this.apiName });

  getRequestMinutesByRequestId = (requestId: number) =>
    this.restService.request<any, FormlGovRequestMinutesDto>({
      method: 'GET',
      url: `/api/app/considerationCommittee/requestMinutes/${requestId}`,
    },
    { apiName: this.apiName });

  saveByInput = (input: ConsiderationCommitteeDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/considerationCommittee/save`,
      body: input,
    },
    { apiName: this.apiName });

  saveFormalGovByInput = (input: FormalGovConsiderationCommitteeDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/considerationCommittee/saveFormalGov`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
