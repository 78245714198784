import { mapEnumToOptions } from '@abp/ng.core';

export enum AgencyTypes {
  StateRealEstateGeneralAuthority = 1,
  MinistryOfInteriorAffairs = 2,
  MinistryOfJustice = 3,
  MinistryOfEnvironmentWaterAgriculture = 4,
  MinistryOfMunicipalRuralAffairsHousing = 5,
  MinistryOfFinance = 6,
}

export const agencyTypesOptions = mapEnumToOptions(AgencyTypes);
