import { Component, Input, OnInit } from '@angular/core';
import { AppAuthService } from '@spga-shared/providers/auth';
import { Router } from '@angular/router';
import { realEstateTypeEnumOptions } from '@proxy/enums';
import { ToasterService } from '@abp/ng.theme.shared';
import { RealEstateRequestAttachmentRepository } from '@data/repository';
import { IAttachment } from '@spga-shared/components';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-published-card',
  templateUrl: './published-card.component.html',
  styleUrls: ['./published-card.component.scss']
})
export class PulblishedCardComponent implements OnInit {
  isLoggedIn = false;
  @Input() singleAd: any;
  date?: string;
  realEstateTypeEnumOptions = realEstateTypeEnumOptions;
  keys = Object.keys;
  values = Object.values;
  isEditMode = true;
  directionsUrl: any;
  directionsMapUrl: any;
  downloadArealReportAttachmentFn?: (attachment: IAttachment) => Observable<any>;
  downloadRealestateLimitsAttachmentFn?: (attachment: IAttachment) => Observable<any>;
constructor(
    private authService: AppAuthService,
    private router: Router,
    private toaster: ToasterService,
    private attachmentRepository: RealEstateRequestAttachmentRepository,
  ) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.checkIfLoggedIn();
    this.directionsUrl = location.origin + `/spgapublic/${this.singleAd.requestId}.JPG`;
    this.directionsMapUrl = `http://maps.google.com/maps?daddr=${this.singleAd.latitude},${this.singleAd.longitude}`;
  }

  getFormattedDate(publishingDate: any) {
    function pad(s: any) { return (s < 10) ? '0' + s : s; }
    let d = new Date(publishingDate)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
  }

  downloadArealReportAttachment = () => this.attachmentRepository
  .downloadArealReportAttachmentByRequestId(this.singleAd.requestId!, this.singleAd.requestId!.toString()).subscribe();

  downloadRealestateLimitsAttachment = () => this.attachmentRepository
  .downloadRealestateLimitsAttachmentByRequestId(this.singleAd.requestId!, this.singleAd.requestId!.toString()).subscribe();

  openObjection() {
      if(this.isLoggedIn) {
        this.router.navigate(['/objection-request', true , this.singleAd.requestId]);
      }
      else {
        this.toaster.error('لتقديم طلب اعتراض يجب ان تقوم بتسجيل الدخول');
      }
  }

openGoogleMapTab() :void{
  window.open(this.directionsMapUrl, '_blank');
}
openRealEstateMap() :void {
  window.open(this.directionsUrl, '_blank');

}

}
