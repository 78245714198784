import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillsSettings , PaymentRequest } from '../model';
import {  BillsService } from '@proxy/bills';
import { BillTypes } from '@proxy/enums';

@Injectable({
  providedIn: 'root'
})
export class BillsSettingsApi {
  constructor(private billsService: BillsService) {
  }

  getBillsSettings(): Observable<BillsSettings> {
    return this.billsService.getBillsSettings().pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateBillsSettings(input: BillsSettings): Observable<boolean> {
    return this.billsService.createBillsSettingsByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getBillRequestByRequestNumber(input: string , billType: BillTypes): Observable<PaymentRequest> {
    return this.billsService.getBillRequestByIdAndBillType(input , billType).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
