import {
CommitteeVotingDto,
ConsiderationCommitteeDto,
CommitteeVotingDetailsDto,
MemeberVotingDto,
RequestCommitteeStatusDto,
FormalGovConsiderationCommitteeDto,
FormalGovMemeberAttachmentAttachmentResponseDto,
FormalGovMemeberAttachmentDto,
FormlGovRequestMinutesDto
  } from '@proxy/consideration-committee';
import { GetReviewComitteeDto, ReviewCommitteeRecommendationDto, RequestMinutesDto} from '@proxy/review-committee/models';
  
  export interface CommitteeVoting extends CommitteeVotingDto {}
  export interface CommitteeVotingDetails extends CommitteeVotingDetailsDto {}
  export interface ReviewComittee extends GetReviewComitteeDto {}
  export interface RequestMinutes extends RequestMinutesDto {}
  export interface ReviewCommitteeRecommendation extends ReviewCommitteeRecommendationDto {}
  export interface ConsiderationCommittee extends ConsiderationCommitteeDto {}
  export interface FormalGovMemeberAttachmentAttachmentResponse extends FormalGovMemeberAttachmentAttachmentResponseDto{}
  export interface MemeberVoting extends MemeberVotingDto {}
  export interface RequestCommitteeStatus extends RequestCommitteeStatusDto {}
  export interface FormalGovConsiderationCommittee extends FormalGovConsiderationCommitteeDto{}
  export interface FormlGovRequestMinutes extends FormlGovRequestMinutesDto{}
  export interface FormalGovMemeberAttachment extends FormalGovMemeberAttachmentDto{}