import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { EngineeringOfficeResponse, UpdateEngineeringOfficeDto } from '../engineering-offices/models';

@Injectable({
  providedIn: 'root',
})
export class ContactInfoService {
  apiName = 'Default';

  getEngneeringOffice = () =>
    this.restService.request<any, UpdateEngineeringOfficeDto>({
      method: 'GET',
      url: `/GetEngneeringOffice`,
    },
    { apiName: this.apiName });

  updateEngineeringOffice = (input: UpdateEngineeringOfficeDto) =>
    this.restService.request<any, EngineeringOfficeResponse>({
      method: 'PUT',
      url: `/UpdateEngneeringOffice`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
