import { mapEnumToOptions } from '@abp/ng.core';

export enum AttachmentType {
  ApplicantNationalIdentity = 1,
  OwnerNationalIdentity = 2,
  LegalRecord = 3,
  AgentOrLawyerData = 4,
  LegitimateAgency = 5,
  ArealReport = 6,
  OwnershipDocuments = 7,
  SupportiveAdditionalAttachment = 8,
  AutoCADFile = 9,
  SHAPEFILE = 10,
  SecondSupportiveAdditionalAttachment = 11,
  ThirdSupportiveAdditionalAttachment = 12,
  ObjectorIdentity = 13,
  ObjectorRepresentativeIdentity = 14,
  Agency = 15,
  Instrument = 16,
  SupportingDocuments = 17,
  RealestateLimits = 18,
  IVSupportiveAdditionalAttachment = 19,
  VSupportiveAdditionalAttachment = 20,
  VISupportiveAdditionalAttachment = 21,
  VIISupportiveAdditionalAttachment = 22,
  VIIISupportiveAdditionalAttachment = 23,
  ZIPFile = 24,
  ExcelFile = 25,
  TechnicalReport = 26,
  ChangeEngOfficeReques = 27
}

export const attachmentTypeOptions = mapEnumToOptions(AttachmentType);
