import type { BillPaymentRequest, BillSettingsDto, BillsSettingsDto, EngineeringOfficeBillDto, PublicUserBillDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BillTypes } from '../enums/bill-types.enum';

@Injectable({
  providedIn: 'root',
})
export class BillsService {
  apiName = 'Default';

  createBillsSettingsByInput = (input: BillSettingsDto) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/bills/billsSettings`,
      body: input,
    },
    { apiName: this.apiName });

  createEngineeringOfficeBillValuesByInput = (input: EngineeringOfficeBillDto) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/bills/engineeringOfficeBillValues`,
      body: input,
    },
    { apiName: this.apiName });

  createPublicUserBillValuesByInput = (input: PublicUserBillDto) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/bills/publicUserBillValues`,
      body: input,
    },
    { apiName: this.apiName });

  getBillRequestByIdAndBillType = (id: string, billType: BillTypes) =>
    this.restService.request<any, BillPaymentRequest>({
      method: 'GET',
      url: `/api/app/bills/${encodeURIComponent(id)}/billRequest`,
      params: { billType: billType },
    },
    { apiName: this.apiName });

  getBillSettings = () =>
    this.restService.request<any, BillSettingsDto>({
      method: 'GET',
      url: `/api/app/bills/billSettings`,
    },
    { apiName: this.apiName });

  getBillsSettings = () =>
    this.restService.request<any, BillsSettingsDto>({
      method: 'GET',
      url: `/api/app/bills/billsSettings`,
    },
    { apiName: this.apiName });

  getEngineeringOfficeBillSettings = () =>
    this.restService.request<any, EngineeringOfficeBillDto>({
      method: 'GET',
      url: `/api/app/bills/engineeringOfficeBillSettings`,
    },
    { apiName: this.apiName });

  getPublicUserBillSettings = () =>
    this.restService.request<any, PublicUserBillDto>({
      method: 'GET',
      url: `/api/app/bills/publicUserBillSettings`,
    },
    { apiName: this.apiName });

  webhook = () =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/bills/webhook`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
