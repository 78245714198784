import { NgModule } from '@angular/core';
import { CommonSharedModule } from '@spga-shared/common-shared.module';
import { PublicApplicationLayoutComponent } from '../views/public-application-layout/public-application-layout.component';
import { PublicApplicationLayoutRequestComponent } from '../views/public-application-layout-request/public-application-layout-request.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavBarComponent } from './components/header/nav-bar/nav-bar.component';
import { LogInComponent } from './components/resuable-btns/login-btn/login-btn.component';
import { AddRequestBtnComponent } from './components/resuable-btns/add-request-btn/add-request-btn.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserNotificationComponent } from './components/notification/user-notification.component';
import { NavigationBtnsComponent } from './components/resuable-btns/navigation-btns/navigation-btns.component';
import { ProfileKitComponent } from '../edit-user-profile/profile-kit/profile-kit.component';
import { RequestCardComponent } from './components/request-card/request-card.component';
import { PulblishedCardComponent } from '../published-ads/published-card/published-card.component';
import { ListComponent } from '@spga-shared/components/list/list.component';
import { EmailValidationCardComponent } from './components/email-validation-card/email-validation-card.component';
import { PopupComponent } from './components/popup/popup.component';


const declarations = [
  PublicApplicationLayoutComponent,
  PublicApplicationLayoutRequestComponent,
  HeaderComponent,
  FooterComponent,
  NavBarComponent,
  UserProfileComponent,
  LogInComponent,
  UserNotificationComponent,
  AddRequestBtnComponent,
  NavigationBtnsComponent,
  ProfileKitComponent,
  RequestCardComponent,
  PulblishedCardComponent,
  EmailValidationCardComponent,
  PopupComponent
];

const imports = [CommonSharedModule];

@NgModule({
  declarations: [declarations],
  imports: [imports],
  exports: [declarations, imports]
})
export class SharedModule {
  // tslint:disable-next-line:typedef
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [CommonSharedModule.forRoot().providers]
    };
  }
}
