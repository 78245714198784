
export interface FileDto {
  fileName: string;
  fileBase64: string;
  contentType: string;
}

export interface SaveFileResponseDto {
  preSignedUrl: string;
}

export interface SaveFilesResponseDto {
  preSignedUrl: string;
}
