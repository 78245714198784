import type { SpecializationMemberNotesDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { FileContentResult } from '../microsoft/asp-net-core/mvc/models';

@Injectable({
  providedIn: 'root',
})
export class SpecializationMemberService {
  apiName = 'Default';

  downloadSpecializationAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/specializationMember/${id}/downloadSpecializationAttachment`,
    },
    { apiName: this.apiName });

  get = (requestId: number) =>
    this.restService.request<any, SpecializationMemberNotesDto>({
      method: 'GET',
      url: `/api/app/specializationMember`,
      params: { requestId: requestId },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
