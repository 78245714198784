import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CaptchaDto } from './model';

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {
  apiName = 'Default';
  

  //generate = (config?: Partial<Rest.Config>) =>
  //  this.restService.request<any, CaptchaDto>({
  //    method: 'POST',
  //    url: '/api/app/captcha/generate',
  //  },
  //    { apiName: this.apiName, ...config });

  generate = () =>
    this.restService.request<any, CaptchaDto>({
      method: 'POST',
      url: '/api/app/captcha/generate',
    },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
