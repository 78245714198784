import { mapEnumToOptions } from '@abp/ng.core';

export enum OutsideSpecRelatedToMOJWritingsInstruments {
  AddArea = 1,
  RealEstateTransferInTheNameOfTheHeirs = 2,
  AddChartNumber = 3,
  AddDistrictName = 4,
  RemainingPartProof = 5,
  AddCivilRegistry = 6,
  RealEstateSorting = 7,
  InstrumentsUpdate = 8,
  AddModifyStreetWidth = 9,
  MergingInstruments = 10,
  UsageDiversion = 11,
  ConvertMeasurementsToMetric = 12,
}

export const outsideSpecRelatedToMOJWritingsInstrumentsOptions = mapEnumToOptions(OutsideSpecRelatedToMOJWritingsInstruments);
