import { mapEnumToOptions } from '@abp/ng.core';

export enum BillStatus {
  UnderCreateBill = 1,
  WaitingPayment = 2,
  Paid = 3,
  Closed = 4,
  Expired = 5,
  Canceled = 6,
  Returnrd = 7,
  Error = 8,
}

export const billStatusOptions = mapEnumToOptions(BillStatus);
