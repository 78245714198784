import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-login-btn',
  templateUrl: './login-btn.component.html',
  styleUrls: ['./login-btn.component.scss']
})
export class LogInComponent {
  @Input() label?: string;
  @Output() LoginClick = new EventEmitter<any>();

  constructor() {
  }
}
