import type { FileDto, SaveFileResponseDto, SaveFilesResponseDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  apiName = 'Default';

  getObjectUrlByFileName = (fileName: string) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/app/blobStorage/objectUrl`,
      params: { fileName: fileName },
    },
    { apiName: this.apiName });

  saveFileByFileDto = (fileDto: FileDto) =>
    this.restService.request<any, SaveFileResponseDto>({
      method: 'POST',
      url: `/api/app/blobStorage/saveFile`,
      body: fileDto,
    },
    { apiName: this.apiName });

  saveFilesByFile = (file: IFormFile) =>
    this.restService.request<any, SaveFilesResponseDto>({
      method: 'POST',
      url: `/api/app/blobStorage/saveFiles`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
