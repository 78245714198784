import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RealEstateTypeApi } from '../api';
import { RealEstateType } from '../model';
import { Repository } from './repository';

@Injectable({
    providedIn: 'root',
})
export class RealEstateTypeRepository implements Repository<RealEstateType, any, RealEstateType> {
    public realEstateTypes$: BehaviorSubject<RealEstateType[]> = new BehaviorSubject<RealEstateType[]>([]);

    constructor(private realEstateTypeApi: RealEstateTypeApi) {
    }

    getAll(): Observable<RealEstateType[]> {
        return this.realEstateTypeApi.getRealEstateTypes().pipe(
            tap((res) => {
                this.realEstateTypes$.next(res);
            })
        );
    }
}
