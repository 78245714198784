import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-application-layout-request',
  templateUrl: './public-application-layout-request.component.html',
  styleUrls: ['./public-application-layout-request.component.scss']
})
export class PublicApplicationLayoutRequestComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
