import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EngineeringOfficeApi } from '../api';
import { CreateUpdateEngineeringOffice, EngineeringOffice, EngineeringOfficeRes, GetAllEngineeringOfficesInput, UpdateEngineeringOffice } from '../model';
import { Repository } from './repository';
import type { PagedResultDto } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';

@Injectable({
  providedIn: 'root'
})
export class EngineeringOfficeRepository implements Repository<EngineeringOffice, CreateUpdateEngineeringOffice, EngineeringOfficeRes, GetAllEngineeringOfficesInput> {
  public engineeringOffices$: BehaviorSubject<EngineeringOffice[]> = new BehaviorSubject<EngineeringOffice[]>([]);

  constructor(private engineeringOfficeApi: EngineeringOfficeApi, private toaster: ToasterService) {
  }

  create(input: CreateUpdateEngineeringOffice): Observable<EngineeringOfficeRes> {
    return this.engineeringOfficeApi.createEngineeringOffice(input).pipe(tap(() => {
      this.toaster.success('تم اضافه المكتب بنجاح');
    }));
  }

  get(id: number): Observable<EngineeringOffice> {
    return this.engineeringOfficeApi.getEngineeringOfficeById(id);
  }

  getList(input: GetAllEngineeringOfficesInput): Observable<PagedResultDto<EngineeringOffice>> {
    return this.engineeringOfficeApi.getEngineeringOffices(input).pipe(
      tap((res) => {
        this.engineeringOffices$.next(res.items!);
      })
    );
  }
  getEngineeringOfficesForAnonymous(input: GetAllEngineeringOfficesInput): Observable<PagedResultDto<EngineeringOffice>> {
    return this.engineeringOfficeApi.getEngineeringOfficesForAnonymous(input).pipe(
      tap((res) => {
        this.engineeringOffices$.next(res.items!);
      })
    );
  }

  update(id: number, input: CreateUpdateEngineeringOffice): Observable<EngineeringOfficeRes> {
    return this.engineeringOfficeApi.updateEngineeringOffice(id, input);
  }

  getEngineeringOfficeContactInfo(): Observable<UpdateEngineeringOffice> {
    return this.engineeringOfficeApi.getEngineeringOfficeContactInfo();
  }

  updateEngineeringOfficeContactInfo(input: UpdateEngineeringOffice): Observable<EngineeringOfficeRes> {
    return this.engineeringOfficeApi.updateEngineeringOfficeContactInfo(input).pipe(tap(() => {
      this.toaster.success('تم تعديل بيانات المكتب الهدنسي بنجاح');
    }));
  }
}
