import { IdentityUserDto } from '@proxy/volo/abp/identity/models';
import {
  NewPublicUserRegisterDto,
  NewAdminRegisterDto,
  AdminDto,
  SetAdminPasswordDto,
  NewAdminRegisterResponseDto, PublicUserDto, UpdateAdminDto, UpdatePublicUserDto, GetAllAdminsInputDto
} from '@proxy/account/models';

export interface IdentityUser extends IdentityUserDto {

}

export interface Admin extends AdminDto {

}

export interface NewAdminRegister extends NewAdminRegisterDto {

}

export interface NewAdminRegisterResponse extends NewAdminRegisterResponseDto {

}

export interface NewPublicUserRegister extends NewPublicUserRegisterDto {
}

export interface SetAdminPassword extends SetAdminPasswordDto {

}
export interface GetAllAdminsInput extends GetAllAdminsInputDto {

}
export interface PublicUser extends PublicUserDto {

}
export interface UpdateAdmin extends UpdateAdminDto {

}
export interface UpdatePublicUser extends UpdatePublicUserDto {

}