import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RegionApi } from '../api';
import { Region } from '../model';
import { Repository } from './repository';

@Injectable({
    providedIn: 'root',
})
export class RegionRepository implements Repository<Region, any, Region> {
    public regions$: BehaviorSubject<Region[]> = new BehaviorSubject<Region[]>([]);

    constructor(private regionApi: RegionApi) {
    }

    getAll(): Observable<Region[]> {
        return this.regionApi.getRegions().pipe(
            tap((res) => {
                this.regions$.next(res);
            })
        );
    }
    get(regionId:number): Observable<Region> {
        return this.regionApi.getRegionById(regionId);
    }
}
