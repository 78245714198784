import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
import { AppAuthService } from './providers/auth/app-auth-service.service';
import { UserIdleService } from './providers/auth/user-idle.service';

@Component({
  selector: 'app-shared-root',
  templateUrl: './common-app.component.html'
})
export class CommonAppComponent implements OnInit {
  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {
  }

  ngOnInit(): void {


    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
