import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { loadCss, loadModules } from "esri-loader";


@Component({
  selector: 'app-shp-file',
  templateUrl: './shp-file.component.html',
  styleUrls: ['./shp-file.component.scss']
})
export class ShpFileComponent implements OnInit {

  scriptLinks = [
    'https://code.jquery.com/jquery-3.6.0.min.js',
    'assets/Js/MainGis.js']

  loadOptions = {
    url: "https://js.arcgis.com/3.41/"
  };
  dependencies = ["esri/layers/ArcGISDynamicMapServiceLayer",
    "esri/config",
    "esri/InfoTemplate",
    "esri/map",
    "esri/request",
    "esri/geometry/scaleUtils",
    "esri/layers/FeatureLayer",
    "esri/renderers/SimpleRenderer",
    "esri/symbols/PictureMarkerSymbol",
    "esri/symbols/SimpleFillSymbol",
    "esri/symbols/SimpleLineSymbol",
    "dojo/dom",
    "dojo/json",
    "dojo/on",
    "dojo/parser",
    "dojo/sniff",
    "dojo/_base/array",
    "esri/Color",
    "dojo/_base/lang",
    "dijit/layout/BorderContainer",
    "dijit/layout/ContentPane",
    "dojo/domReady!",
    "esri/virtualearth/VETiledLayer",
    "esri/tasks/AreasAndLengthsParameters",]
  MapView: any
  WebMap: any

  constructor() { }

  ngOnInit(): void {
    //    this.esriService.init()
    loadModules(this.dependencies, this.loadOptions).then(([MapView, WebMap]) => {
      this.MapView = MapView
      this.WebMap = WebMap
      loadCss("3.41")
      this.scriptLinks.forEach((e, i) => {
        let s = document.createElement('script');
        s.src = e;
        s.id = 'arcgis' + i
        s.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(s);
      });

    }).catch((e) => {
      console.log('loadModules', e);
    })
  }


  ngOnDestroy(): void {
    const scriptTags = Array.from(document.scripts);
    scriptTags.forEach(s => {
      if (s.src.includes('arcgis') || s.id.includes('arcgis'))
        s?.remove()
    });
  }

}
