import { mapEnumToOptions } from '@abp/ng.core';

export enum IdentificationType {
  National = 1,
  Passport = 2,
  Commercial = 3,
  Governmental = 4,
  HafizatNufus = 5,
  None = 6,
}

export const identificationTypeOptions = mapEnumToOptions(IdentificationType);
