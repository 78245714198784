import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ABP, eLayoutType, PermissionGuard } from '@abp/ng.core';
import { LoginGuard } from '@spga-shared/providers/guard';
import { defaultErrorPage } from '@spga-shared/components';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: {
      routes: {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'identity',
    canActivate: [PermissionGuard],
    loadChildren: () => import('@abp/ng.identity').then((m) => m.IdentityModule.forLazy())
  },
  {
    path: 'real-estate',
    canActivate: [LoginGuard],
    loadChildren: () => import('./real-estate-request/real-estate.module').then((m) => m.RealEstateModule),
    data: {
      routes: {
        path: '/real-estate',
        name: '::Menu:real-estate',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'my-requests',
    canActivate: [LoginGuard],
    loadChildren: () => import('./my-requests/my-requests.module').then((m) => m.MyRequestsModule),
    data: {
      routes: {
        path: '/my-requests',
        name: '::Menu:my-requests',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'edit-profile',
    canActivate: [LoginGuard],
    loadChildren: () => import('./edit-user-profile/edit-user-profile.module').then((m) => m.EditProfileModule),
    data: {
      routes: {
        path: '/edit-profile',
        name: '::Menu:edit-profile',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'request-succeed',
    canActivate: [LoginGuard],
    loadChildren: () => import('./request-succeed/request-succeed.module').then((m) => m.RequestSucceedModule),
    data: {
      routes: {
        path: '/request-succeed',
        name: '::Menu:request-succeed',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'bill-redirection/:billType/:refrence',
    canActivate: [LoginGuard],
    loadChildren: () => import('./bill-redirection/bill-redirection.module').then((m) => m.BillRedirectionModule),
    data: {
      routes: {
        path: '/bill-redirection',
        name: '::Menu:bill-redirection',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'engineering-office-list',
    loadChildren: () => import('./eng-office-list/eng-office-list.module').then((m) => m.EngOfficeListModule),
    data: {
      routes: {
        path: '/engineering-office-list',
        name: '::Menu:engineering-office-list',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then((m) => m.AboutUsModule),
    data: {
      routes: {
        path: '/about-us',
        name: '::Menu:about-us',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then((m) => m.TermsCondtionsModule),
    data: {
      routes: {
        path: '/terms-conditions',
        name: '::Menu:terms-conditions',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'FAQ',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FAQModule),
    data: {
      routes: {
        path: '/FAQ',
        name: '::Menu:FAQ',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
    data: {
      routes: {
        path: '/contact-us',
        name: '::Menu:contact-us',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'published-ads',
    loadChildren: () => import('./published-ads/published-ads.module').then((m) => m.PublishedAdsModule),
    data: {
      routes: {
        path: '/published-ads',
        name: '::Menu:published-ads',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'objection-request/:isEditMode/:requestId/',
    canActivate: [LoginGuard],
    loadChildren: () => import('./objection-request/objection-request.module').then((m) => m.ObjectionsRequestModule),
    data: {
      routes: {
        path: '/objection-request',
        name: '::Menu:objection-request',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'objection-request/:isEditMode/:objectionId',
    canActivate: [LoginGuard],
    loadChildren: () => import('./objection-request/objection-request.module').then((m) => m.ObjectionsRequestModule),
    data: {
      routes: {
        path: '/objection-request',
        name: '::Menu:objection-request',
        layout: eLayoutType.application
      } as ABP.Route
    }
  },
  {
    path: 'my-objections',
    canActivate: [LoginGuard],
    loadChildren: () => import('./my-objections/my-objections.module').then((m) => m.MyObjectionsModule),
    data: {
      routes: {
        path: '/my-objections',
        name: '::Menu:my-objections',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'error403',
    component: defaultErrorPage,
    data: {
      routes: {
        path: '/error403',
        name: '::Menu:error403',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  },
  {
    path: 'error405',
    component: defaultErrorPage,
    data: {
      routes: {
        path: '/error405',
        name: '::Menu:error405',
        layout: eLayoutType.empty
      } as ABP.Route
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
