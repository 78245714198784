import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { UserApi, RegionApi } from '../api';
import {
  Admin,
  GetAllAdminsInput,
  NewAdminRegister,
  NewAdminRegisterResponse,
  Region,
} from '../model';
import { Repository } from './repository';
import type { PagedResultDto } from '@abp/ng.core';
import { UpdateAdminDto, EmployeeDto } from '@proxy/account';
import { ToasterService } from '@abp/ng.theme.shared';
import { AdminRoles } from '@proxy/enums';

@Injectable({
  providedIn: 'root',
})
export class UserRepository
  implements Repository<Admin, NewAdminRegister, any, GetAllAdminsInput> {
  public users$: BehaviorSubject<Admin[]> = new BehaviorSubject<Admin[]>([]);
  public regions$: BehaviorSubject<Region[]> = new BehaviorSubject<Region[]>(
    []
  );

  constructor(
    private userApi: UserApi,
    private toaster: ToasterService,
    private regionApi: RegionApi
  ) { }

  get(id: string): Observable<Admin> {
    return this.users$
      .asObservable()
      .pipe(map((value) => value.find((x) => x.identificationNumber === id)!))
      .pipe(take(1));
  }

  getList(input: GetAllAdminsInput): Observable<PagedResultDto<Admin>> {
    return this.userApi.getAdminsListPageAndSort(input).pipe(
      tap((res) => {
        this.users$.next(res.items!);
      })
    );
  }

  getSuperVisores(employee: number): Observable<EmployeeDto[]> {
    return this.userApi.getSupervisorsByEmployee(employee);
  }
  getAll(): Observable<Admin[]> {
    return this.userApi.getAdminsList().pipe(
      tap((res) => {
        this.users$.next(res);
      })
    );
  }

  create(input: NewAdminRegister): Observable<NewAdminRegisterResponse> {
    return this.userApi.createNewAdmin(input).pipe(
      tap(() => {
        this.toaster.success('تم إضافة المستخدم بنجاح');
      })
    );
  }

  update(id: number, input: NewAdminRegister): Observable<UpdateAdminDto> {
    return this.userApi.updateAdmin(id, input);
  }

  getRegionOrSuperVisores(employee: number) {

    if (employee === AdminRoles.MemberOfTheInspectionDepartment) {
      return this.userApi.getSupervisorsByEmployee(employee).subscribe((data) => {
        let dataObj = {
          lookupType: true,
          data: data
        }
        dataObj = {
          lookupType: true,
          data: data

        }
      });
    }
    else if (employee === AdminRoles.CommitteeSecretaryToConsiderNormalRequests) {
      {
        return this.regionApi.getRegions().pipe(
          tap((res) => {
            let dataObj = {
              lookupType: false,
              data: res
            }
            this.regions$.next(res);
            dataObj = {
              lookupType: false,
              data: res

            }
          })
        );
      }
    }
    else {
      return of([])
    }
  }
  // getSupervisorUsers(): Observable<EmployeeDto[]> {
  //   return this.userApi.getSupervisorUsers();
  // }
  
  getActiveInspectionSupervisors(role:number): Observable<EmployeeDto[]> {
    return this.userApi.getActiveInspectionSupervisors(role);
  }
}
