import { mapEnumToOptions } from '@abp/ng.core';

export enum RecommendationsFilter {
  FullTitling = 1,
  OwningPartOfThePropertyWithAnArea = 2,
  OwningFullOrPart = 3,
  NotFullyTitling = 4
}

export const recommendationsFilterOptions = mapEnumToOptions(RecommendationsFilter);
