import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Request, RequestRealEstateAttachment, RequestRealEstateReportAttachment, RequestReportAttachmentRes, RequestRes,SpecializationMemberAttachmentRes } from '../model';
import { RealEstateOwnershipRequestService } from '@proxy/real-estate-ownership-requests/real-estate-ownership-request.service';
import { Rest,RestService } from '@abp/ng.core';
import {
  InspectionMemberAttachmentResponse,
  RequestReportAttachmentResponse,
  RequestResponse,
  ShariaMemberAttachmentResponse,
  TechnicalMemberAttachmentResponse,SpecializationMemberAttachmentResponse
} from '@proxy/real-estate-ownership-requests';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { SpecializationMemberService } from '@proxy/specialization-member/specialization-member.service';
import { IFormFile } from '@proxy/microsoft/asp-net-core/http';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestAttachmentApi {

  constructor(
    private realEstateOwnershipRequestService: RealEstateOwnershipRequestService,
    private restService: RestService,
    private specializationMemberService:SpecializationMemberService
  ) {
  }

  getRealEstateAttachmentsById(requestId: number): Observable<Request> {
    return this.realEstateOwnershipRequestService.getRealEstateRequestAttachment(requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  uploadRequestRealEstateAttachment(input: RequestRealEstateAttachment): Observable<RequestRes> {
    return this.realEstateOwnershipRequestService.createRealEstateRequestAttachmentByAttachmentDto(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  uploadRequestRealEstateAttachmentFormFile(requestId: number, body: FormData): Observable<RequestRes> {
    return this.restService.request<any, RequestResponse>({
        method: 'POST',
        url: `/api/app/realEstateOwnershipRequest/realEstateRequestAttachmentFormFile/${requestId}`,
        body
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  deleteRequestRealEstateAttachment(id: string): Observable<void> {
    return this.realEstateOwnershipRequestService.deleteRealEstateAttachment(id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  uploadRequestReportAttachment(input: RequestRealEstateReportAttachment): Observable<RequestReportAttachmentRes> {
    return this.realEstateOwnershipRequestService.createAreaReportAttachmentByAttachmentDto(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  uploadRequestReportAttachmentFormFile(body: FormData): Observable<RequestReportAttachmentResponse> {
    return this.restService.request<any, RequestReportAttachmentResponse>({
        method: 'POST',
        url: `/api/app/realEstateOwnershipRequest/areaReportAttachmentFormFile`,
        body
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  downloadRealEstateAttachmentById(id: string): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/${id}/downloadRealEstateAttachment`,
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  createInspectionMemberAttachmentByFile(body: FormData): Observable<InspectionMemberAttachmentResponse> {
    return this.restService.request<any, InspectionMemberAttachmentResponse>({
        method: 'POST',
        url: `/api/app/realEstateOwnershipRequest/inspectionMemberAttachment`,
        body
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  createShariaMemberAttachmentByFile(body: FormData): Observable<ShariaMemberAttachmentResponse> {
    return this.restService.request<any, ShariaMemberAttachmentResponse>({
        method: 'POST',
        url: `/api/app/realEstateOwnershipRequest/shariaMemberAttachment`,
        body
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  createTechnicalMemberAttachmentByFile(body: FormData): Observable<TechnicalMemberAttachmentResponse> {
    return this.restService.request<any, TechnicalMemberAttachmentResponse>({
        method: 'POST',
        url: `/api/app/realEstateOwnershipRequest/technicalMemberAttachment`,
        body
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });

  }
  
  createSpecializationMemberAttachmentByFile(body: FormData): Observable<SpecializationMemberAttachmentResponse> {
    return this.restService.request<any, SpecializationMemberAttachmentResponse>({
        method: 'POST',
        url: `/api/app/realEstateOwnershipRequest/specializationMemberAttachment`,
        body
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });

  }

  downloadInspectionAttachmentById(id: string): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/${id}/downloadInspectionAttachment`,
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  downloadArealReportAttachmentByRequestId(requestId: number): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/downloadArealReportAttachment/${requestId}`,
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }

  downloadRealestateLimitsAttachmentByRequestId(requestId: number): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/downloadRealestateLimitsAttachment/${requestId}`,
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName,
        observe: Rest.Observe.Response});
  }

  downloadShariaAttachmentById(id: string): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/${id}/downloadShariaAttachment`,
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName });
  }


  downloadTechnicalAttachmentById(id: string): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequest/${id}/downloadTechnicalAttachment`,
        responseType: 'blob'
      },
      { apiName: this.realEstateOwnershipRequestService.apiName,
        observe: Rest.Observe.Response });
  }

  downloadSpecializationMemberAttachmentById(id: string): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/specializationMember/${id}/downloadSpecializationAttachment`,
        responseType: 'blob'
      },
      { apiName: this.specializationMemberService.apiName });
  }
}

