import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RealEstateRequestAdsApi } from '../api';
import { PublishAdsRequest, RealEstateRequestAds ,RealEstateRequestAdsFilters} from '../model';
import { Repository } from './repository';
import { ToasterService } from '@abp/ng.theme.shared';
import type { PagedResultDto } from '@abp/ng.core';
@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestAdsRepository implements Repository<any, any, any> {

  constructor(
    private realEstateRequestAdsApi: RealEstateRequestAdsApi,
    private toaster: ToasterService
  ) { 
  }

  create(input: PublishAdsRequest): Observable<boolean> {
    return this.realEstateRequestAdsApi.publishRealEstateRequestAds(input)
    .pipe(tap((res) =>
    {
      if(res)
      this.toaster.success('تم نشر الطلبات بنجاح');
      else 
      this.toaster.error('حدث خطاء');
    } ));
  }

  getList(input: RealEstateRequestAdsFilters): Observable<PagedResultDto<RealEstateRequestAds>> {
    return this.realEstateRequestAdsApi.getRealEstateRequestList(input).pipe(
      tap((res) => {
        return res;
      })
    );
  }

}
