import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EngineeringOfficeBillsApi } from '../api';
import { EngineeringOfficeBill } from '../model';
import { Repository } from './repository';

@Injectable({
  providedIn: 'root',
})
export class EngineeringOfficeBillRepository implements Repository<any, any, any> {

  constructor(private engineeringOfficeBillsApi: EngineeringOfficeBillsApi) {
  }

  getEngineeringOfficeBill(): Observable<EngineeringOfficeBill> {
    return this.engineeringOfficeBillsApi.getEngineeringOfficeBillValue();
  }
  update(id: number, input: EngineeringOfficeBill): Observable<boolean> {
    return this.engineeringOfficeBillsApi.updateEngineeringOfficeBillValue(input);
  }
}
