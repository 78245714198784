import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Nationality } from '../model';
import { NationalityService } from '@proxy/lookup/';

@Injectable({
  providedIn: 'root'
})
export class NationalityApi {
  constructor(private nationalityService: NationalityService) {
  }

  getNationalities(): Observable<Nationality[]> {
    return this.nationalityService.getList().pipe(
      map((res) => {
        return res.items!;
      })
    );
  }
}
