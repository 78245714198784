import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { GetReassignPublicUserOperationRequestDto, GetReassignPublicUserOperationRequestsRequestDto, GetReassignPublicUserOperationRequestsResponseDto, GetReassignPublicUserOperationResponseDto, GetRequestsByIdentificationNumberRequestDto, GetRequestsByIdentificationNumberResponseDto, GetRequestsByRequestIdRequestDto, GetRequestsByRequestIdResponseDto, ReassignPublicUserRequestDto } from './models';

@Injectable({
  providedIn: 'root',
})
export class TransferPublicRequestService {
  apiName = 'Default';

  getReassignPublicUserOperation = (input: GetReassignPublicUserOperationRequestDto) =>
    this.restService.request<GetReassignPublicUserOperationRequestDto, PagedResultDto<GetReassignPublicUserOperationResponseDto>>({
      method: 'GET',
      url: `/api/app/reassignPublicUserRequests/reassignPublicUserOperation`,
      params: { maxResultCount: input.maxResultCount, skipCount: input.skipCount, sorting: input.sorting, filter: input.filter}
    },
      { apiName: this.apiName });

  getReassignPublicUserOperationRequests = (input: GetReassignPublicUserOperationRequestsRequestDto) =>
    this.restService.request<GetReassignPublicUserOperationRequestsRequestDto, PagedResultDto<GetReassignPublicUserOperationRequestsResponseDto>>({
      method: 'GET',
      url: `/api/app/reassignPublicUserRequests/reassignPublicUserOperationRequests`,
      params: { maxResultCount: input.maxResultCount, skipCount: input.skipCount, sorting: input.sorting, operationId: input.operationId}
    },
      { apiName: this.apiName });

  getRequestsByIdentificationNumber = (input: GetRequestsByIdentificationNumberRequestDto) =>
    this.restService.request<GetRequestsByIdentificationNumberRequestDto, PagedResultDto<GetRequestsByIdentificationNumberResponseDto>>({
      method: 'GET',
      url: `/api/app/reassignPublicUserRequests/requestsByIdentificationNumber`,
      params: { identificationNumber: input.identificationNumber, maxResultCount: input.maxResultCount, skipCount: input.skipCount}
    },
      { apiName: this.apiName });


  getRequestsByRequestId = (input: GetRequestsByRequestIdRequestDto) =>
    this.restService.request<GetRequestsByRequestIdRequestDto, GetRequestsByRequestIdResponseDto>({
      method: 'GET',
      url: `/api/app/reassignPublicUserRequests/requestsByRequestId`,
      params: { requestId: input.requestId },
    },
      { apiName: this.apiName });

  postReassignPublicUserRequests = (input: ReassignPublicUserRequestDto) =>
    this.restService.request<ReassignPublicUserRequestDto, void>({
      method: 'POST',
      url: `/api/app/reassignPublicUserRequests/reassignPublicUserRequests`,
      body: input,
    },
      { apiName: this.apiName });

  constructor(private restService: RestService) { }
}
