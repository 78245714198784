import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Request, RequestRealestateLocation, RequestRes } from '../model';
import { RealEstateOwnershipRequestService } from '@proxy/real-estate-ownership-requests/real-estate-ownership-request.service';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestLocationApi {
  constructor(private realEstateOwnershipRequestService: RealEstateOwnershipRequestService) {
  }

  getRealEstateLocationById(requestId: number): Observable<Request> {
    return this.realEstateOwnershipRequestService.getRealEstateRequestLocation(requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createRealEstateLocation(input: RequestRealestateLocation): Observable<RequestRes> {
    return this.realEstateOwnershipRequestService.createRealEstateRequestLocationByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
