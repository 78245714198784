import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Request, RequestOwnership, RequestRealEstateEndors, RequestRes } from '../model';
import { RealEstateOwnershipRequestService } from '@proxy/real-estate-ownership-requests/real-estate-ownership-request.service';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestOwnerApi {
  constructor(private realEstateOwnershipRequestService: RealEstateOwnershipRequestService) {
  }

  getRealEstateOwnershipById(requestId: number): Observable<Request> {
    return this.realEstateOwnershipRequestService.getRealEstateRequestOwnership(requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createRealEstateOwnership(input: RequestOwnership): Observable<RequestRes> {
    return this.realEstateOwnershipRequestService.createRealEstateRequestOwnershipByRequest(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createRealEstateRequestEndorsement(input: RequestRealEstateEndors): Observable<RequestRes> {
    return this.realEstateOwnershipRequestService.createRealEstateRequestEndorsementByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  isCreateNewRequestEnabled(): Observable<boolean> {
    return this.realEstateOwnershipRequestService.isCreateNewRequestEnabled().pipe(
      map((res) => {
        return res;
      })
    );
  }
  
}
