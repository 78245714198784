import { Directive, ElementRef, HostListener, Input } from '@angular/core';
    @Directive({
        selector: '[appInputRestriction]'
    })
    export class InputRestrictionDirective {
        inputElement: ElementRef;

        @Input('appInputRestriction') appInputRestriction!: string;
        arabicRegex = /[\u0621-\u064A\u0660-\u0669 ]/g;
        arabicAndNumberRegex = /[\u0621-\u064A0-9 ]/g;
        englishAndNumberRegex = /[A-Za-z0-9 ]/g;
        englishAndArabicLettersRegex = /[a-zA-Zء-ي ]/g;
        englishArabicWithNumbersRegex = /[a-zA-Z0-9ء-ي ]/g;
        arabicAndNumberWithDotRegex = /[ء-ي0-9. ]/g;
        arabicAndNumberWithDotCommaRegex = /[ء-ي0-9., ]/g;
        numbersWithDotRegex = /[0-9.-]/g;
        arabicLettersWithPlusRegex = /[ء-ي0-9. +]/g;

        constructor(el: ElementRef) {
            this.inputElement = el;
        }

        @HostListener('keypress', ['$event']) onKeyPress(event: any) {

            if (this.appInputRestriction === 'integerOnly') {
                this.integerOnly(event);
            }else if (this.appInputRestriction === 'number') {
                this.number(event);
            } else if (this.appInputRestriction === 'englishOnly') {
                this.englishOnly(event);
            } else if (this.appInputRestriction === 'arabicOnly') {
                this.arabicOnly(event);
            } else if (this.appInputRestriction === 'englishAndNumbersOnly') {
                this.englishAndNumbersOnly(event);
            } else if (this.appInputRestriction === 'arabicAndNumbersOnly') {
                this.arabicAndNumbersOnly(event);
            }
            else if (this.appInputRestriction === 'englishArabicLettersOnly') {
                this.englishArabicLettersOnly(event);
            }
            else if (this.appInputRestriction === 'englishArabicWithNumbers') {
                this.englishArabicWithNumbers(event);
            }
            else if (this.appInputRestriction === 'arabicAndNumberWithDot') {
                this.arabicAndNumberWithDot(event);
            }
            else if (this.appInputRestriction === 'arabicAndNumberWithDotComma') {
                this.arabicAndNumberWithDotComma(event);
            }
            else if (this.appInputRestriction === 'numbersWithDot') {
                this.numbersWithDot(event);
            }
            else if (this.appInputRestriction === 'noSpecialCharachters') {
                this.noSpecialCharachters(event);
            }
            else if (this.appInputRestriction === 'noSpecialCharachters') {
                this.noSpecialCharachters(event);
            }
            else if (this.appInputRestriction === 'arabicLettersWithPlus') {
                this.arabicLettersWithPlus(event);
            }
        }
        noSpecialCharachters(event: KeyboardEvent){
            const e = <KeyboardEvent>event;
            if (!/[a-z0-9 \u0621-\u064A\u0660-\u0669]/.test(e.key)) {
               event.preventDefault();
            }
        }
        integerOnly(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;

            if (e.key === 'Tab' || e.key === 'TAB') {
                return;
            }
            if(e.key == '.' || e.key == 'n'){
                e.preventDefault();
            }
            if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (e.keyCode === 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+V
                (e.keyCode === 86 && e.ctrlKey === true) ||

                // Allow: Ctrl+X
                (e.keyCode === 88 && e.ctrlKey === true)) {
                // let it happen, don't do anything
                return;
            }
            if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) {
                e.preventDefault();
            }
        }

        number(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;

            if (e.key === 'Tab' || e.key === 'TAB') {
                return;
            }
            if(e.key == 'n'){
                e.preventDefault();
            }
            if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (e.keyCode === 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+V
                (e.keyCode === 86 && e.ctrlKey === true) ||

                // Allow: Ctrl+X
                (e.keyCode === 88 && e.ctrlKey === true)) {
                // let it happen, don't do anything
                return;
            }
            if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) {
                e.preventDefault();
            }
        }
        numbersWithDot(event: KeyboardEvent){
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.numbersWithDotRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        englishOnly(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            if (e.key === 'Tab' || e.key === 'TAB') {
                return;
            }
            let k;
            k = event.keyCode;  // k = event.charCode;  (Both can be used)
            if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
                return;
            }
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.arabicRegex);
            if (!regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        englishAndNumbersOnly(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.englishAndNumberRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        arabicOnly(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.arabicRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        arabicAndNumbersOnly(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.arabicAndNumberRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        englishArabicLettersOnly(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.englishAndArabicLettersRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        englishArabicWithNumbers(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.englishArabicWithNumbersRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        arabicAndNumberWithDot(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.arabicAndNumberWithDotRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        arabicLettersWithPlus(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.arabicLettersWithPlusRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        arabicAndNumberWithDotComma(event: KeyboardEvent) {
            const e = <KeyboardEvent>event;
            const ch = String.fromCharCode(e.keyCode);
            const regEx = new RegExp(this.arabicAndNumberWithDotCommaRegex);
            if (regEx.test(ch)) {
                return;
            }
            e.preventDefault();
        }

        //prevent copy/paste wronge data
        @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {

            let regex;
            if (this.appInputRestriction === 'integerOnly') {
                regex = /[0-9]/g;
            }
            else if (this.appInputRestriction === 'englishOnly') {
                regex = /[a-zA-Z0-9]/g;
            }
            else if (this.appInputRestriction === 'arabicOnly') {
                regex = this.arabicRegex;
            }
            else if (this.appInputRestriction === 'arabicAndNumbersOnly') {
                regex = this.arabicAndNumberRegex;
            }
            else if(this.appInputRestriction === 'arabicAndNumberWithDot')
            {
                regex = this.arabicAndNumberWithDotRegex;
            }
            else if(this.appInputRestriction === 'numbersWithDot')
            {
                regex = this.numbersWithDotRegex;
            }
            else if(this.appInputRestriction === 'englishArabicLettersOnly')
            {
                regex = this.englishAndArabicLettersRegex;
            }
            else if (this.appInputRestriction === 'englishAndNumbersOnly') {
                regex = this.englishAndNumberRegex;
            }
            else if (this.appInputRestriction === 'noSpecialCharachters')
            {
                regex = /[a-z0-9 \u0621-\u064A\u0660-\u0669]/;
            }
            else if (this.appInputRestriction === 'arabicLettersWithPlus')
            {
                regex = this.arabicLettersWithPlusRegex;
            }


            const e = <ClipboardEvent>event;
            const pasteData = e.clipboardData?.getData('text/plain');
            let m;
            let matches = 0;
            if(regex)
            {
                while ((m = regex.exec(pasteData!)) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }
                    // The result can be accessed through the `m`-variable.
                    m.forEach((match, groupIndex) => {
                        matches++;
                    });
                }
                if (matches === pasteData!.length) {
                    return;
                } else {
                    e.preventDefault();
                }
            }


        }

    }
