<div class="main-nav-bar">
    <ul class="navbar-nav main-nav-bar__sections">
        <li class="nav-item active">
            <a class="nav-link" (click)="onNavItemClick()" routerLinkActive="is-active" routerLink="/">الرئيسية <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onNavItemClick()" routerLinkActive="is-active" routerLink="about-us">عن إحكام</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onNavItemClick()" routerLinkActive="is-active" routerLink="terms-conditions">الشروط و الوثائق</a>

        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onNavItemClick()" routerLinkActive="is-active" routerLink="engineering-office-list"> المكاتب الهندسية</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onNavItemClick()" routerLinkActive="is-active" routerLink="FAQ"> الأسئلة الشائعة</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onNavItemClick()" routerLinkActive="is-active" routerLink="contact-us"> اتصل بنا </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="onNavItemClick()" routerLinkActive="is-active" routerLink="published-ads">الإعلانات </a>
        </li>

    </ul>


</div>