import { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { TransferPublicRequestApi } from '@data/api';
import { GetReassignPublicUserOperationRequestsRequestDto, GetReassignPublicUserOperationRequestsResponseDto, GetReassignPublicUserOperationResponseDto, GetRequestsByIdentificationNumberRequestDto, GetRequestsByIdentificationNumberResponseDto, GetRequestsByRequestIdRequestDto, GetRequestsByRequestIdResponseDto, ReassignPublicUserRequestDto } from '@proxy/transfer-public-request/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransferPublicRequestRepository {

  constructor(private transferPublicRequestApi: TransferPublicRequestApi,
  ) { }

  // getList(input: GetReassignPublicUserOperationResponseDto): Observable<PagedResultDto<GetReassignPublicUserOperationResponseDto>> {
  //   return this.transferPublicRequestApi.getReassignPublicUserOperation(input);
  // }

  getOperationRequests(input: GetReassignPublicUserOperationRequestsRequestDto): Observable<PagedResultDto<GetReassignPublicUserOperationRequestsResponseDto>> {
    return this.transferPublicRequestApi.getReassignPublicUserOperationRequests(input);
  }

  getList(input: GetRequestsByIdentificationNumberRequestDto): Observable<PagedResultDto<GetRequestsByIdentificationNumberResponseDto>> {    
    return this.transferPublicRequestApi.getRequestsByIdentificationNumber(input);
  }

  getRequestsByRequestId(input: GetRequestsByRequestIdRequestDto): Observable<GetRequestsByRequestIdResponseDto> {
    return this.transferPublicRequestApi.getRequestsByRequestId(input);
  }
  
  postReassignPublicUserRequests(input: ReassignPublicUserRequestDto): Observable<void> {
    return this.transferPublicRequestApi.postReassignPublicUserRequests(input);
  }

}
