import { environment } from '../../../environments/environment';
import { AttachmentInput } from '../../data/model';
import { AttachmentType } from '../../proxy/enums';

export class Constants {
  static isProduction = environment.production;
  static adminSiteAllowedRoles = ['admin', 'EngineeringOffice', 'InspectionMember' , 'TechnicalMember','ShariaMember','NormalCommitteeSecretary','FormalCommitteeSecretary','AwqafCommitteeSecretary','GovernmentCommitteeSecretary','PublishingUnitAndObjectionsEmployee','AuditCommitteeSecretary','InspectionSupervisor','SpecializationStudyMember','PreliminaryStudiesCommitteeSupervisor' , 'RequestsStudyingMember', 'EmirateRepresentative','SupervisorOfTheTechnicalDepartment','SupervisorOfTheShariaAdministration','TransferPublicRequest'];
}

export const AreaReportAttachmentsInputs: () => AttachmentInput[] = () => [
  { attachmentType: AttachmentType.ZIPFile, title: 'مخطط ZIP SHAPEFILE' },
  { attachmentType: AttachmentType.ArealReport, title: 'التقرير المساحي PDF' },
  { attachmentType: AttachmentType.AutoCADFile, title: 'ملف اوتوكاد DWG'  ,optional: true},
  { attachmentType: AttachmentType.SupportiveAdditionalAttachment, title: 'ملف داعم صيغة PDF و Excel و SHAPEFILE', optional: true, count: 1 },
  { attachmentType: AttachmentType.SupportiveAdditionalAttachment, title: 'ملف داعم صيغة PDF و Excel و SHAPEFILE', optional: true, count: 2 },
  { attachmentType: AttachmentType.SupportiveAdditionalAttachment, title: 'ملف داعم صيغة PDF و Excel و SHAPEFILE', optional: true, count: 3 },
  { attachmentType: AttachmentType.SupportiveAdditionalAttachment, title: 'ملف داعم صيغة PDF و Excel و SHAPEFILE', optional: true, count: 4 },
  { attachmentType: AttachmentType.SupportiveAdditionalAttachment, title: 'ملف داعم صيغة PDF و Excel و SHAPEFILE', optional: true, count: 5 },
  { attachmentType: AttachmentType.SupportiveAdditionalAttachment, title: 'ملف داعم صيغة PDF و Excel و SHAPEFILE', optional: true, count: 6 }
];