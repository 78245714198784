import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublicUser, UpdatePublicUser } from '../model';
import { SPGAPublicUserService } from '@proxy/account/spgapublic-user.service';
import { UserUpdatedDto } from '@proxy/account/models';

@Injectable({
  providedIn: 'root'
})
export class PublicUserApi {
  constructor(private sPGAAccountService: SPGAPublicUserService) {
  }

  GetUpdatedUserInfo(): Observable<UserUpdatedDto> {
    return this.sPGAAccountService.GetUpdatedUserInfo();
  }

  generateConfirmationEmail(): Observable<void> {
    return this.sPGAAccountService.generateConfirmationEmail();
  }

  getPublicUser(): Observable<PublicUser> {
    return this.sPGAAccountService.getPublicUser();
  }

  updatePublicUser(id: string, input: UpdatePublicUser): Observable<UpdatePublicUser> {
    return this.sPGAAccountService.updatePublicUser(id, input).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
