import type { EntityDto } from '@abp/ng.core';

export interface CityDto extends EntityDto<number> {
  name: string;
}

export interface CourtDto extends EntityDto<number> {
  courtName: string;
}

export interface CreateCityDto {
  name: string;
  regionId: number;
}

export interface CreateRegionDto {
  name: string;
}

export interface DocumentTypeDto extends EntityDto<number> {
  name: string;
}

export interface NationalityDto extends EntityDto<number> {
  nationalityArabic: string;
}

export interface RealEstateTypeDto extends EntityDto<number> {
  name: string;
}

export interface RegionDto extends EntityDto<number> {
  name: string;
}

export interface UpdateCityDto {
  name: string;
  regionId: number;
}

export interface UpdateRegionDto {
  name: string;
}
