import type { CommitteeVote } from '../enums/committee-vote.enum';
import type { FormalGovCommitteeVote } from '../enums/formal-gov-committee-vote.enum';
import type { EntityDto } from '@abp/ng.core';
import type { AttachmentType } from '../enums/attachment-type.enum';
import type { FormalGovOrganizationName } from '../enums/formal-gov-organization-name.enum';
import type { MainReserveMember } from '../enums/main-reserve-member.enum';
import type { MemberVote } from '../enums/member-vote.enum';
import type { AbstainingReason } from '../enums/abstaining-reason.enum';
import type { ConsiderationCommitteeStatus } from '../enums/consideration-committee-status.enum';

export interface CommitteeVotingDetailsDto {
  committeeVote: CommitteeVote;
  votingReason: string;
  memebersVoting: MemeberVotingDto[];
  area?: string;
}

export interface CommitteeVotingDto {
  id?: number;
  requestId: number;
  vote: CommitteeVote;
  area?: string;
  votingReason: string;
}

export interface ConsiderationCommitteeDto {
  requestId: number;
  requestCommitteeStatus: RequestCommitteeStatusDto;
  committeeVoting: CommitteeVotingDto;
  memeberVotings: MemeberVotingDto[];
  statementId?: number;
  statementReply: string;
  isInAds: boolean;
  isPublished: boolean;
  isAdvFromAudit: boolean;
}

export interface FormalGovCommitteeVotingDetailsDto {
  committeeVote: CommitteeVote;
  votingReason: string;
  memebersVoting: MemeberVotingDto[];
  area?: string;
}

export interface FormalGovCommitteeVotingDto {
  id?: number;
  requestId: number;
  formalGovRecommendation: string;
  fomralGovVote?: FormalGovCommitteeVote;
  formalGovReasonOfRefuse: string;
  formalGovRecordNumber: string;
  formalGovRecordDate: string;
}

export interface FormalGovConsiderationCommitteeDto {
  requestId: number;
  formalGovCommitteeVoting: FormalGovCommitteeVotingDto;
  formalGovMemeberVotings: FormalGovMemeberVotingDto[];
  attachmentsUrls: FormalGovMemeberAttachmentDto[];
}

export interface FormalGovMemeberAttachmentAttachmentResponseDto {
  url: string;
  blobName: string;
}

export interface FormalGovMemeberAttachmentDto extends EntityDto<string> {
  description: string;
  name: string;
  extension: string;
  attachmentType: AttachmentType;
  url: string;
}

export interface FormalGovMemeberVotingDto {
  id?: number;
  requestId: number;
  name: string;
  formalGovOrganizationName?: FormalGovOrganizationName;
  formalGovMainReserveMember: MainReserveMember;
  vote?: MemberVote;
  disapprovalReason: string;
  formalGovAbstainingReason: string;
}

export interface FormlGovRequestMinutesDto {
  formalGovRecordNumber: string;
  formalGovRecordDate?: string;
  requestId: number;
  formalModificationText: string[];
  ownerName: string;
  ownerIdentificationNumber: string;
  formalGovRecommendation: string;
  formalGovReasonOfRefuse: string;
  organizationName: string;
  memeberVotings: MemeberVotingDto[];
}

export interface MemeberVotingDto {
  id?: number;
  requestId: number;
  name: string;
  organizationName: string;
  vote?: MemberVote;
  disapprovalReason: string;
  abstainingReason?: AbstainingReason;
  abstainingReasonExplanation: string;
}

export interface RequestCommitteeStatusDto {
  id?: number;
  requestId: number;
  status?: ConsiderationCommitteeStatus;
  date?: string;
  reasonOfStatus: string;
}
