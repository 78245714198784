<div class="page__content">
  <div class="my-profile">
    <img src="assets/images/user-profile.svg" alt="user image" class="img-fluid page__image">
    <div>
    <p class="page__description">{{userData.name}}</p>
    <p class="d-flex"><i class="icon icon-mail"></i><span class="user-email">{{userData.emailAddress}}</span></p>
    <p class="d-flex"><i class="icon icon-phone"></i><span class="user-phone">{{userData.phoneNumber}}</span></p>
  </div>
  </div>
  <div class="mb-3">
    <app-add-request-btn *ngIf="isCreateRequestEnabled"></app-add-request-btn>
  </div>
  <div class="actions">
  <div class="col-6 col-lg-12 p-0 actions-wrraper">
    <button class="btn btn-outline-dark actions-btn"
    (click)="navigateToEdit()" type="submit"> تعديل الحساب
    </button>
  </div>
  <div class="col-6 col-lg-12 p-0 actions-wrraper">
    <button class="btn btn-outline-danger actions-btn "
          (click)="navigateToMyObjections()" type="submit">اعتراضاتي
  </button>
  </div>
  </div>
</div>
