import { mapEnumToOptions } from '@abp/ng.core';

export enum ChangeEngOfficeReasons {
    DueToTheClosureOfTheEngineeringOffice = 1,
    BecauseTheEngineeringOfficeDidNotRespondToTheBeneficiaryAndRespondToHisCalls = 2,
    ImposingAnAdditionalAmountOfMoney = 3,
    TheEndOfTheContractualRelationshipWithTheCurrentEngineeringOffice = 4,
    TheInabilityOfTheEngineeringOfficeToProvideTheRequired = 5,
    ChangingTheActivityOfTheEngineeringOffice = 6,
    Other = 7
}

export const changeEngOfficeReasonsOptions = mapEnumToOptions(ChangeEngOfficeReasons);
