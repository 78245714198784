import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-query-message-popup',
  templateUrl: './query-message-popup.component.html',
  styleUrls: ['./query-message-popup.component.scss']
})
export class QueryMessagePopupComponent implements OnInit, OnDestroy {
  subscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private confirmation: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    // @ts-ignore
    this.subscription = this.route.queryParams.subscribe(({ message, type }) => {
      if (message && type) {
        switch (type) {
          case 'info':
            return this.confirmation.info(message, 'معلومة', { hideYesBtn: true, cancelText: 'حسناَ' });
          case 'error':
            return this.confirmation.error(message, 'خطأ', { hideYesBtn: true, cancelText: 'حسناَ' });
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
