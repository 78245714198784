import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublicUserBill } from '../model';
import { BillsService } from '@proxy/bills';

@Injectable({
  providedIn: 'root'
})
export class PublicUsersBillsApi {
  constructor(private billsService: BillsService) {
  }

  updatePublicUserBillValue(input: PublicUserBill): Observable<boolean> {
    return this.billsService.createPublicUserBillValuesByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPublicUserBillValue(): Observable<PublicUserBill> {
    return this.billsService.getPublicUserBillSettings().pipe(
      map((res) => {
        return res;
      })
    );
  }
}
