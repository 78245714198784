import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdminObjectionsApi } from '../api';
import { ObjectionInForm,GetObjectionAdminListRequest,GetObjectionAdminListRes,ObjectionDetailsPerRequest} from '../model';
import { Repository } from './repository';
import type { PagedResultDto } from '@abp/ng.core';
@Injectable({
  providedIn: 'root'
})
export class AdminObjectionsRepository implements Repository<any, any, any, GetObjectionAdminListRequest> {

  constructor(
    private adminObjectionsApi: AdminObjectionsApi
  ) { 
  }

  get(id: number): Observable<ObjectionInForm> {
    return this.adminObjectionsApi.getObjectionDetails(id);
  }

  getObjectionDetailsList(input: ObjectionDetailsPerRequest): Observable<PagedResultDto<ObjectionInForm>> {
    return this.adminObjectionsApi.getObjectionDetailsList(input);
  }

  getList(input: GetObjectionAdminListRequest): Observable<PagedResultDto<GetObjectionAdminListRes>> {
    return this.adminObjectionsApi.getAdminObjectionList(input).pipe(
      tap((res) => {
        return res;
      })
    );
  }
}
