import { mapEnumToOptions } from '@abp/ng.core';

export enum RevivalType {
    Agricultural = 1,
    Residential = 2,
    Well = 3,
    Other = 200
}

export const revivalTypeOptions = mapEnumToOptions(RevivalType);
