<div *ngIf="request;else noRequest" class="request-card">
    <div class="request-card__date-type">
        <div class="request-card__date">
            <i class="icon icon-calendar"></i>
            <div>{{date}}</div>
            <div>تاريخ الطلب</div>
        </div>
        <div class="request-card__type">
            <p>
            {{request.requestTypeText}}
        </p>
        </div>
    </div>
    <div class="request-card__data">
        <div class="row">
            <div class="col-lg-8 col-12">
                <h2 class="request-card__id">#{{request.requestId}}</h2>
                <p class="request-card__status">حالة الطلب : {{request.requestStatusText}}</p>
                <p *ngIf="request.location && request.regionName && request.cityName" class="request-card__location">
                    <i class="icon icon-location"></i> {{request.location}}
                </p>
             <p *ngIf="request.engineeringOfficeMobile" class="request-card__phone-number">رقم المكتب الهندسي : {{request.engineeringOfficeMobile}}
                </p>
            </div>
            <div class="col-lg-4 col-12 d-flex flex-column align-items-center justify-content-center mt-3">
                <button [fragment]="!isEditMode(request,true) ? 'summary':''" [routerLink]="'/real-estate/'+request.requestId"
                    class="btn btn-outline-dark btn-block">
                    <i [class.icon-edit]="isEditMode(request)" [class.icon-eye]="!isEditMode(request)" class="icon"></i>
                    {{isEditMode(request) ? 'تعديل الطلب' : 'استعراض'}}
                </button>
                <button class="btn btn-outline-danger btn-block mt-2 px-2" (click)="onChangeEngOffice()"
                [disabled]="request.changeEngOfficeStatus==changeEngOfficeStatuses.Disabled"
                *ngIf="request.changeEngOfficeStatus==changeEngOfficeStatuses.Show || request.changeEngOfficeStatus==changeEngOfficeStatuses.Disabled">
                    تغيير المكتب الهندسي
                </button>
            </div>
        </div>
        <br>
        <ng-container>
            <a class="tertiary" *ngIf="request.billStatus == billStatus.WaitingPayment || request.billStatus == billStatus.UnderCreateBill"
                [routerLink]="['/bill-redirection', billType.Request ,request.reference ]">
                <span class="icon icon-invoice"></span>
                سداد الفاتورة 
            </a>
            <a class="tertiary" *ngIf="request.billStatus == billStatus.Paid"
                [routerLink]="['/bill-redirection', billType.Request ,request.reference ]">
                <span class="icon icon-invoice"></span> 
                استعراض الفاتورة 
            </a>
        </ng-container>
    </div>
</div>
<ng-template #noRequest>
    <div class="alert alert-warning">لا يوجد طلبات</div>
</ng-template>