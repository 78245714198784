import { mapEnumToOptions } from '@abp/ng.core';

export enum FormalGovOrganizationName {
  StateRealEstateGeneralAuthority = 1,
  MinistryOfInteriorAffairs = 2,
  MinistryOfJustice = 3,
  MinistryOfEnvironmentWaterAndAgriculture = 4,
  MinistryOfMunicipalAndRuralAffairsAndHousing = 5,
  MinistryOfFinance = 6,
}

export const formalGovOrganizationNameOptions = mapEnumToOptions(FormalGovOrganizationName);
