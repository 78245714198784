import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AttachmentsDescriptions, attachmentsDescriptions } from '@data/common/enum';
import { noSpecialCharactersValidator } from '@spga-shared/validators';

export interface IAttachmentInfo {
  description?: string;
  attachmentDate?: string;
  url?: string;
  ddlDescription?: string;
  otherDescription?: string;
}

@Component({
  selector: 'app-uploader-popup',
  templateUrl: './uploader-popup.component.html',
  styleUrls: ['./uploader-popup.component.scss']
})
export class UploaderPopupComponent implements OnInit {
  @Input() file!: File;
  @Input() hasDate = true;
  @Input() hasDescription = true;
  @Input() hasDDL = false;

  @Output() attachmentInfo = new EventEmitter<IAttachmentInfo>();

  isModalOpen = true;
  form!: UntypedFormGroup;
  description?: string;
  attachmentDate?: string;
  previewImageSource?: string;
  docType = '';
  showDescriptionText= false;
  attachmentsDescriptions = attachmentsDescriptions;

  constructor( private fb: UntypedFormBuilder) {

  }

  ngOnInit(): void {
    if (this.isImage()) {
      this.readURL();
    }
    this.buildForm();
  }

  readURL(): void {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(this.file);
    fileReader.onload = e => {
      this.previewImageSource = e.target?.result as string;
    };
  }
  
  buildForm() {
    this.form = this.fb.group({
      descriptionDDL :!this.hasDescription ? [null , Validators.required] : [null] ,
      otherDescriptions: [''],
      description:[null, [noSpecialCharactersValidator()]]
    });
  }
   onDescriptionChange(ev: any){
    if (ev === AttachmentsDescriptions.Other) {
       this.showDescriptionText = true;
       this.form.controls.otherDescriptions.setValidators(Validators.required);
       this.form.controls.otherDescriptions.updateValueAndValidity();
  }
  else {
    this.showDescriptionText = false;
    this.form.controls.otherDescriptions.reset();
    this.form.controls.otherDescriptions.clearValidators();
    this.form.controls.otherDescriptions.updateValueAndValidity();
  }
}

  onSaveUploadedFile(isCancel = false): void {
    this.isModalOpen = false;
    if (this.hasDDL){
      this.description = !this.showDescriptionText ? this.form.controls.descriptionDDL.value :
      this.form.controls.otherDescriptions.value;
    }
    setTimeout(() =>
        this.attachmentInfo.emit(isCancel ? undefined : {
          description: this.hasDDL ? this.description : this.form.controls.description.value,
          attachmentDate: this.attachmentDate,
          url: this.previewImageSource
          // ddlDescription: this.form.controls.descriptionDDL.value,
          // otherDescription: this.form.controls.otherDescriptions.value
        }),
      250);
  }

  isImage(): boolean {
    if (!this.file) {
      return false;
    }
    this.docType = (this.file.name.split('.').pop() || '').toLowerCase();
    return /(png|gif|jpg|jpeg)$/i.test(this.file.type || '');
  }
}
