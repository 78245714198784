import { PagedAndSortedResultRequestDto } from "@abp/ng.core";
import { RealEstateOwnershipRequestType } from "@proxy/enums";
import { number } from "ngx-custom-validators/src/app/number/validator";

export interface GetReassignPublicUserOperationResponseDto {
    id: number;
    idNumberFrom: string;
    idNumberTo: string;
    requests: string;
    noOfRequests: number;
    operationDate: string
}

export interface GetReassignPublicUserOperationRequestDto extends PagedAndSortedResultRequestDto {
    filter?: string;
}

export interface GetReassignPublicUserOperationRequestsResponseDto {
    operationId: number;
    requestId: number;
    realstateType: string;
    creationDate: string;
    requestType: RealEstateOwnershipRequestType
    ownerName: string;
    ownerIdentificationId: string;
    ownerRepresentativeName: string;
    ownerRepresentativeId: string;
    city: string;
    region: string;
    area: string;
}

export interface GetReassignPublicUserOperationRequestsRequestDto extends PagedAndSortedResultRequestDto {
    operationId: number;
}

export interface GetRequestsByIdentificationNumberRequestDto extends PagedAndSortedResultRequestDto {
    identificationNumber: string;
}

export interface GetRequestsByIdentificationNumberResponseDto {
    requestId: number;
    realEstateTypeName: string;
    regionName: string;
    cityName: string;
    requestCreatedDate: string;
}

export interface GetRequestsByRequestIdRequestDto {
    requestId: string;
}

export interface GetRequestsByRequestIdResponseDto {
    operationId: number;
    requestId: number;
    realstateType: string;
    creationDate: string;
    requestType: RealEstateOwnershipRequestType
    ownerName: string;
    ownerIdentificationId: string;
    ownerRepresentativeName: string;
    ownerRepresentativeId: string;
    city: string;
    region: string;
    area: string;
}

export interface ReassignPublicUserRequestDto {
    idNumberFrom: string;
    idNumberTo: string;
    lstRequests: string[];
}