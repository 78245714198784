import { Injectable } from '@angular/core';
import { UserUpdatedDto } from '@proxy/account/models';
import { Observable } from 'rxjs';
import { PublicUserApi } from '../api';
import { PublicUser, UpdatePublicUser } from '../model';
import { Repository } from './repository';

@Injectable({
    providedIn: 'root'
})
export class PublicUserRepository implements Repository<PublicUser, PublicUser, any> {

    constructor(private userApi: PublicUserApi) {
    }

    GetUpdatedUserInfo(): Observable<UserUpdatedDto> {
        return this.userApi.GetUpdatedUserInfo();
      }

    generateConfirmationEmail(): Observable<void> {
        return this.userApi.generateConfirmationEmail();
    }

    get(): Observable<PublicUser> {
        return this.userApi.getPublicUser();

    }

    update(id: string, input: UpdatePublicUser): Observable<UpdatePublicUser> {
        return this.userApi.updatePublicUser(id, input);
    }

    delete(id: number): Observable<void> {
        throw Error('Not Implemented');
    }
}
