import type { CreateStatementAttachmentResponseDto, GetStatementsByRequestIdDto, RequestStatemenDto, RequestStatementReplyDto, RequestStatementReplyResponse, RequestStatementResponse, StatementByRequestIdResponse, StatementReplyHistoryForAllDeptRequestDto, StatementReplyHistoryForAllDeptResponse, StatementReplyHistoryPerDeptRequestDto, StatementReplyHistoryPerDeptResponseDto, SavedBeneficiaryStatementDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { StatementType } from '../enums/statement-type.enum';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';
import type { FileContentResult } from '../microsoft/asp-net-core/mvc/models';
import type { MenuDetailsDto } from '../statement-administration/models';

@Injectable({
  providedIn: 'root',
})
export class RealEstateOwnershipRequestsStatementsService {
  apiName = 'Default';

  createRequestStatementByInput = (input: RequestStatemenDto) =>
    this.restService.request<any, RequestStatementResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequestsStatements/requestStatement`,
      body: input,
    },
      { apiName: this.apiName });
  //SavedBeneficiaryStatement
  //Task<SavedBeneficiaryStatementDto> GetAsync(long requestId, int adminRole);
  //Task<int> InsertAsync(SavedBeneficiaryStatementDto statement);
  //Task<bool> DeleteAsync(long requestId, int adminRole);
  createSavedBeneficiaryStatement = (input: SavedBeneficiaryStatementDto) =>
    this.restService.request<any, number>({
      method: 'POST',
      url: `/api/app/savedBeneficiaryStatement`,
      body: input,
    },
      { apiName: this.apiName });

  getSavedBeneficiaryStatement = (requestId: number) =>
    this.restService.request<any, SavedBeneficiaryStatementDto>({
      method: 'GET',
      url: `/api/app/savedBeneficiaryStatement`,
      params: { requestId: requestId },

    },
      { apiName: this.apiName });

  createRequestStatementReplyByInput = (input: RequestStatementReplyDto) =>
    this.restService.request<any, RequestStatementReplyResponse>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequestsStatements/requestStatementReply`,
      body: input,
    },
    { apiName: this.apiName });

  createStatementAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, CreateStatementAttachmentResponseDto>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequestsStatements/statementAttachment`,
    },
    { apiName: this.apiName });

  downloadStatementAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequestsStatements/${id}/downloadStatementAttachment`,
    },
    { apiName: this.apiName });

  getStatementReplyHistoryForAllDept = (input: StatementReplyHistoryForAllDeptRequestDto) =>
    this.restService.request<any, StatementReplyHistoryForAllDeptResponse []>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequestsStatements/statementReplyHistoryForAllDept`,
      params: {requestId: input.requestId, type: input.type},
      
    },
    { apiName: this.apiName });

  getMenuDetailsWithOptionsByStatementType = (statementType: StatementType) =>
    this.restService.request<any, MenuDetailsDto[]>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequestsStatements/menuDetailsWithOptions`,
      params: { statementType: statementType },
    },
    { apiName: this.apiName });

  getStatementReplyHistoryPerDeptResponseByInput = (input: StatementReplyHistoryPerDeptRequestDto) =>
    this.restService.request<any, PagedResultDto<StatementReplyHistoryPerDeptResponseDto>>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequestsStatements/statementReplyHistoryPerDeptResponse`,
      params: { requestId: input.requestId, type: input.type, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getStatementReplyHistoryStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisorByInput = (input: StatementReplyHistoryPerDeptRequestDto) =>
    this.restService.request<any, PagedResultDto<StatementReplyHistoryPerDeptResponseDto>>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequestsStatements/statementReplyHistoryStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisor`,
      params: { requestId: input.requestId, type: input.type, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getStatementsByRequestId = (input: GetStatementsByRequestIdDto) =>
    this.restService.request<any, StatementByRequestIdResponse[]>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequestsStatements/statementsByRequestId`,
      params: { requestId: input.requestId, statementReply: input.statementReply, types: input.types },
    },
    { apiName: this.apiName });

  getStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisorByRequestId = (requestId: number) =>
    this.restService.request<any, StatementByRequestIdResponse[]>({
      method: 'GET',
      url: `/api/app/realEstateOwnershipRequestsStatements/studyMemberStatementsFromPreliminaryStudiesCommitteeSupervisor/${requestId}`,
    },
    { apiName: this.apiName });

  remindUnansweredRequestStatement = () =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequestsStatements/remindUnansweredRequestStatement`,
    },
    { apiName: this.apiName });

  roolBackCanceledRequests = () =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequestsStatements/roolBackCanceledRequests`,
    },
    { apiName: this.apiName });

  updateAuditCommitteeSecretaryStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/auditCommitteeSecretaryStatements`,
    },
    { apiName: this.apiName });

  updateCommitteeSecretaryStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/committeeSecretaryStatements`,
    },
    { apiName: this.apiName });

  updateInspectionMemberStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/inspectionMemberStatements`,
    },
    { apiName: this.apiName });

  updatePreliminaryStudiesCommitteeSupervisorStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/preliminaryStudiesCommitteeSupervisorStatements`,
    },
    { apiName: this.apiName });

  updateRequestSpecializationMemberStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/requestSpecializationMemberStatements`,
    },
    { apiName: this.apiName });

  updateRequestStudyMemberStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/requestStudyMemberStatements`,
    },
    { apiName: this.apiName });

  updateShariaMemberStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/shariaMemberStatements`,
    },
    { apiName: this.apiName });

  updateTechnicalMemberStatements = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/realEstateOwnershipRequestsStatements/technicalMemberStatements`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
