import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RealEstateRequestAdsObjectionApi } from '../api';
import { ObjectionRequest, ObjectionInList,ObjectionInForm,ObjectionAttachmentRes} from '../model';
import { Repository } from './repository';
import { ToasterService } from '@abp/ng.theme.shared';
import type { PagedResultDto , PagedAndSortedResultRequestDto} from '@abp/ng.core';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { downloadBlobFile } from '@spga-shared/utils';
@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestAdsObjectionRepository implements Repository<any, any, any> {

  constructor(
    private realEstateRequestAdsObjectionApi: RealEstateRequestAdsObjectionApi,
    private toaster: ToasterService
  ) { 
  }

  create(input: ObjectionRequest): Observable<boolean> {
    return this.realEstateRequestAdsObjectionApi.createObjection(input)
    .pipe(tap((res) =>
    {
      if(res)
      this.toaster.success('تم اضافة اعتراضك بنجاح ');
      else 
      this.toaster.error('حدث خطاء');
    } ));
  }

  get(id: number): Observable<ObjectionInForm> {
    return this.realEstateRequestAdsObjectionApi.getObjectionDetails(id);
  }

  getList(input: PagedAndSortedResultRequestDto): Observable<PagedResultDto<ObjectionInList>> {
    return this.realEstateRequestAdsObjectionApi.getMyObjectionList(input).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  uploadAttachment(body: FormData): Observable<ObjectionAttachmentRes> {
    return this.realEstateRequestAdsObjectionApi.uploadObjectionAttachment(body);
  }

  downloadAttachment(id: string, name: string): Observable<FileContentResult> {
    return this.realEstateRequestAdsObjectionApi.downloadObjectionAttachment(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }
}
