<div class="container">
    <div class="header">
        <div class="no-gutters h-100">
            <nav class="navbar navbar-expand-lg navbar-light  main-nav-bar">
                <a href="#">
                    <div class="main-nav-bar__logo">
                        <abp-logo></abp-logo>
                    </div>
                </a>

                <div *ngIf="isLoggedIn" class="navbar-toggler isLoggedIn" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                    (click)="isMenuCollapsed = !isMenuCollapsed">
                    <span class="navbar-toggler-icon isLoggedIn">
                        <img src="assets/images/user-profile.svg" alt="">
                        <span>{{currentUser.userName}}</span>
                    </span>
                </div>
                <button *ngIf="!isLoggedIn" class="navbar-toggler ml-auto p-0" type="button" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                    (click)="isMenuCollapsed = !isMenuCollapsed">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isMenuCollapsed">
                    <div class="d-block d-lg-none" *ngIf="isLoggedIn">
                        <div class="main-nav-bar mt-4">
                            <ul class="navbar-nav main-nav-bar__sections p-0">
                                <li class="nav-item">
                                    <a class="nav-btn" [routerLink]="['/', 'my-requests']" (click)="isMenuCollapsed = true">طلباتي</a>
                                </li>
                                <app-add-request-btn class="nav-btn" [isForMobile]="true" *ngIf="isCreateRequestEnabled && !isRequestLayout">
                                </app-add-request-btn>
                            </ul>
                        </div>
                    </div>
                    <div class="d-block d-lg-none font-weight-bolder" *ngIf="!isLoggedIn">
                        <div class="main-nav-bar mt-4">
                            <ul class="navbar-nav main-nav-bar__sections">
                                <li class="nav-item">
                                    <app-login-btn *ngIf="!isLoggedIn" (click)="onLoginClick($event)" [label]="LoginLabel">
                                    </app-login-btn>
                                </li>
                                <app-add-request-btn [isForMobile]="true"
                                    *ngIf="isCreateRequestEnabled && !isRequestLayout">
                                </app-add-request-btn>
                            </ul>
                        </div>
                    </div>
                    <app-nav-bar *ngIf="!isRequestLayout" (navItemClick)="isMenuCollapsed = true"></app-nav-bar>
                    <hr class="d-lg-none d-block"  *ngIf="isLoggedIn">
                    <form class="main-nav-bar__heading my-2 my-lg-0 ml-lg-auto ml-4">
                        <app-login-btn class="d-none d-lg-block" *ngIf="!isLoggedIn" (click)="onLoginClick($event)" [label]="LoginLabel">
                        </app-login-btn>
                        <!-- <app-user-notification *ngIf="isLoggedIn"></app-user-notification> -->
                        <app-user-profile *ngIf="isLoggedIn" [isCreateRequestEnabled]="isCreateRequestEnabled" [isRequestLayout]="isRequestLayout" (navItemClick)="isMenuCollapsed = true"></app-user-profile>
                        <app-add-request-btn class="ml-0 ml-lg-4 d-none d-lg-block" *ngIf="isCreateRequestEnabled && !isRequestLayout">
                        </app-add-request-btn>
                    </form>
                </div>
            </nav>
        </div>
    </div>
    <div class="container mt-4">
        <div class="col-12">
            <span class="back-main" (click)="navigateTo()" *ngIf="isRequestLayout">
                <img height="11" width="15" src="assets/images/nav-right.svg" alt=""> العودة
                للرئيسية</span>
        </div>
    </div>

</div>
