import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangeEngOfficeRequestDto, ChangeOfficeAttachmentResponseDto } from './models';


@Injectable({
  providedIn: 'root',
})
export class ChangeEngOfficeService {
  apiName = 'Default';

  constructor(private restService: RestService) { }

  changeEngOfficeDecision = (input: ChangeEngOfficeRequestDto) =>
  this.restService.request<any, void>({
    method: 'POST',
    url: `/api/app/changeEngOffice/changeEngOfficeDecision`,
    body: input,
  },
    { apiName: this.apiName });

  createChangeOfficeAttachment(body: FormData): Observable<ChangeOfficeAttachmentResponseDto> {
    return this.restService.request<any, ChangeOfficeAttachmentResponseDto>({
      method: 'POST',
      url: `/api/app/changeEngOffice/changeOfficeAttachment`,
      body
    },
      { apiName: this.apiName });
  }
}
