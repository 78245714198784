<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        {{requestsList?.items?.length===1?'تفاصيل الطلب':'تفاصيل الطلبات'}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="search-box" *ngIf="isListLoading">
        <p>جاري التحميل</p>
        <i class="fa fa-spinner fa-spin"></i>
    </div>

    <div class="search-box" *ngIf="!isListLoading && requestsList?.items?.length<1">
        <p> لا يوجد بيانات</p>
    </div>

    <ng-container *ngIf="!isListLoading && requestsList?.items?.length>0">
        
        <div class="d-flex px-3 mb-3" *ngIf="requestsList?.items?.length>1">
            <div class="col card-text">رقم الطلب</div>
            <div class="col card-text">نوع الطلب</div>
            <div class="col card-text">تاريخ تقديم الطلب</div>
            <div class="col-1"></div>
        </div>
        <ng-container *ngIf="requestsList?.items && requestsList?.items?.length===1">
            <ng-container *ngTemplateOutlet="templateRef; context: { item:requestsList?.items[0] }"></ng-container>
        </ng-container>

        <ng-container *ngIf="requestsList?.items?.length>1">

            <ngb-accordion [closeOthers]="true" activeIds="static-1">
                <ng-container *ngFor="let request of requestsList?.items; let index=index">
                    <ngb-panel>
                        <ng-template ngbPanelHeader let-opened="opened">
                            <button class="accordion-button" ngbPanelToggle [class.collapsed]="!opened">
                                <div class="col card-text text-cloudy">{{request.requestId}}</div>
                                <div class="col card-text text-cloudy">{{request.requestType}}</div>
                                <div class="col card-text text-cloudy">{{request.creationDate | customDate}}</div>
                                <div class="col-1"> <button class="col-1 button-nostyle toggle-icon"></button></div>
                            </button>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <ng-container
                                *ngTemplateOutlet="templateRef; context: { item:request, index:index }"></ng-container>
                        </ng-template>
                    </ngb-panel>
                </ng-container>
            </ngb-accordion>

            <div *ngIf="requestsList?.totalCount > params.maxResultCount" class="pagination-wrapper">
                <ngb-pagination (pageChange)="pageChange($event)" [(page)]="page"
                    [collectionSize]="requestsList?.totalCount" [maxSize]="3" [pageSize]="params.maxResultCount"
                    [rotate]="true" aria-label="Default pagination">
                </ngb-pagination>
            </div>

        </ng-container>

    </ng-container>

    <ng-template #templateRef let-item="item" let-index="index">
        <div class="row px-4">
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">رقم الطلب</h2>
                    <p class="field__data">
                        {{item?.requestId? item.requestId : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">نوع الطلب</h2>
                    <p class="field__data">
                        {{item?.requestType? item.requestType : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">نوع العقار</h2>
                    <p class="field__data">
                        {{item?.realstateType? item.realstateType : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">اسم المالك </h2>
                    <p class="field__data">
                        {{item?.ownerRepresentativeName? item.ownerRepresentativeName : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">رقم هوية مقدم الطلب</h2>
                    <p class="field__data">
                        {{item?.ownerRepresentativeId? item.ownerRepresentativeId : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">المنطقة</h2>
                    <p class="field__data">
                        {{item?.region? item.region : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">المدينة</h2>
                    <p class="field__data">
                        {{item?.city? item.city : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">المساحة</h2>
                    <p class="field__data">
                        {{item?.area? item.area + ' متر مربع ' : '-'}} 
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="field">
                    <h2 class="field__label">تاريخ التقديم</h2>
                    <p class="field__data">
                        {{item?.creationDate? (item.creationDate | customDate) : '-'}} 
                    </p>
                </div>
            </div>
        </div>
    </ng-template>


    <div class="modal-footer mt-2 pb-0">
        <button (click)="activeModal.dismiss('Cross click')" type="button" class="btn btn-outline-dark">عودة</button>
    </div>


</div>