import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CityApi } from '../api';
import { City } from '../model';
import { Repository } from './repository';

@Injectable({
  providedIn: 'root'
})
export class CityRepository implements Repository<City, any, City> {
  public cites$: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([]);

  constructor(private cityApi: CityApi) {
  }

  getAll(): Observable<City[]> {
    return this.cityApi.getCites().pipe(
      tap((res) => {
        this.cites$.next(res);
      })
    );
  }

  get(cityId: number): Observable<City> {
    return this.cityApi.getCityById(+cityId);
  }

  getCitiesByRegionIdByRegionId(regionId: number): Observable<City[]> {
    return this.cityApi.getCitiesByRegionIdByRegionId(regionId).pipe(
      tap((res) => {
        this.cites$.next(res);
      })
    );
  }
  getCitiesByRegionIdByRegionIdForEngOffice(regionId: number): Observable<City[]> {
    return this.cityApi.getCitiesByRegionIdByRegionIdForEngOffice(regionId).pipe(
      tap((res) => {
        this.cites$.next(res);
      })
    );
  }
}
