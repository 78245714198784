import { mapEnumToOptions } from '@abp/ng.core';

export enum FormalModification {
  AddArea = 1,
  ModifyingInstrumentLimits = 2,
  AddingCivilRegistryToOwnerOfHafida = 3,
  AddCivilRegistry = 4,
  ModifyCivilRegistryNo = 5,
  ModifyNameOrSurName = 6,
  ModifyFinancialWrittenError = 7,
  AddOrModifyStreetWidthPlotAndSectionNo = 8,
  ConvertNonMetricMeasurementsToMetric = 9,
  Other = 200,
}

export const formalModificationOptions = mapEnumToOptions(FormalModification);
