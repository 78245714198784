import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealEstateRequestOwnerApi } from '../api';
import { Request, RequestOwnership, RequestRealEstateEndors, RequestRes } from '../model';
import { Repository } from './repository';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestOwnerRepository implements Repository<any, any, any> {

  constructor(private realEstateRequestOwnerApi: RealEstateRequestOwnerApi) {
  }

  get(id: number): Observable<Request> {
    return this.realEstateRequestOwnerApi.getRealEstateOwnershipById(id);
  }

  create(input: RequestOwnership): Observable<RequestRes> {
    return this.realEstateRequestOwnerApi.createRealEstateOwnership(input);
  }

  endorsement(input: RequestRealEstateEndors): Observable<RequestRes> {
    return this.realEstateRequestOwnerApi.createRealEstateRequestEndorsement(input);
  }
  isCreateNewRequestEnabled(): Observable<boolean> {
    return this.realEstateRequestOwnerApi.isCreateNewRequestEnabled();
  }
}
