import {
  CreateUpdateEngineeringOfficeDto,
  EngineeringOfficeDto,
  EngineeringOfficeResponse,
  GetAllEngineeringOfficesInputDto,
  UpdateEngineeringOfficeDto
} from '@proxy/engineering-offices/models';

export interface EngineeringOffice extends EngineeringOfficeDto {}
export interface CreateUpdateEngineeringOffice extends CreateUpdateEngineeringOfficeDto {}
export interface EngineeringOfficeRes extends EngineeringOfficeResponse {}
export interface GetAllEngineeringOfficesInput extends GetAllEngineeringOfficesInputDto {}
export interface UpdateEngineeringOffice extends UpdateEngineeringOfficeDto {}
