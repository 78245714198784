
import { CreateObjectionRequestDto,GetObjectionDetailsReponseDto,GetObjectionResponseDto,ObjectionAttachmentDto, CreateObjectionAttachmentResponseDto,GetObjectionAdminListRequestDto, GetObjectionAdminListResponseDto,ObjectionDetailsPerRequestDto } from '@proxy/objections'
export interface ObjectionRequest extends CreateObjectionRequestDto {

}
export interface ObjectionInForm extends GetObjectionDetailsReponseDto {

}
export interface ObjectionInList extends GetObjectionResponseDto {

}
export interface ObjectionAttachment extends ObjectionAttachmentDto {

}

export interface ObjectionAttachmentRes extends CreateObjectionAttachmentResponseDto {
}

export interface GetObjectionAdminListRequest extends  GetObjectionAdminListRequestDto{
    filter:string;
}
export interface GetObjectionAdminListRes extends GetObjectionAdminListResponseDto{
   
}
export interface ObjectionDetailsPerRequest extends ObjectionDetailsPerRequestDto{
   
}