import { Component, Input, OnInit } from '@angular/core';
import { AttachmentInput, StatementReplyHistoryPerDeptResponse } from '@data/model';
import { RealEstateRequestAttachmentRepository } from '@data/repository';
import { RealEstateRequestStatementsRepository } from '@data/repository/real-estate-request-statements.repository';
import { EngineeringOfficeDto } from '@proxy/engineering-offices';
import { RequestStatus } from '@proxy/enums';
import { InspectionMemberAttachmentDto } from '@proxy/real-estate-attachments';
import { Observable } from 'rxjs';
import { IAttachment } from '..';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() office?: EngineeringOfficeDto;  
  @Input() statment?: StatementReplyHistoryPerDeptResponse
  downloadAttachmentFn?: (attachment: IAttachment) => Observable<any>;
  date?: string;
  requestStatus? = RequestStatus;
  showAttachement:boolean =false
  constructor(private attachmentRepository: RealEstateRequestAttachmentRepository , private statementsRepository: RealEstateRequestStatementsRepository) {
  }

  ngOnInit(): void {
    this.downloadAttachmentFn = (attachment: IAttachment) => this.statementsRepository
    .downloadStatementAttachmentById(attachment.id!, attachment.name!);
  }

  handleAttachment(attachmentInput: AttachmentInput, attachment: IAttachment): void {
    attachmentInput.attachment = attachment as InspectionMemberAttachmentDto;
  }

}
