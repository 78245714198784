import { mapEnumToOptions } from '@abp/ng.core';

export enum CommitteeVote {
  FullTitling = 1,
  NotFullyTitling = 2,
  OwningPartOfThePropertyWithAnArea = 3,
  DismissTheRequest = 4,
  Other = 5,
}

export const committeeVoteOptions = mapEnumToOptions(CommitteeVote);
