<abp-modal (disappear)="onSaveUploadedFile(true)" [(visible)]="isModalOpen" [options]="{size:'lg',scrollable:false,modalDialogClass:'admin-modal'}">
    <ng-template #abpBody>
        <div class="app-header"></div>
        <div class="file-preview">
            <img *ngIf="isImage();else notImage" [src]="previewImageSource" alt="">
            <ng-template #notImage>
                <h2>{{docType}}</h2>
            </ng-template>
        </div>
    </ng-template>

    <ng-template #abpFooter>
        <form [formGroup]="form">
            <div>
                <div class="row info__data">
                    <div *ngIf="hasDescription" class="col-12 col-md-8">
                        <span class="info__data">
              <label class="form-inputs__title" for="exampleInputEmail1">
                وصف المرفق
              </label>
            </span>
            <div class="form-group form-inputs">
                <input formControlName="description" id="description" class="form-control  form-inputs__input"
                 placeholder="كتابة نص لوصف المرفق" type="text" value="">
            </div>
                    </div>
                    <div *ngIf="hasDDL" class="col-12 col-md-8">
                            <span class="info__data required">
                                    <label class="form-inputs__title" for="exampleInputEmail1">
                                      وصف المرفق
                                    </label>
                                  </span>
                        <div class="form-group form-inputs">
                            <ng-select (change)="onDescriptionChange($event?.value)" [items]="attachmentsDescriptions" bindLabel="key" bindValue="value" formControlName="descriptionDDL" id="descriptionDDL" placeholder="اختيار الوصف">
                                <ng-container>
                                    <ng-template let-index="index" let-item="item" ng-option-tmp>
                                        {{(item['value'])}}
                                    </ng-template>
                                    <ng-template let-item="item" ng-label-tmp>
                                        {{(item['value'])}}
                                    </ng-template>
                                </ng-container>
                            </ng-select>
                        </div>
                        <div *ngIf="showDescriptionText" class="form-group form-inputs">

                            <input [maxLength]=250 aria-describedby="emailHelp" class="form-control  form-inputs__input" formControlName="otherDescriptions" id="otherDiscriptions0" placeholder="أسباب أخرى" type="text" value="">
                        </div>
                    </div>

                    <div *ngIf="hasDate" class="col-12 col-lg-4 col-md-6">
                        <span class="info__data">
              <i class="icon icon-calendar"></i>
              <label class="form-inputs__title">
                تاريخه
                <span class="info__mojNum">(اختياري)</span>
                        </label>
                        </span>
                        <div class="form-group  form-inputs custom-calendar">
                            <app-datepicker-range hasMaxDate="true" (fromDateChange)="attachmentDate = $event" [isDateRange]="false" id="caseDate">
                            </app-datepicker-range>
                        </div>
                    </div>
                </div>
                <ng-container>
                    <div class="form-row pt-3 align-items-center">
                        <div class="col-auto my-1">
                            <button #abpClose (click)="onSaveUploadedFile(true)" class="btn btn-outline-secondary navigate-btn" type="button">
                                إلغاء
                            </button>
                        </div>
                        <div class="col-auto my-1">
                            <button [disabled]="form.invalid" (click)="onSaveUploadedFile()" class="btn btn-primary mr-2 navigate-btn">
                                <i class="icon icon-Save-Ico mr-1"></i>
                                حفظ واغلاق
                            </button>
                        </div>
                    </div>
                    <div>كتابتك لوصف المرفق بدقة سيسهل عملية معالجة الطلب</div>
                </ng-container>
            </div>
        </form>
    </ng-template>
</abp-modal>