
import { AdsRequestDto, AdsResponseDto,PublishAdsRequestDto } from '@proxy/ads'
export interface PublishAdsRequest extends PublishAdsRequestDto {

}

export interface RealEstateRequestAds extends AdsResponseDto {

}

export interface RealEstateRequestAdsFilters extends AdsRequestDto {
  //filter: string;
}
