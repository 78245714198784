import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateUpdateEngineeringOffice,
  EngineeringOffice,
  EngineeringOfficeRes,
  GetAllEngineeringOfficesInput,
  UpdateEngineeringOffice
} from '../model';
import { EngineeringOfficeService } from '@proxy/engineering-offices/engineering-office.service';
import { ContactInfoService } from '@proxy/contact-info/contact-info.service';
import type { PagedResultDto } from '@abp/ng.core';

@Injectable({
  providedIn: 'root'
})
export class EngineeringOfficeApi {
  constructor(private engineeringOfficeService: EngineeringOfficeService,private contactInfoService: ContactInfoService) {
  }

  getEngineeringOffices(input: GetAllEngineeringOfficesInput): Observable<PagedResultDto<EngineeringOffice>> {
    return this.engineeringOfficeService.getList(input);
  }

  getEngineeringOfficesForAnonymous(input: GetAllEngineeringOfficesInput): Observable<PagedResultDto<EngineeringOffice>> {
    return this.engineeringOfficeService.getListAnonymous(input);
  }
  getEngineeringOfficeById(engineeringOfficeId: number): Observable<EngineeringOffice> {
    return this.engineeringOfficeService.get(engineeringOfficeId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createEngineeringOffice(input: CreateUpdateEngineeringOffice): Observable<EngineeringOfficeRes> {
    return this.engineeringOfficeService.create(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateEngineeringOffice(id: number, input: CreateUpdateEngineeringOffice): Observable<EngineeringOfficeRes> {
    return this.engineeringOfficeService.update(id, input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getEngineeringOfficeContactInfo(): Observable<UpdateEngineeringOffice> {
    return this.contactInfoService.getEngneeringOffice();
  }

  updateEngineeringOfficeContactInfo(input: UpdateEngineeringOffice): Observable<EngineeringOfficeRes> {
    return this.contactInfoService.updateEngineeringOffice(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
