import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealEstateRequestLocationApi } from '../api';
import { Request, RequestRealestateLocation, RequestRes } from '../model';
import { Repository } from './repository';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestLocationRepository implements Repository<any, any, any> {

  constructor(private realEstateRequestLocationApi: RealEstateRequestLocationApi) {
  }

  get(id: number): Observable<Request> {
    return this.realEstateRequestLocationApi.getRealEstateLocationById(id);
  }

  create(input:RequestRealestateLocation): Observable<RequestRes> {
    return this.realEstateRequestLocationApi.createRealEstateLocation(input);
  }
}
