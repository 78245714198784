import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { ObjectionInForm, ObjectionInList, ObjectionRequest, ObjectionAttachmentRes} from '../model';
import { ObjectionService } from '@proxy/objections/objection.service';
import type { PagedResultDto} from '@abp/ng.core';
import { RestService } from '@abp/ng.core';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestAdsObjectionApi {
  constructor(private objectionService: ObjectionService , private restService: RestService) {
  }

  createObjection(input: ObjectionRequest): Observable<boolean> {
    return this.objectionService.createObjectionByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getMyObjectionList(input:PagedAndSortedResultRequestDto):Observable<PagedResultDto<ObjectionInList>>{
    return this.objectionService.getObjectionListByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getObjectionDetails(input:number):Observable<ObjectionInForm>{
    return this.objectionService.getObjectionDetailsByObjectionId(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  uploadObjectionAttachment(body: FormData): Observable<ObjectionAttachmentRes> {
    return this.restService.request<any, ObjectionAttachmentRes>({
        method: 'POST',
        url: `/api/app/objection/objectionAttachment`,
        body
      },
      { apiName: this.objectionService.apiName });
  }

  downloadObjectionAttachment(id: string): Observable<FileContentResult> {
    return this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/objection/${id}/downloadObjectionAttachment`,
        responseType: 'blob'
      },
      { apiName: this.objectionService.apiName });
  }

}
