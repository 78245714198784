<div [class.cursor-pointer]="attachmentInput" [class.disabled]="isUploading || (!uploadFn && !attachmentInput)" [class.is-invalid]="!isValidExtension()" class="custom-file mb-3  avatar">
    <ng-container *ngIf="!attachment; else hasAttachment">
        <img alt="add" class="add-img" src="assets/images/add-upload.svg">
        <div class="item">
            <div class="upload-label font-weight-bold">{{title}}</div>
            <div class="upload-label  desc text-cloudy font-weight-bold pt-4">{{desc}}</div>
            <!--    <div class="upload-label  date"> 20/12/2020</div>-->
        </div>
        <ng-container *ngIf="isShpfile">
            <div id="uploadForm">
                <div class="field">
                    <label class="" for="inFile">
                        <span class="d-block h-100 position-absolute w-100 pointer" ></span>
                        <input  class="d-none" id="inFile" #fileUpload [accept]="allowedExtensions" [disabled]="isUploading || !uploadFn" type="file">            
                    </label>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!isShpfile">
            <input #fileUpload (change)="handleFileInput($event)" [accept]="allowedExtensions" [disabled]="isUploading || !uploadFn" type="file">            
        </ng-container>
        
    </ng-container>

    <ng-template #hasAttachment>
        <i (click)="preview()" *ngIf="attachment?.url" appClickStopPropagation class="fa fa-eye preview-file file-action"></i>
        <i (click)="downloadAttachment()" *ngIf="downloadFn&&attachment?.id" appClickStopPropagation class="fa fa-download download-file file-action"></i>
        <i (click)="removeAttachment()" *ngIf="showRemove || (removeFn&&attachment?.id) || (!removeFn&&!attachmentInput)" appClickStopPropagation class="remove-file file-action"></i>
        <img *ngIf="isImage(attachment);else notImage" [alt]="attachment?.description" [src]="attachment?.url" height="160" width="100%">
        <ng-template #notImage>
            <h2 class="text-center my-5">{{attachment?.extension}}</h2>
        </ng-template>
        <!-- <div *ngIf="attachment?.aiResult" [ngbTooltip]="('::Enum:AIResult:' + attachment?.aiResult)|abpLocalization" class="ai-info" placement="top">
            <i class="fa fa-info-circle mr-2"></i>{{('::Enum:AIResult:' + attachment?.aiResult)|abpLocalization}}
        </div> -->
        <div class="upload-label font-weight-bold">{{title}}</div>
        <div class="upload-label desc font-weight-bold pt-4">{{attachment?.description}}</div>
        <div class="upload-label date pt-4">{{attachment?.attachmentDate|customDate}}</div>
    </ng-template>
</div>
<div class="invalid-feedback">نوع الملف غير مسموح</div>
<app-uploader-popup (attachmentInfo)="handleAttachmentInfo($event)" *ngIf="isModalOpen" [file]="file!" [hasDate]="hasDate" [hasDescription]="hasDescription" [hasDDL]="hasDDL"></app-uploader-popup>
