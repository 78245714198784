import { mapEnumToOptions } from '@abp/ng.core';

export enum OwnerRepresentativeType {
  Ancestral = 1,
  Representative = 2,
  Deputy = 3,
  Authorized = 4,
  Caretaker = 5,
  Inheritor = 6,
  Partner = 7,
  CorporateJudicialLiquidator = 8,
  JudicialLiquidatorLegacies = 9,
  JudicialGuard = 10,
}

export const ownerRepresentativeTypeOptions = mapEnumToOptions(OwnerRepresentativeType);
