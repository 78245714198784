import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealEstateRequestAttachmentApi } from '../api';
import { Request, RequestRealEstateAttachment, RequestRealEstateReportAttachment, RequestReportAttachmentRes, RequestRes ,SpecializationMemberAttachmentRes} from '../model';
import { Repository } from './repository';
import { InspectionMemberAttachmentResponse, RequestReportAttachmentResponse, ShariaMemberAttachmentResponse, TechnicalMemberAttachmentResponse } from '@proxy/real-estate-ownership-requests';
import { tap } from 'rxjs/operators';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { downloadBlobFile } from '@spga-shared/utils';
import { IFormFile } from '@proxy/microsoft/asp-net-core/http';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestAttachmentRepository implements Repository<any, any, any> {
  constructor(private realEstateRequestAttachmentApi: RealEstateRequestAttachmentApi) {
  }

  get(id: number): Observable<Request> {
    return this.realEstateRequestAttachmentApi.getRealEstateAttachmentsById(id);
  }

  create(input: RequestRealEstateAttachment): Observable<RequestRes> {
    return this.realEstateRequestAttachmentApi.uploadRequestRealEstateAttachment(input);
  }

  createAttachment(requestId: number, formData: FormData): Observable<RequestRes> {
    return this.realEstateRequestAttachmentApi.uploadRequestRealEstateAttachmentFormFile(requestId, formData);
  }

  delete(id: string): Observable<void> {
    return this.realEstateRequestAttachmentApi.deleteRequestRealEstateAttachment(id);
  }

  uploadReportAttachment(
    input: RequestRealEstateReportAttachment
  ): Observable<RequestReportAttachmentRes> {
    return this.realEstateRequestAttachmentApi.uploadRequestReportAttachment(input);
  }

  uploadRequestReportAttachmentFormFile(body: FormData): Observable<RequestReportAttachmentResponse> {
    return this.realEstateRequestAttachmentApi.uploadRequestReportAttachmentFormFile(body);
  }

  downloadRealEstateAttachmentById(id: string, name: string): Observable<FileContentResult> {
    return this.realEstateRequestAttachmentApi.downloadRealEstateAttachmentById(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }

  createInspectionMemberAttachment(body: FormData): Observable<InspectionMemberAttachmentResponse> {
    return this.realEstateRequestAttachmentApi.createInspectionMemberAttachmentByFile(body);
  }

  createShariaMemberAttachmentByFile(body: FormData): Observable<ShariaMemberAttachmentResponse> {
    return this.realEstateRequestAttachmentApi.createShariaMemberAttachmentByFile(body);
  }

  createTechnicalMemberAttachmentByFile(body: FormData): Observable<TechnicalMemberAttachmentResponse> {
    return this.realEstateRequestAttachmentApi.createTechnicalMemberAttachmentByFile(body);
  }

  createSpecializationMemberAttachmentByFile(body: FormData): Observable<SpecializationMemberAttachmentRes> {
    return this.realEstateRequestAttachmentApi.createSpecializationMemberAttachmentByFile(body);
  }

  downloadInspectionAttachmentById(id: string, name: string): Observable<FileContentResult> {
    return this.realEstateRequestAttachmentApi.downloadInspectionAttachmentById(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }
  downloadShariaAttachmentById(id: string, name: string): Observable<FileContentResult> {
    return this.realEstateRequestAttachmentApi.downloadShariaAttachmentById(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }
  downloadTechnicalAttachmentById(id: string, name: string): Observable<FileContentResult> {
    return this.realEstateRequestAttachmentApi.downloadTechnicalAttachmentById(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }


  downloadArealReportAttachmentByRequestId(id: number,name:string): Observable<FileContentResult> {

    return this.realEstateRequestAttachmentApi.downloadArealReportAttachmentByRequestId(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }

  downloadRealestateLimitsAttachmentByRequestId(id: number,name:string): Observable<FileContentResult> {

    return this.realEstateRequestAttachmentApi.downloadRealestateLimitsAttachmentByRequestId(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }
  downloadSpecializationMemberAttachmentById(id: string,name:string): Observable<FileContentResult> {
    return this.realEstateRequestAttachmentApi.downloadSpecializationMemberAttachmentById(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }

  downloaStatementAttachmentById(id: string,name:string): Observable<FileContentResult>{
    return this.realEstateRequestAttachmentApi.downloadSpecializationMemberAttachmentById(id)
    .pipe(tap(x => downloadBlobFile(x, '', name)));
  }

}
