import { Injectable } from '@angular/core';
import { CreateStatementAttachmentResponseDto, RealEstateOwnershipRequestsStatementsService, RequestStatementReplyDto, RequestStatementReplyResponse, SavedBeneficiaryStatementDto, StatementByRequestIdResponse, StatementReplyHistoryForAllDeptRequestDto, StatementReplyHistoryForAllDeptResponse } from '@proxy/real-estate-ownership-requests-statements';
import { MenuDetailsDto} from '@proxy/statement-administration';
import { Observable } from 'rxjs';
import { RequestStatement, RequestStatementResponse, StatementReplyHistoryPerDeptRequest, StatementReplyHistoryPerDeptResponse, StatementRequest, StatementResponse } from '@data/model';
import type { PagedResultDto } from '@abp/ng.core';
import { IFormFile } from '@proxy/microsoft/asp-net-core/http';
import { RestService } from '@abp/ng.core';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { StatementType } from '@proxy/enums/statement-type.enum';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestStatementsApi {
  apiName = 'Default';
  constructor(private realEstateRequestStatementsService: RealEstateOwnershipRequestsStatementsService , private restService: RestService) {
  }

  createRequestStatement(input: RequestStatement): Observable<RequestStatementResponse> {
    return this.realEstateRequestStatementsService.createRequestStatementByInput(input);
  }
  createSavedBeneficiaryStatement(input: SavedBeneficiaryStatementDto): Observable<number> {
    return this.realEstateRequestStatementsService.createSavedBeneficiaryStatement(input);
  }
  getSavedBeneficiaryStatement(requestId: number): Observable<SavedBeneficiaryStatementDto> {
    return this.realEstateRequestStatementsService.getSavedBeneficiaryStatement(requestId);
  }
  createStatementAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, CreateStatementAttachmentResponseDto>({
      method: 'POST',
      url: `/api/app/realEstateOwnershipRequestsStatements/statementAttachment`,
      body: file
    },
    { apiName: this.apiName });


    downloadStatementAttachmentById = (id: string) =>
      this.restService.request<any, FileContentResult>({
        method: 'GET',
        url: `/api/app/realEstateOwnershipRequestsStatements/${id}/downloadStatementAttachment`,
        responseType: 'blob'
      },
      { apiName: this.apiName });

  createRequestStatementReplyByInput(input: RequestStatementReplyDto ):Observable<RequestStatementReplyResponse>{
    return this.realEstateRequestStatementsService.createRequestStatementReplyByInput(input)
  }

  getStatementsByRequestIdByInput(input: StatementRequest): Observable<StatementResponse[]> {
    return this.realEstateRequestStatementsService.getStatementsByRequestId(input);
  }

  getStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisorByInput(input: number) : Observable<StatementByRequestIdResponse[]>{
    return this.realEstateRequestStatementsService.getStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisorByRequestId(input)
  }

  getStatementReplyHistoryPerDeptResponseByInput(input: StatementReplyHistoryPerDeptRequest): Observable<PagedResultDto<StatementReplyHistoryPerDeptResponse>>{
    return this.realEstateRequestStatementsService.getStatementReplyHistoryPerDeptResponseByInput(input);
  }
  getMenuDetailsWithOptionsByStatementType(input: StatementType): Observable<MenuDetailsDto[]>{
    return this.realEstateRequestStatementsService.getMenuDetailsWithOptionsByStatementType(input);
  }
  
  getStatementReplyHistoryForAllDept(input: StatementReplyHistoryForAllDeptRequestDto): Observable<StatementReplyHistoryForAllDeptResponse[]> {
    return this.realEstateRequestStatementsService.getStatementReplyHistoryForAllDept(input);
  }
}
