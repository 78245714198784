import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppAuthService } from '@spga-shared/providers/auth';

@Injectable({
  providedIn: 'root'
})
export class TransferPublicRequestGuard {
  constructor(private authService: AppAuthService, private router: Router) {
  }

  canActivate(): boolean {
    //if (this.authService.currentLoggedInUser$.roles[0] !== 'TransferPublicRequest') {
    //  this.router.navigate(['/404']);
    //  return false;
    //}
    //else
      return true
  }

}
