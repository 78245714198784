import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgmMarker, MouseEvent } from '@agm/core';

declare const google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  lat = 0;
  lng = 0;
  zoom = 8;
  markers: { lat: number, lng: number, alpha: number }[] = [{ lat: 0, lng: 0, alpha: 1 }];
  @Input() location?: { lat: number, lng: number };
  @Input() isSetMarker = false;
  @Input() isEditable = true;
  @Input() isCoordinatesControl= false;
  @Output() outPutLocation: EventEmitter<{ lat: number, lng: number }> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    if (this.location) {
      this.zoom = 8;
      this.lat = this.location.lat;
      this.lng = this.location.lng;
      this.markers = [];
      this.markers.push({ lat: this.lat, lng: this.lng, alpha: 1 });
    } else if (this.isSetMarker) {
      this.setCurrentLocation();
    }
  }

  addMarker(lat: number, lng: number) {
    this.setLatLng(lat, lng);
  }

  setLatLng(lat: number, lang: number) {
    this.lat = lat as number;
    this.lng = lang as number;
    this.markers = [];
    this.markers.push({ lat: this.lat, lng: this.lng, alpha: 1 });
    const latlangObj = { lat: this.lat, lng: this.lng };
    this.outPutLocation.emit(latlangObj);
  }

  dragEnd(event: MouseEvent) {
    this.setLatLng(event.coords.lat, event.coords.lng);
  }

  selectMarker(event: AgmMarker) {
    if (this.isEditable) {
      this.setLatLng(event.latitude, event.longitude);
    }
  }

  private setCurrentLocation() {
    this.setLatLng(24.690002927059805, 46.754177222070325);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setLatLng(position.coords.latitude, position.coords.longitude);
      });
    }
  }

  max(cordType: 'lat' | 'lng'): number {
    return Math.max(...this.markers.map(marker => marker[cordType]));
  }

  min(cordType: 'lat' | 'lng'): number {
    return Math.min(...this.markers.map(marker => marker[cordType]));
  }
}
