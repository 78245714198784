import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EngineeringOfficeBill } from '../model';
import { BillsService } from '@proxy/bills';

@Injectable({
  providedIn: 'root'
})
export class EngineeringOfficeBillsApi {
  constructor(private billsService: BillsService) {
  }

  updateEngineeringOfficeBillValue(input: EngineeringOfficeBill): Observable<boolean> {
    return this.billsService.createEngineeringOfficeBillValuesByInput(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getEngineeringOfficeBillValue(): Observable<EngineeringOfficeBill> {
    return this.billsService.getEngineeringOfficeBillSettings().pipe(
      map((res) => {
        return res;
      })
    );
  }
}
