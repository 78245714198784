import { Injectable } from '@angular/core';
import { DelayRequest } from '../model';
import { DelayRequestService } from '@proxy/delay-requests';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DelayRequestApi {
  constructor(private delayRequestService: DelayRequestService) {
  }

  Create(input: DelayRequest): Observable<void> {
    return this.delayRequestService.create(input.requestId).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
