import type { CourtDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CourtService {
  apiName = 'Default';

  getList = () =>
    this.restService.request<any, PagedResultDto<CourtDto>>({
      method: 'GET',
      url: `/api/app/court`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
