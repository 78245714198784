import { Injectable } from '@angular/core';
import { BlobStorageService } from '@proxy/blob-storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlobStorageApi {
  constructor(private blobStorageService: BlobStorageService) {
  }

  getObjectUrlByFileName(fileName : string): Observable<string> {
    return this.blobStorageService.getObjectUrlByFileName(fileName).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
