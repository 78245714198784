import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class AreaReportService {
    public isValidFile$ = new BehaviorSubject<boolean>(false);

    constructor(
    ) { }


  public setIsValidFile(isValidFile: boolean) {
    this.isValidFile$.next(isValidFile);
  }

  public getIsValidFile() {
    return this.isValidFile$.value
  }


}
