<ng-container *ngIf="repository && options; else noRepository">
    <div [class.mb-0]="options.filters" class="app-header">
        <div class="titles col-6">
            <h1 *ngIf="options.title" class="app-title">{{options.title}}</h1>
            <h3 *ngIf="options.subTitle" class="app-sub-title">{{options.subTitle}}</h3>
        </div>
        <button (click)="openCreateModal()" *ngIf="options.actions?.canCreate" class="btn btn-primary">
            <i [class]="'icon mr-1 '+options.buttons?.iconClass||'icon-plus'"></i>
            {{options.buttons?.add}}
        </button>
        <button  *ngIf="options.baseButton" class="btn btn-primary" [routerLink]="[options.baseButton?.route]">
            <i [class]="'icon mr-1 '+options.baseButton?.iconClass||'icon-plus'"></i>
            {{options.baseButton?.title}}
        </button>
        <ng-container *ngIf="options.search">
            <form (submit)="search()" *abpPermission="options.search.requiredPermission" [formGroup]="searchForm!" class="col-md-4 p-0">
                <div class="input-group">
                    <input [placeholder]="options.search.placeholder" class="form-control" formControlName="searchText" type="text" appInputRestriction ="integerOnly" [maxlength]="options.search?.maxLength || 20">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary btn-auto-width" type="submit">
                            <i class="icon icon-search fa fa-rotate-90"></i>
                        </button>
                    </div>
                </div>

            </form>
        </ng-container>
        <ng-container *abpPermission="options.actions?.hasExportPermission">
            <div *ngIf="options.actions?.exportToExcel" class="filter ml-auto">
                <button (click)="downloadExcel()" class="btn btn-primary">تحميل Excel</button>
            </div>
        </ng-container>
    </div>
    <div *ngIf="hasEditableFilters || options.actions?.exportToExcel" class="filters row">
        <ng-container *ngIf="hasEditableFilters">
            <ng-container *abpPermission="options.filtersRequiredPermission">
                <ng-container *ngFor="let filter of options?.filters;index as i">
                    <ng-container *abpPermission="filter.requiredPermission">
                        <ng-container *ngIf="!filter.condition">
                        <div *ngIf="filter.isEditable === undefined || filter.isEditable" class="filter ml-2 mb-3" ngbDropdown>
                            <button [id]="'filter_'+i" class="btn btn-outline-tertiary text-semi-dark custom-arrow" ngbDropdownToggle><i
                                    class="icon icon-filters mr-2"></i>{{filter.title || 'No title entered'}}
                            </button>
                            <div [attr.aria-labelledby]="'filter_'+i" [ngClass]="{'calendar-dropdown custom-calendar':filter.type === 'date'}" ngbDropdownMenu>
                                <app-datepicker-range [isDateSingleFilter]="filter.isDateSingle" (fromDateChange)="changeFilter(filter,$event)" (toDateChange)="changeFilter(filter,$event,{isToDate:true})" *ngIf="filter.type === 'date';else drop">
                                </app-datepicker-range>
                                <ng-template #drop>
                                    <div class="dropdown-content">
                                        <button (click)="changeFilter(filter,filterValue)" *ngFor="let filterValue of filters[filter.field]?.data" [class.active]="isActiveFilter(filter,filterValue)" ngbDropdownItem>
                                            {{filter.enumName ?
                                             (('::Enum:' + filter.enumName + ':' + filterValue[filter.valueField || 'value']) | abpLocalization) :
                                             filterValue[filter.labelField || 'key']}}
                                        </button>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>

    <div *ngIf="options.search?.mustSearch && (!searchForm?.controls.searchText.value || (!onGoingRequest && !isLoaded));else loadingList" class="search-box">
        <img alt="input search text" src="assets/images/search-image.svg">
        <p>{{options.search?.mustSearchText}}</p>
    </div>

    <ng-template #loadingList>
        <div *ngIf="onGoingRequest; else searchNotRequired" class="search-box">
            <p>جاري التحميل</p>
            <i class="fa fa-spinner fa-spin"></i>
        </div>
    </ng-template>

    <ng-template #searchNotRequired>
        <div class="table-wrapper">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <ng-container *ngIf="options.bulkActions">
                            <th *abpPermission="options.bulkActions.requiredPermission">
                                <div *ngIf="pagedResult.items?.length!==0" class="custom-control custom-checkbox">
                                    <input (change)="checkAll($event)" [checked]="isAllChecked()" class="custom-control-input" id="checkAll" type="checkbox">
                                    <label class="custom-control-label font-weight-bold" for="checkAll">&nbsp;</label>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container *ngFor="let col of options.columns">
                            <ng-container *ngIf="!col.hideFromTable">
                                <th *abpPermission="col.requiredPermission||''">{{col.title}}</th>
                            </ng-container>
                        </ng-container>
                        <th aria-label="actions"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!pagedResult.items?.length">
                        <td *ngIf="!notFoundMessage" colspan="12">
                            لا يوجد بيانات
                        </td>
                        <td *ngIf="notFoundMessage" colspan="8">
                            {{notFoundMessage}}
                        </td>
                    </tr>
                    <tr *ngFor="let row of pagedResult.items">
                      <ng-container *ngIf="options.bulkActions">
                        <th *abpPermission="options.bulkActions.requiredPermission">
                          <div class="custom-control custom-checkbox">
                            <input (change)="checkItem($event,row)" [checked]="isChecked(row)" [id]="'item-select-'+row[options?.idField]" class="custom-control-input" type="checkbox">
                            <label [for]="'item-select-'+row[options?.idField]" class="custom-control-label font-weight-bold">&nbsp;</label>
                          </div>
                        </th>
                      </ng-container>
                      <ng-container *ngFor="let col of options.columns">
                        <ng-container *ngIf="!col.hideFromTable">
                          <td *abpPermission="col.requiredPermission||''" [title]="row[col.field]?.length > 25 ? row[col.field] : ''">
                            <ng-container [ngSwitch]="col.type">
                              <ng-container *ngSwitchCase="columnTypes.Icon">
                                <span *ngIf="col.iconClassFn ? col.iconClassFn(row) : false" [class]="col.iconTagClass||''">
                                  <i [class]="col.iconClassFn ? col.iconClassFn(row) : ''"></i>
                                </span>
                              </ng-container>
                              <ng-container *ngSwitchCase="columnTypes.Enum">
                                {{getFromEnum(col, row) | abpLocalization}}
                              </ng-container>
                              <ng-container *ngSwitchCase="columnTypes.Date">
                                {{row[col.field] | customDate}}
                              </ng-container>
                              <ng-container *ngSwitchDefault>{{row[col.field]}}</ng-container>
                            </ng-container>
                          </td>
                        </ng-container>

                      </ng-container>
                     
                   
                      <td aria-label="actions">

                        <div class="d-flex align-items-center">

                          <!--
            <div class="switch">
              <input type="checkbox" class="switch-input" [id]="'office_'+row.id">
              <label class="switch-label" [for]="'office_'+row.id"></label>
            </div>
      -->
                          <ng-container *ngIf="options.actions?.buttons?.length">
                            <ng-container *ngFor="let button of options.actions?.buttons">
                              <ng-container *ngIf="button.conditionFn ? button.conditionFn(row) : true">
                                <ng-container *ngIf="button.route">
                                  <a *abpPermission="button.requiredPermission" [routerLink]="[button.route ,(button.paramField ? row[button.paramField] :'')]" class="btn btn-outline-secondary mr-2">
                                    <i *ngIf="button.iconClass" [class]="'icon mr-1 '+button.iconClass"></i> {{button.titleFn ? button.titleFn(row) : button.title}}
                                  </a>
                                </ng-container>

                                <ng-container *ngIf="button.actionFn">
                                  <a (click)="doButtonAction(button,row[button.paramField])" *abpPermission="button.requiredPermission" class="btn btn-outline-secondary mr-2">
                                    <i *ngIf="button.iconClass" [class]="'icon mr-1 '+button.iconClass"></i> {{button.titleFn ? button.titleFn(row) : button.title}}
                                  </a>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <div *ngIf="hasActions" class="actions ml-4">
                            <div *ngIf="row.permission != '3'" container="body" ngbDropdown>
                              <i [id]="'actions_'++row.id!" class="icon icon-settings no-arrow actions__dropdown-toggle" ngbDropdownToggle></i>
                              <div [attr.aria-labelledby]="'actions_'++row.id!" class="actions__dropdown-menu" ngbDropdownMenu>
                                <button (click)="openEditModal(row)" *ngIf="options.actions?.canEdit" class="actions__dropdown-item" ngbDropdownItem>
                                  <i class="icon icon-edit-note mr-2"></i>
                                  تعديل
                                </button>
                                <button *ngIf="options.actions?.canDelete" class="actions__dropdown-item text-danger" ngbDropdownItem>
                                  <i class="icon icon-delete mr-2"></i>
                                  حذف
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-container *ngIf="options.iconsGuides && !options.iconsGuides.hideFromTable">
            <div *abpPermission="options.iconsGuides.requiredPermission||''" class="icons-guides">
                <div *ngFor="let guide of options.iconsGuides.guides" class="icons-guides__guide">
                    <i [class]="guide.iconClass"></i>{{guide.title}}
                </div>
            </div>
        </ng-container>

        <div *ngIf="pagedResult.totalCount! > params.maxResultCount" class="pagination-wrapper">
            <ngb-pagination (pageChange)="pageChange($event)" [(page)]="page" [collectionSize]="pagedResult.totalCount!" [maxSize]="5" [pageSize]="params.maxResultCount" [rotate]="true" aria-label="Default pagination">
            </ngb-pagination>
        </div>


        <div *ngIf="options.bulkActions" class="bulk-actions mt-4 w-100">
            <div *abpPermission="options.bulkActions.requiredPermission" class="row w-100">
                <span class="d-block col-sm-6 col-12 mt-3">{{(options.bulkActions.selectedCountText || selectedCountText) | abpLocalization}}
                    ({{checkedIds.length}})</span>
                <div class="col-sm-6 col-12">
                    <ng-container *ngFor="let action of options.bulkActions.actions">
                        <button (click)="doBulkAction(action)" *abpPermission="action.requiredPermission||''" [class]="action.buttonClass || 'btn-primary'" [disabled]="(isLoadingBulkAction || action.onSelected && !checkedIds.length)|| pagedResult.items?.length==0" class="btn mr-2">
                            <i *ngIf="action.iconClass" [class]="'icon '+action.iconClass"></i>
                            {{action.title | abpLocalization}}
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- Add the modal here -->
    <abp-modal [(visible)]="isModalOpen" [options]="{scrollable:false,modalDialogClass:'admin-modal'}">
      <ng-template #abpBody>
        <div class="app-header">
          <h2 *ngIf="options.modalTitles" class="app-title">
            {{isEdit ? options.modalTitles.edit : options.modalTitles.add}}
          </h2>
        </div>
        <form [formGroup]="form!">
          <div class="row">
            <div [class]="'col-'+(options?.formGridSize || 9)">
              <div class="row">
                <ng-container *ngFor="let col of orderedColumns;first as first">
                  <div *ngIf="col.requiredCondition?.hideIfNotMeet ? col.required : true" [class]="'col-'+(col.gridSize || 6)+' '+ (col.extraClass||'')">
                    <div class="form-group">
                      <label [class.required]="col.required" [for]="'field_'+col.field">
                        <i *ngIf="col.iconClass"
                           [class]="'icon '+col.iconClass"></i>{{col.title}}
                      </label>
                      <input (blur)="onInputBlur(col)" *ngIf="isInputType(col.type)" [attr.autofocus]="first" [formControlName]="col.formControlField||col.field" [id]="'field_'+col.field" [max]="col.input?.max" [maxlength]="col.input?.maxLength" [min]="col.input?.min" [minlength]="col.input?.minLength"
                             [numbersOnly]="col.input?.numbersOnly" [placeholder]="col.placeholder||''" [type]="col.type||'text'" class="form-control" />
                      <ng-select (ngModelChange)="changeLookup(col)" *ngIf="isSelectType(col.type)"
                                 [bindLabel]="col.lookup?.labelField || 'key'"
                                 [bindValue]="col.lookup?.valueField || 'value'"
                                 [formControlName]="col.formControlField||col.field"
                                 [id]="'field_'+col.field"
                                 [items]="lookups[col.formControlField||col.field]?.data!"
                                 [placeholder]="col.lookup?.placeholder">
                        <ng-container *ngIf="col.type === columnTypes.Enum && col.lookup">
                          <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
                            {{col.lookup.enumName ? (('::Enum:' + col.lookup.enumName + ':' + item[(col.lookup.valueField || 'value')]) | abpLocalization) : item[(col.lookup.labelField || 'key')]}}
                          </ng-template>
                          <ng-template let-item="item" ng-label-tmp>
                            {{col.lookup.enumName ? (('::Enum:' + col.lookup.enumName + ':' + item[(col.lookup.valueField || 'value')]) | abpLocalization) : item[(col.lookup.labelField || 'key')]}}
                          </ng-template>
                        </ng-container>
                      </ng-select>

                    </div>
                  </div>
                </ng-container>
                <div *ngIf="options?.hasCaptcha && !isEdit" class="col-6 mt-3">
                  <!--*ngIf="options?.hasCaptcha"-->
                  <div>
                    <app-captcha [maxLength]="6" (onCodeChanges)="onCaptchaChanges($event)"></app-captcha>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        
      </ng-template>


      <ng-template #abpFooter>
        <button abpClose (click)="closePopUp()" class="btn btn-outline-secondary" type="button">
          إلغاء
        </button>




        <!--added save button-->
        <button (click)="save()" [disabled]="form?.invalid" class="btn btn-primary">
          <i [class]="'icon mr-1 '+(isEdit ? 'icon-edit' : (options.buttons?.iconClass||'icon-plus'))"></i>
          {{isEdit ? options.buttons?.edit : options.buttons?.add}}
        </button>
      </ng-template>




      <div *ngIf="isSupervisorsModalOpen">
        <abp-modal [(visible)]="isSupervisorsModalOpen" [options]="{size: 'lg',modalDialogClass:'modal-stopped'}">
          <ng-template #abpHeader>
          </ng-template>
          <ng-template #abpBody>
            <div class="col-md-6 col-12">
              <div class="modal-header pt-0">
                <img class="modal-img" src="assets/images/delete-Ico.svg" alt="">
                <h5 class="modal-title" *ngIf="isSupervisorsModalOpen">ايقاف</h5>
              </div>
              <h5 class="modal-desc my-2 text-center" *ngIf="isSupervisor">
                الرجاء اختيار المشرف الذي سيتم نقل الموظفين
                إليه.
              </h5>
              <h5 class="modal-desc my-2 text-center" *ngIf="!isSupervisor">
                الرجاء اختيار الموظف الذي سيتم نقل الطلبات
                إليه.
              </h5>
            </div>
            <div class="form-group form-inputs col-md-6 col-12 my-3">
              <p class="text-left pt-3 h6" *ngIf="isSupervisor">أسماء مشرفي الإدارة</p>
              <p class="text-left pt-3 h6" *ngIf="!isSupervisor">أسماء موظفي الإدارة</p>
              <ng-select (change)="getToSupervisorId($event)" [items]="supervisorUsers" bindLabel="name"
                         bindValue="id" [clearable]="false" [searchable]="false" id="toSupervisorId"></ng-select>
              <p class="modal-notes" *ngIf="isSupervisor" class="mt-4">
                ملاحظة: عملية إسناد الموظفين قد تستغرق وقت
                طويل، علمًا بأن سيتم تغيير حالة الصلاحية بشكل مباشر بعد إتمام العملية.
              </p>
              <p class="modal-notes" *ngIf="!isSupervisor" class="mt-4">
                ملاحظة: عملية نقل الطلبات قد تستغرق وقت طويل،
                علمًا بأن سيتم تغيير حالة الصلاحية بشكل مباشر بعد إتمام العملية.
              </p>
            </div>
            <div class="d-flex my-auto pt-5">
              <button abpClose class="main-page__main-description__details-btn bg-light mr-3 modal-buttons p-0">
                إلغاء
              </button>
              <button type="button" (click)="updateStoped()"
                      class="btn btn-primary request-btn my-2 my-sm-0 modal-buttons p-0 mr-2">
                موافق
              </button>
            </div>
          </ng-template>
        </abp-modal>
      </div>
      <div *ngIf="isDeleteModalOpen">
        <abp-modal [(visible)]="isDeleteModalOpen" [options]="{size: 'lg',modalDialogClass:'modal-stopped'}">
          <ng-template #abpHeader>
          </ng-template>
          <ng-template #abpBody>
            <div class="col-md-6 col-12">
              <div class="modal-header pt-0">
                <img class="modal-img" src="assets/images/delete-Ico.svg" alt="">
                <h5 class="modal-title" *ngIf="isDeleteModalOpen">حذف</h5>
              </div>
              <h5 class="modal-desc mt-2 text-center" *ngIf="isSupervisor">
                الرجاء اختيار المشرف الذي سيتم نقل الموظفين
                إليه.
              </h5>
              <h5 class="modal-desc mt-2 text-center" *ngIf="!isSupervisor">
                الرجاء اختيار الموظف الذي سيتم نقل الطلبات
                إليه.
              </h5>
            </div>
            <p class="text-left pt-3 h6 col-md-6 col-12 mt-3" *ngIf="isSupervisor">أسماء مشرفي الإدارة</p>
            <p class="text-left pt-3 h6 col-md-6 col-12 mt-3" *ngIf="!isSupervisor">أسماء موظفي الإدارة</p>
            <div class="form-group form-inputs col-md-6 col-12 my-3">
              <ng-select (change)="getToSupervisorId($event)" [items]="supervisorUsers" bindLabel="name"
                         bindValue="id" [clearable]="false" [searchable]="false" id="toSupervisorId"></ng-select>
              <p class="modal-notes" *ngIf="isSupervisor" class="mt-4">
                ملاحظة: عملية إسناد الموظفين قد تستغرق وقت
                طويل، علمًا بأن سيتم تغيير حالة الصلاحية بشكل مباشر بعد إتمام العملية.
              </p>
              <p class="modal-notes" *ngIf="!isSupervisor" class="mt-4">
                ملاحظة: عملية نقل الطلبات قد تستغرق وقت طويل،
                علمًا بأن سيتم تغيير حالة الصلاحية بشكل مباشر بعد إتمام العملية.
              </p>
            </div>
            <div class="d-flex my-auto pt-5">
              <button abpClose class="main-page__main-description__details-btn bg-light mr-3 modal-buttons p-0">
                إلغاء
              </button>
              <button type="button" (click)="updateStopedOnDelet()"
                      class="btn btn-primary request-btn my-2 my-sm-0 modal-buttons p-0 mr-2">
                موافق
              </button>
            </div>
          </ng-template>
        </abp-modal>
      </div>


    </abp-modal>
</ng-container>

<ng-template #noRepository>
    <div class="alert alert-danger text-center">
        <div *ngIf="!repository"><b>Repository</b> not provided</div>
        <div *ngIf="!options"><b>Options</b> not provided</div>
    </div>
</ng-template>
