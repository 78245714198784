import { mapEnumToOptions } from '@abp/ng.core';

export enum AdminRoles {
  MemberOfTheInspectionDepartment = 1,
  SupervisorOfTheInspectionDepartment = 2,
  MemberOfTheTechnicalDepartment = 3,
  SupervisorOfTheTechnicalDepartment = 4,
  MemberOfTheShariaAdministration = 5,
  SupervisorOfTheShariaAdministration = 6,
  MemberOfTheSecretariatOfTheCommitteeToConsiderRequests = 7,
  MemberOfTheApplicationsInspectCommittee = 8,
  MemberOfTheLegalDepartment = 9,
  Secretary = 10,
  RepresentativeOfTheMinistryOfJustice = 11,
  RepresentativeOfTheGeneralSurveyAuthority = 12,
  MemberOfTheApplicationsReviewCommittee = 13,
  CommitteesDirectorGeneral = 14,
  CommitteeSecretaryToConsiderFormalRequests = 15,
  CommitteeSecretaryToConsiderGovernmentRequests = 16,
  CommitteeSecretaryToConsiderAwqafRequests = 17,
  CommitteeSecretaryToConsiderNormalRequests = 18,
  PublishingUnitAndObjectionsEmployee = 19,
  AuditCommitteeSecretary = 20,
  SpecializationStudyMember = 21,
  SupervisorOfThePreliminaryStudiesCommittee = 22,
  RequestsStudyingMember = 23,
  EmirateRepresentative = 24,
  ConsiderationCommitteeMember = 25,
}

export const adminRolesOptions = mapEnumToOptions(AdminRoles);
