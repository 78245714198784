import { Component, OnInit } from '@angular/core';
import { RequestPreviewTabs } from '@data/common/enum';
import { PublicUserRepository } from '@data/repository';
import { AppAuthService } from '@spga-shared/providers/auth';
import { ToasterService } from '@abp/ng.theme.shared';
import { UserUpdatedDto } from '@proxy/account/models';
import { EmailValidationService } from '../../services/email-validation.service';

@Component({
  selector: 'app-email-validation-card',
  templateUrl: './email-validation-card.component.html',
  styleUrls: ['./email-validation-card.component.scss']
})
export class EmailValidationCardComponent implements OnInit {

  currentUser: any;
  userType = RequestPreviewTabs
  showCard = false
  UpdatedUserInfo!: UserUpdatedDto

  constructor(
    private authService: AppAuthService,
    private publicUserRepository: PublicUserRepository,
    private toaster: ToasterService,
    private emailValidationService: EmailValidationService,
  ) { }

  ngOnInit(): void {
    this.authService.currentConfig$.subscribe(val => {
      this.currentUser = val.currentUser
    })
    this.checkIfEmailVerified()
    this.emailValidationService.isEmailUpdated$.subscribe((v) => {
      if (v) {
        this.checkIfEmailVerified()
      }
    });
  }

  checkIfEmailVerified() {
    if (this.authService.checkIfLoggedIn()) {
      this.publicUserRepository.GetUpdatedUserInfo().subscribe(val => {
        this.UpdatedUserInfo = val;
        if (!this.UpdatedUserInfo.isEmailConfirmed && this.currentUser.roles[0] == this.userType.SPGAPublicUser) {
          this.showCard = true;
        }
        else {
          this.showCard = false;
        }
      })
    }
  }

  onActivateEmail() {
    this.publicUserRepository.generateConfirmationEmail().subscribe(val => {
      this.toaster.success('تم إرسال رابط التفعيل إلى بريدك الإلكتروني بنجاح')
    })
  }

}
