import { mapEnumToOptions } from '@abp/ng.core';

export enum OutsideSpecRelatedToCourtInstruments {
  ExecutionOfJudicialRuling = 1,
  EditTitle = 2,
  AddRevivalDate = 3,
  EditCivilRegistrationNo = 4,
  AddArea = 5,
  AddBuildings = 6,
}

export const outsideSpecRelatedToCourtInstrumentsOptions = mapEnumToOptions(OutsideSpecRelatedToCourtInstruments);
