import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  transform(value?: string): string {
    return value ? value.split('T')[0].split('-').reverse().join('/') : '';
  }
}
