import { mapEnumToOptions } from '@abp/ng.core';

export enum RequestPath {
  Normal = 1,
  Formal = 2,
  Governmental = 3,
  AwqafAuthority = 4,
}

export const requestPathOptions = mapEnumToOptions(RequestPath);
