import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealEstateRequestReportApi } from '../api';
import { RequestAreaReport, RequestAreaReportDetails, RequestReportRes, UpdateAreaReportCoordinates} from '../model';
import { Repository } from './repository';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { tap } from 'rxjs/operators';
import { downloadBlobFile } from '@spga-shared/utils';
import { ToasterService } from '@abp/ng.theme.shared';
import { TaqniaAreaReportDto } from '@proxy/real-estate-ownership-requests';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestReportRepository implements Repository<any, any, any> {
  selectedStatementId?: number;

  constructor(private realEstateRequestReportApi: RealEstateRequestReportApi,
    private toaster: ToasterService
    ) {
  }

  get(id: number): Observable<RequestAreaReport> {
    return this.realEstateRequestReportApi.getRequestReport(id);
  }

  getDetails(id: number): Observable<RequestAreaReportDetails> {
    return this.realEstateRequestReportApi.getRequestAreaReportDetails(id);
  }

  create(input: RequestAreaReport): Observable<RequestReportRes> {
    if (this.selectedStatementId) {
      input.statementId = this.selectedStatementId;
    }
    return this.realEstateRequestReportApi.createRealEstateRequestReport(input)
      .pipe(tap(() => this.selectedStatementId = undefined));
  }

  cancel(input: number): Observable<void> {
    return this.realEstateRequestReportApi.cancelRequest(input);
  }

  downloadAreaReportAttachmentById(id: string, name: string): Observable<FileContentResult> {
    return this.realEstateRequestReportApi.downloadAreaReportAttachmentById(id)
      .pipe(tap(x => downloadBlobFile(x, '', name)));
  }

  updateCoordinates(input: UpdateAreaReportCoordinates):Observable<boolean>{
    return this.realEstateRequestReportApi.updateAreaReportCoordinates(input)
    .pipe(tap((res) =>
    {
      if(res)
       this.toaster.success('تم تعديل حدود العقار بنجاح');
     else 
      this.toaster.error('حدث خطاء');
    } ));
  }

  checkTaqniaToolData(taqniaAreaReportDto: TaqniaAreaReportDto): Observable<boolean> {
    return this.realEstateRequestReportApi.checkTaqniaToolData(taqniaAreaReportDto);
  }
}
