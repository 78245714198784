import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebHookService {
  apiName = 'Default';

  webhook = () =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/webHook/webhook`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
