import { AttachmentType } from '@proxy/enums';
import { ChangeEngOfficeReasons } from '.';


export interface ChangeEngOfficeRequestDto {
    decision: boolean;
    requestId: number;
    changeEngOfficeReason?: number[]
    changeEngOfficeOtherReason?: string
    attachmentsUrls?: ChangeEngOfficeAttachmentDto[]
}

export interface ChangeEngOfficeAttachmentDto {
    description?: string;
    name?: string;
    extension?: string;
    attachmentType?: AttachmentType;
    url?: string;
}

export interface ChangeOfficeAttachmentResponseDto {
    url: string;
    blobName: string;
}
