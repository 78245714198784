import { Component, Injectable, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { LocalizationService } from '@abp/ng.core';
import { Confirmation, ToasterService, ConfirmationService } from '@abp/ng.theme.shared';

import { AppAuthService } from './app-auth-service.service';

import { Router } from '@angular/router';

import { NgIdleService } from './ng-idle.service';
import { environment } from 'projects/spga-shared/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserIdleService {
  idleTimerLeft!: string;
  secondTimerLeft!: string;
  timeRemain!: number;
  FULL_DASH_ARRAY = 283;

  firstLevelTimer!: number;
  secondLevelTimer!: number;
  constructor(
    private ngIdle: NgIdleService,
    private confirmationService: ConfirmationService,
    private appAuthService: AppAuthService,
    private router: Router,
    private localizationService: LocalizationService
  ) { }

  startInializeIdelTime(firstTimerValue: number): void {
    this.firstLevelTimer = firstTimerValue;

    this.IntailizeTimer();
  }

  IntailizeTimer(): void {
    this.initTimer(this.firstLevelTimer, this.secondLevelTimer);
  }
  logout(): void {

    const options: Partial<Confirmation.Options> = {
      hideCancelBtn: true,
      hideYesBtn: false,
      dismissible: false,
      yesText: "نعم"
    };
    var isConfirm = false
    this.confirmationService
      .warn('انتهت الجلسة سيتم تسجيل خروجك, هل تريد المواصلة', 'تسجيل الخروج', options)
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          isConfirm = true
          this.startInializeIdelTime(.5);
          return
        }
      });
    setTimeout(() => {
      if (!isConfirm) {
        this.appAuthService.logout();
        NgIdleService.runTimer = false;
      }
    }, 30000)
    // To Do Acctual Log Out
    // this.appAuthService.logoutABP();
    //this.router.navigateByUrl('/');
    // stop all timer and end the session
  }

  initTimer(firstTimerValue: number, secondTimerValue: number): void {
    // Timer value initialization
    this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN = firstTimerValue;
    this.ngIdle.FINAL_LEVEL_TIMER_VALUE_IN_MIN = secondTimerValue;
    // end
    console.log('Timer value initialization');

    // Watcher on timer
    this.ngIdle.initilizeSessionTimeout();
    this.ngIdle.userIdlenessChecker?.subscribe((status: string) => {
      this.initiateFirstTimer(status);
    });

    this.ngIdle.secondLevelUserIdleChecker?.subscribe((status: string) => { });
  }

  initiateFirstTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        break;

      case 'STOPPED_TIMER':
        this.logout();
        break;
    }
  };
}
