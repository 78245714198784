import type { CreateUpdateEngineeringOfficeDto, EngineeringOfficeDto, EngineeringOfficeResponse, GetAllEngineeringOfficesInputDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EngineeringOfficeService {
  apiName = 'Default';

  create = (input: CreateUpdateEngineeringOfficeDto) =>
    this.restService.request<any, EngineeringOfficeResponse>({
      method: 'POST',
      url: `/api/app/engineeringOffice`,
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: number) =>
    this.restService.request<any, boolean>({
      method: 'DELETE',
      url: `/api/app/engineeringOffice/${id}`,
    },
    { apiName: this.apiName });

  get = (id: number) =>
    this.restService.request<any, EngineeringOfficeDto>({
      method: 'GET',
      url: `/api/app/engineeringOffice/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: GetAllEngineeringOfficesInputDto) =>
    this.restService.request<any, PagedResultDto<EngineeringOfficeDto>>({
      method: 'GET',
      url: `/api/app/engineeringOffice`,
      params: { filter: input.filter, regionFilter: input.regionFilter, cityFilter: input.cityFilter, authorityTypeFilter: input.authorityTypeFilter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getListAnonymous = (input: GetAllEngineeringOfficesInputDto) =>
    this.restService.request<any, PagedResultDto<EngineeringOfficeDto>>({
      method: 'GET',
      url: `/api/app/engineeringOffice/anonymous`,
      params: { filter: input.filter, regionFilter: input.regionFilter, cityFilter: input.cityFilter, authorityTypeFilter: input.authorityTypeFilter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: number, input: CreateUpdateEngineeringOfficeDto) =>
    this.restService.request<any, EngineeringOfficeResponse>({
      method: 'PUT',
      url: `/api/app/engineeringOffice/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
