import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-navigation-btns',
  templateUrl: './navigation-btns.component.html',
  styleUrls: ['./navigation-btns.component.scss']
})
export class NavigationBtnsComponent implements OnChanges {
  @Input() label?: string;
  @Input() Coloring: any;
  @Input() iconStyle?: string;
  @Input() isBack?: boolean;
  @Input() FormValid?: boolean;
  @Output() LoginClick = new EventEmitter<any>();

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ref.detectChanges();
  }

  // tslint:disable-next-line:variable-name
  onClickButton(_event: any): void {
  }

  isFormValid(): boolean {
    return !!this.FormValid;
  }
}
