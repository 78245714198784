import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[type=number], input[numbersOnly]'
})
export class NumbersOnlyInputDirective {
  @Input('numbersOnly') active?: boolean;

  constructor(private elRef: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    if (this.active) {
      const initialValue = this.elRef.nativeElement.value;
      this.elRef.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
      if (initialValue !== this.elRef.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }

}
