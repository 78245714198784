<div class="align-items-center d-none d-lg-flex" ngbDropdown>
  <img src="assets/images/user-profile.svg" alt="" ngbDropdownToggle>
  <div class="mt-2" ngbDropdownMenu>
    <button ngbDropdownItem (click)="logout()">
      <i class="icon icon-power"></i>
      تسجيل الخروج
    </button>
  </div>
  <div class="ml-2">
    <span>{{currentUser.userName}}</span>
    <div class="user-details"><u (click)="navigateToMyRequets()">طلباتي</u></div>
  </div>
</div>

<div class="d-block d-lg-none">
  <div class="main-nav-bar">
    <ul class="navbar-nav main-nav-bar__sections p-0">
      <li class="nav-item">
        <a class="nav-link text-danger" (click)="logout()">
          <i class="icon icon-power"></i>
          تسجيل الخروج</a>
      </li>
    </ul>
  </div>
</div>