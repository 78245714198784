import { mapEnumToOptions } from '@abp/ng.core';

export enum AdsArea {
  From1To500 = 1,
  From501To1000 = 2,
  From1001To2000 = 3,
  From2001To5000 = 4,
  From5001 = 5,
}

export const adsAreaOptions = mapEnumToOptions(AdsArea);
