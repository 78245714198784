import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NationalityApi } from '../api';
import { Nationality } from '../model';
import { Repository } from './repository';

@Injectable({
    providedIn: 'root',
})
export class NationalityRepository implements Repository<Nationality, any, Nationality> {
    public nationalities$: BehaviorSubject<Nationality[]> = new BehaviorSubject<Nationality[]>([]);

    constructor(private nationalityApi: NationalityApi) {
    }

    getAll(): Observable<Nationality[]> {
        return this.nationalityApi.getNationalities().pipe(
            tap((res) => {
                this.nationalities$.next(res);
            })
        );
    }

}
