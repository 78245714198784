<ng-container *ngIf="statements?.items?.length || statements?.length ">
    <h2 class="app-title">الإفادات</h2>
    <div *ngFor="let statement of (statements.items || statements) ; let index=index;" class="statement-box">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <ng-container>
                    <h3 class="statement-box__date" *ngIf="statement?.type == 55">ملاحظات مشرف لجنة الدراسات الأولية</h3>
                    <h3 class="statement-box__date" *ngIf="statement?.type == 74">إفادة لجنة النظر</h3>
                </ng-container>
                
                <h4 class="statement-box__title">الإفادة</h4>
                <h3 class="statement-box__date">{{statement.creationTime | customDate}}</h3>
                <div class="statement-box__data">
                    {{statement.statement}}<a *ngIf="statement && statement.engOfficeShapeFileGuideline" target="_blank" [href]="statement.engOfficeShapeFileGuideline">الدليل الإرشادي</a>
                </div>
                <div *ngIf="statement.statementReply">
                    <h4 class="statement-box__title">رد الإفادة</h4>
                    <h3 class="statement-box__date">{{statement.answeredTime | customDate}}</h3>
                    <div class="statement-box__data">
                        {{statement.statementReply}}
                    </div>
                </div>
            </div>
            <button *ngIf="((externalCustomers || (canEditStatement && index == 0)) && canEditStatementForPublic)" (click)="click(statement)" class="btn btn-primary">{{buttonText}}</button>
        </div>
        <ng-container *ngTemplateOutlet="attachments"></ng-container>
        <ng-template #attachments>
            <div *ngIf="statement.attachmentsUrls?.length>0;else noAttachments">
                <button (click)="showAttachement = !showAttachement" class="tertiary linkish">{{!showAttachement ? 'المرفقات': 'اخفاء المرفقات'}}</button>
                <div *ngIf="showAttachement" class="row">
                    <div *ngFor="let attachmentInput of statement.attachmentsUrls" class="col-md-4">
                        <app-custom-file-upload [attachmentInput]="attachmentInput" [attachmentType]="attachmentInput.attachmentType" [downloadFn]=" downloadAttachmentFn" [desc]="attachmentInput.description">
                        </app-custom-file-upload>
                    </div>
                </div>
            </div>
            <ng-template #noAttachments>
                <div class="alert alert-info">لا توجد مرفقات</div>
            </ng-template>
        
        </ng-template>
    </div>
</ng-container>
<div *ngIf="statements?.totalCount > params.maxResultCount" class="pagination-wrapper">
    <ngb-pagination (pageChange)="pageChange($event)" [(page)]="page" [collectionSize]="statements?.totalCount" [maxSize]="3" [pageSize]="params.maxResultCount" [rotate]="true"
                    aria-label="Default pagination"></ngb-pagination>
</div>