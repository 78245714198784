<div #container id="abp-http-error-container" class="error">
  <div class="container">
    <div class="header">
      <div class="header__logo">
        <abp-logo></abp-logo>
      </div>
    </div>
    <div class="row centered">
      <div class="app-content" [ngSwitch]="errorCode">
        <ng-container *ngSwitchCase="'/error403'">
          <h1 class="app-title">خطأ</h1>
          <p class="app-sub-title text-semi-dark">غير مسموح لك بالوصول</p>
          <img alt="404" class="img-fluid" width="600" src="assets/images/403.svg">
          <a class="btn btn-outline-secondary" href="/">العودة للرئيسية</a>
        </ng-container>
        <ng-container *ngSwitchCase="'/error405'">
          <h1 class="app-title">حدث خطأ !</h1>
          <p class="app-sub-title text-semi-dark"></p>
          <img alt="404" class="img-fluid" width="600" src="assets/images/405.svg">
          <a class="btn btn-outline-secondary" href="/">العودة للرئيسية</a>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <h1 class="app-title">خطأ</h1>
          <p class="app-sub-title text-semi-dark"></p>
          <a class="btn btn-outline-secondary" href="/">العودة للرئيسية</a>
        </ng-container>
      </div>
    </div>
  </div>
</div>