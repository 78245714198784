<agm-map style="height:300px" [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeId]="'hybrid'" (mapClick)="addMarker($event.coords.lat, $event.coords.lng)">
    <agm-marker iconUrl="assets/images/map-pin.svg" *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng" [opacity]="marker.alpha" [markerDraggable]="false" (markerClick)="selectMarker($event)" (dragEnd)="dragEnd($event)">
    </agm-marker>
    <!-- <agm-rectangle [north]="max('lat')" [east]="max('lng')" [south]="min('lat')" [west]="min('lng')">
   </agm-rectangle>-->
</agm-map>
<ng-container *ngIf="isCoordinatesControl">
  <div class="row">
    <div class="col-md-4 mr-1">
      <div class="form-group">
        <label for="lat" class="pb-2">احداثيات العقار  X: </label>
      <div *ngIf="isEditable">
        <input class="form-control  form-inputs__input"  id="lat" placeholder="احداثيات العقار x"  maxlength="60" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" required type="number"
       [(ngModel)]=lat (change)=setLatLng(lat,lng)>
      </div>
      <div *ngIf="!isEditable"> 
        <label for="lat" class="pb-2">{{location?.lat}}</label>
      </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label for="lng" class="pb-2">احداثيات العقار Y: </label>
        <div *ngIf="isEditable">
        <input class="form-control  form-inputs__input" id="lng" placeholder="احداثيات العقار y"  maxlength="60" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" required type="number"
        [(ngModel)]=lng  (change)=setLatLng(lat,lng)>
        </div>
        <div *ngIf="!isEditable">
          <label for="lng" class="pb-2">{{location?.lng}}</label>
        </div>
        </div>
      </div>
    </div>
</ng-container>