import { mapEnumToOptions } from '@abp/ng.core';

export enum StatementStatusWithExpiredEnum {
  NotAnswered = 0,
  Answered = 1,
  Expired = 2,
}

export const statementStatusWithExpiredEnumOptions = mapEnumToOptions(StatementStatusWithExpiredEnum);

