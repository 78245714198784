import type { OtpGenerationModelDto, OtpVerificationModelDto, OtpVerificationResponseDto, SendOtpResponseDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NationalNotificationService {
  apiName = 'Default';

  sendOtpByOtpGenerationModel = (otpGenerationModel: OtpGenerationModelDto) =>
    this.restService.request<any, SendOtpResponseDto>({
      method: 'POST',
      url: `/api/app/nationalNotification/sendOtp`,
      body: otpGenerationModel,
    },
    { apiName: this.apiName });

  verifyOtpByOtpVerificationModel = (otpVerificationModel: OtpVerificationModelDto) =>
    this.restService.request<any, OtpVerificationResponseDto>({
      method: 'POST',
      url: `/api/app/nationalNotification/verifyOtp`,
      body: otpVerificationModel,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
