import type { CreateObjectionAttachmentResponseDto, CreateObjectionRequestDto, GetObjectionAdminListRequestDto, GetObjectionAdminListResponseDto, GetObjectionDetailsReponseDto, GetObjectionResponseDto,ObjectionDetailsPerRequestDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IFormFile } from '../microsoft/asp-net-core/http/models';
import type { FileContentResult } from '../microsoft/asp-net-core/mvc/models';

@Injectable({
  providedIn: 'root',
})
export class ObjectionService {
  apiName = 'Default';

  createObjectionAttachmentByFile = (file: IFormFile) =>
    this.restService.request<any, CreateObjectionAttachmentResponseDto>({
      method: 'POST',
      url: `/api/app/objection/objectionAttachment`,
    },
    { apiName: this.apiName });

  createObjectionByInput = (input: CreateObjectionRequestDto) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/objection/objection`,
      body: input,
    },
    { apiName: this.apiName });

  downloadObjectionAttachmentById = (id: string) =>
    this.restService.request<any, FileContentResult>({
      method: 'GET',
      url: `/api/app/objection/${id}/downloadObjectionAttachment`,
    },
    { apiName: this.apiName });

  getAdminObjectionListByInput = (input: GetObjectionAdminListRequestDto) =>
    this.restService.request<any, PagedResultDto<GetObjectionAdminListResponseDto>>({
      method: 'GET',
      url: `/api/app/objection/adminObjectionList`,
      params: { filter: input.filter, realEstateTypeFilter: input.realEstateTypeFilter, realEstateOwnershipRequestTypeFilter: input.realEstateOwnershipRequestTypeFilter, regionFilter: input.regionFilter, cityFilter: input.cityFilter, dateFromFilter: input.dateFromFilter, dateToFilter: input.dateToFilter, objectionDateFromFilter: input.objectionDateFromFilter, objectionDateToFilter: input.objectionDateToFilter, objectionBillStatusFilter: input.objectionBillStatusFilter, requestDateFromFilter: input.requestDateFromFilter, requestDateToFilter: input.requestDateToFilter, sadadDateFromFilter: input.sadadDateFromFilter, sadadDateToFilter: input.sadadDateToFilter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getObjectionDetailsByObjectionId = (objectionId: number) =>
    this.restService.request<any, GetObjectionDetailsReponseDto>({
      method: 'GET',
      url: `/api/app/objection/objectionDetails/${objectionId}`,
    },
    { apiName: this.apiName });

    getObjectionDetailsListByRequestId = (input: ObjectionDetailsPerRequestDto) =>
    this.restService.request<any, PagedResultDto<GetObjectionDetailsReponseDto>>({
      method: 'GET',
      url: `/api/app/objection/objectionDetailsList/`,
      params: { requestId: input.requestId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount }
    },
    { apiName: this.apiName });

  getObjectionListByInput = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<GetObjectionResponseDto>>({
      method: 'GET',
      url: `/api/app/objection/objectionList`,
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
