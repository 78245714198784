import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttachmentInput, StatementReplyHistoryPerDeptRequest, StatementReplyHistoryPerDeptResponse, StatementResponse } from '@data/model';
import { RealEstateRequestAttachmentRepository } from '@data/repository';
import { RealEstateRequestStatementsRepository } from '@data/repository/real-estate-request-statements.repository';
import { StatementType } from '@proxy/enums';
import { InspectionMemberAttachmentDto } from '@proxy/real-estate-attachments';
import { StatementAttachmentDto } from '@proxy/real-estate-ownership-requests-statements';
import { AppAuthService } from '@spga-shared/providers';
import { Observable } from 'rxjs';
import { IAttachment } from '..';
import { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';

@Component({
  selector: 'app-statement-preview',
  templateUrl: './statement-preview.component.html',
  styleUrls: ['./statement-preview.component.scss']
})
export class StatementPreviewComponent implements OnInit {
  @Input() requestId!: number;
  @Input() type!: StatementType[];
  @Input() buttonText = 'تعديل الطلب';
  @Input() isChangeEngOffice: boolean | null = false;
  @Input() isAssignedToNewEngOffice: boolean | null = false;
  @Output() buttonClicked = new EventEmitter<number>();
  @Output() getStatment = new EventEmitter<any>();
  attachmentsInputs:any
  statements?: any;
  downloadAttachmentFn?: (attachment: IAttachment) => Observable<any>;
  canEditStatement:any
  canEditStatementForPublic = true
  showAttachement:boolean = false
  externalCustomers!:boolean
  page = 1;
  pageSize = 3;
  params = new PagedAndSortedResultRequestDto({
    maxResultCount: 3
  }) as StatementReplyHistoryPerDeptRequest;
  constructor(
    private statementsRepository: RealEstateRequestStatementsRepository,
    private appAuthService :AppAuthService,
    private attachmentRepository: RealEstateRequestAttachmentRepository,
  ) {
  }

  ngOnInit(): void {    
    this.externalCustomers = this.appAuthService.checkIfUserHavePermission("SPGA.PublicUser || SPGA.Requests.EngOfficeRequests")
    this.checkForStatements();
    this.canEditStatement = !this.appAuthService.checkIfUserHavePermission("SPGA.Requests.RequestsStudyingMemberRequests");
    this.downloadAttachmentFn = (attachment: IAttachment) => this.statementsRepository
    .downloadStatementAttachmentById(attachment.id!,attachment.name!);
  }

  checkForStatements(): void {
    if (this.appAuthService.checkIfUserHavePermission("SPGA.Requests.RequestsStudyingMemberRequests") && this.requestId){
      this.statementsRepository.getStudyMemberStatementsFromPreliminaryStudiesCommitteeSupervisorByInput(this.requestId).subscribe(value => {
        if(value && value.length){
          this.statements = value
          this.buttonClicked.emit(value[0].statementId);
        }
      });
    }else if(this.requestId){ 
      if(this.externalCustomers){ 
        this.statementsRepository.get({
          requestId:this.requestId,
          statementReply:"",
          types:this.type
        }).subscribe(value => {
          this.statements = value
          this.updateCanEditStatement(this.statements)
        })
      }else{
        this.statementsRepository.getStatement({
          requestId: this.requestId,
          type: undefined,
          maxResultCount:3
        }).subscribe(value => {
          this.statements = value
        });
      }
    }
  }

  click(statement: any): void {
    this.buttonClicked.emit(statement.statementId);
    this.getStatment.emit(statement);
  }

  pageChange(page: number) {
    this.params.skipCount = (page - 1) * this.params.maxResultCount;
    this.statementsRepository.getStatement({maxResultCount:this.params.maxResultCount , requestId: this.requestId , type: this.type[0],skipCount:(page - 1) * this.params.maxResultCount}).subscribe(data => this.statements=data)
  }

  updateCanEditStatement(statements: any) {
    if (statements[0].type == StatementType.FromTechnicalMemberToSPGAPublicUser) {
      if(this.isChangeEngOffice && !this.isAssignedToNewEngOffice){
        this.canEditStatementForPublic = false
      }
      else
      this.canEditStatementForPublic = true
    }
    else
    this.canEditStatementForPublic = true
  }
  
  // handleAttachment(attachmentInput: AttachmentInput, attachment: IAttachment): void {
  //   attachmentInput.url = attachment as StatementAttachmentDto;
  // }
}
