import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService } from '@spga-shared/providers/auth';
import { RealEstateRequestRepository} from '@data/repository';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  currentUser: any;
  @Input() isCreateRequestEnabled!:boolean
  @Input() isRequestLayout!:boolean
  @Output() navItemClick = new EventEmitter<boolean>();


  constructor(private authService: AppAuthService, private router: Router, private repository: RealEstateRequestRepository) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentLoggedInUser$;
    if (this.currentUser && environment.chatbot?.isEnabled=="true") {
      this.getChatbotToken();
    }
  }

  getChatbotToken(): void {
    this.repository.getChatbotToken().subscribe((res) => {
      (window as any).liveChat('auth', res.token, function (errorOfChatbotAuth: { message: any; }, resultOfChatbotAuth: any) {
        if (errorOfChatbotAuth) {
          console.log("chatbot auth error");
          console.log(errorOfChatbotAuth.message);
        } else {
          console.log("chatbot auth success");
        }
      })
    });
  }
  navigateToMyRequets(): void {
    this.onNavItemClick()
    this.router.navigate(['/', 'my-requests']);
  }

  logout(): void {
    //console.log("environment : ", environment);
    //console.log("environment.chatbot : ", environment.chatbot);
    //console.log("environment.chatbot?.isEnabled : ", environment.chatbot?.isEnabled);
    if (environment.chatbot?.isEnabled == "true") {
      (window as any).liveChat('logout', null, function (error: { code: any; message: any; }, result: any) {
        console.log("chatbot logout result : ");
        if (error) {
          console.log(error.code, error.message);
          console.log(error.message);
        } else {
          console.log("logout successfully");
        }
      });
    }
    this.authService.logout();
  }

  onNavItemClick() {
    this.navItemClick.emit(true)
  }
}
