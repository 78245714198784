import type { AdminDto, EmployeeDto, GetAllAdminsInputDto, NewAdminRegisterDto, NewAdminRegisterResponseDto, SetAdminPasswordDto, UpdateAdminDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { AdminRoles } from '@proxy/enums';

@Injectable({
  providedIn: 'root',
})
export class SPGAAdminService {
  apiName = 'Default';

  getAdminByIdByIdentificationNumber = (identificationNumber: string) =>
    this.restService.request<any, AdminDto>({
      method: 'GET',
      url: `/api/app/sPGAAdmin/adminById`,
      params: { identificationNumber: identificationNumber },
    },
    { apiName: this.apiName });

  getSupervisorUsers = () =>
    this.restService.request<any, EmployeeDto[]>({
      method: 'GET',
      url: `/api/app/sPGAAdmin/supervisorUsers`,
    },
      { apiName: this.apiName });

  getActiveInspectionSupervisors = (role:number) =>
    this.restService.request<any, EmployeeDto[]>({
      method: 'GET',
      url: `/api/app/sPGAAdmin/activeInspectionSupervisors`,
      params: { role: role }
    },
      { apiName: this.apiName });
  
  getSupervisorsByEmployee = (employee: number) =>
    this.restService.request<any, EmployeeDto[]>({
      method: 'GET',
      url: `/api/app/sPGAAdmin/supervisors`,
      params: { employee: employee },
    },
    { apiName: this.apiName });

  getadminsPagedListByInput = (input: GetAllAdminsInputDto) =>
    this.restService.request<any, PagedResultDto<AdminDto>>({
      method: 'GET',
      url: `/api/app/sPGAAdmin/adminsPagedList`,
      params: { identificationNumber: input.identificationNumber, roleFilter: input.roleFilter, permissionFilter: input.permissionFilter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  registerAdmin = (input: NewAdminRegisterDto) =>
    this.restService.request<any, NewAdminRegisterResponseDto>({
      method: 'POST',
      url: `/api/app/sPGAAdmin/registerAdmin`,
      body: input,
    },
    { apiName: this.apiName });

  setAdminPasswordByModel = (model: SetAdminPasswordDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/sPGAAdmin/setAdminPassword`,
      body: model,
    },
    { apiName: this.apiName });

  updateAdmin = (identificationNumber: string, input: UpdateAdminDto) =>
    this.restService.request<any, UpdateAdminDto>({
      method: 'PUT',
      url: `/api/app/sPGAAdmin/admin`,
      params: { identificationNumber: identificationNumber },
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
