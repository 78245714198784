import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { SpgaCaptcha } from '../../models/spgaCaptcha';
import { CaptchaService } from '@proxy/captcha';
import { CaptchaActions, CaptchaNotifier } from '../../services/captchaNotifier';
import { Observable } from 'rxjs';
import { CaptchaDto } from '../../proxy/captcha/model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})


export class CaptchaComponent implements OnInit {
  constructor(private captchaService: CaptchaService, private captchaNotifier: CaptchaNotifier) {}
  captchaValue: string | undefined;
  spgaCaptcha: SpgaCaptcha = new SpgaCaptcha();
  captchaImageBase64: string = '';

  @Input() maxLength = 6;
  @Input() clearCaptchaInput = false;
  @Output() onCodeChanges: EventEmitter<SpgaCaptcha> = new EventEmitter();
  ngOnInit(): void {
    this.captchaNotifier.notifier.subscribe(data => {
      if (data == CaptchaActions.ResetCaptcha) this.resetCaptcha();
    });
    this.getCaptcha();
  }

  getCaptcha() {
    this.captchaService.generate().subscribe(data => {
      this.spgaCaptcha.codeId = data.id;
      this.captchaImageBase64 = 'data:image/gif;base64,' + data.captchaImageBase64;
    });
  }
  resetCaptcha(){
    this.spgaCaptcha = new SpgaCaptcha();
    this.captchaValue = '';
    this.getCaptcha();
  }
  codeChanged(code: string) {
    if (code.length == this.maxLength) {
      this.spgaCaptcha.code = code;
      this.spgaCaptcha.isCaptchaReady = true;
    } else {
      this.spgaCaptcha.isCaptchaReady = false;
    }
    this.onCodeChanges?.emit(this.spgaCaptcha);
  }
}
