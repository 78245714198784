import { APP_INITIALIZER, NgModule } from '@angular/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxValidateCoreModule, VALIDATION_BLUEPRINTS } from '@ngx-validate/core';
import { CoreModule } from '@abp/ng.core';
import { AgmCoreModule } from '@agm/core';
import { NgbCalendar, NgbCalendarIslamicCivil, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule } from '@abp/ng.theme.shared';

import { AppConfigService } from '@spga-shared/providers/configuration';
import { CommonAppComponent } from './common-app.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import {
  CheckAttachmentsComponent,
  CustomFileUploadComponent,
  DatepickerRangeComponent,
  GoBackComponent,
  MapComponent,
  QueryMessagePopupComponent,
  StatementPreviewComponent,
  StaticMapComponent,
  UploaderPopupComponent,
  ListComponent,
  CardComponent,
  PageNotFoundComponent,
  defaultErrorPage,
  CaptchaComponent
} from '@spga-shared/components';
import { ArabicNumbersPipe, CustomDatePipe } from '@spga-shared/pipes';
import { ClickStopPropagationDirective, NumbersOnlyInputDirective, InputRestrictionDirective, numbersWithoutCommaInputDirective } from '@spga-shared/directives';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppAuthService, IslamicI18n, LanguageUpdateService, LoginGuard, TransferPublicRequestGuard } from '@spga-shared/providers';
import { environment } from '../environments/environment';
import { NgxPrintModule } from 'ngx-print';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ShpFileComponent } from './components/shp-file/shp-file.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './providers/interceptors/interceptor.service';
import { CaptchaNotifier } from './services/captchaNotifier';
import { RequestsDetailsModalComponent } from './components/requests-details-modal/requests-details-modal.component';

export const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

const declarations = [
  CommonAppComponent,
  CustomFileUploadComponent,
  UploaderPopupComponent,
  DatepickerRangeComponent,
  CheckAttachmentsComponent,
  MapComponent,
  QueryMessagePopupComponent,
  StaticMapComponent,
  ArabicNumbersPipe,
  CustomDatePipe,
  ClickStopPropagationDirective,
  NumbersOnlyInputDirective,
  numbersWithoutCommaInputDirective,
  InputRestrictionDirective,
  PageNotFoundComponent,
  defaultErrorPage,
  StatementPreviewComponent,
  GoBackComponent,
  ListComponent,
  CardComponent,
  ShpFileComponent,
  CaptchaComponent,
  RequestsDetailsModalComponent
];

const imports = [
  CoreModule,
  ThemeSharedModule,
  ThemeBasicModule,
  NgxPrintModule,
  NgxValidateCoreModule,
  NgbModule,
  NgSelectModule,
  MatSelectModule,
  MatFormFieldModule,
  NgMultiSelectDropDownModule.forRoot(),
];

const providers = [
  CaptchaNotifier,
  AppConfigService,
  AppAuthService,
  LanguageUpdateService,
  LoginGuard,
  TransferPublicRequestGuard,
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [AppConfigService]
  },
  {
    provide: VALIDATION_BLUEPRINTS,
    useValue: {
      email: 'هذا الحقل لا يمثل عنوان بريد إلكتروني صالح.',
      max: 'هذا الحقل يقبل قيمة لاتزيد على {{ max }}.',
      maxlength: 'هذا الحقل يجب أن لا يتعدى طوله {{ requiredLength }}.',
      min: 'هذا الحقل يقبل قيمة أقل من {{ min }}.',
      minlength: 'هذا الحقل يجب أن لا يقل طوله عن {{ requiredLength }}.',
      pattern: 'غير صحيح قم باتباع النمط',
      required: 'هذا الحقل إجباري.',
      passwordMismatch: 'Passwords do not match.',
      invalidPassword: 'Password should include {{ description }}.',
      mobileNumber: 'رقم الجوال غير صحيح قم باتباع النمط 05XXXXXXXX',
      crNumber: 'يجب إدخال رقم الهوية بشكل صحيح وألا يزيد عن 10 أرقام',
      area:'عفوا، قيمة المساحة يجب ان تساوي القيمة المدخلة من مقدم الطلب',
      arabicCharactersOnly: 'هذا الحقل  يجب ان يكون حروف عربية فقط',
      arabicLettersNumbersOnly: 'هذا الحقل  يجب ان يكون حروف وأرقام عربية فقط',
      noEnglishLettersValidator: 'هذا الحقل  يجب ان يكون حروف وأرقام عربية ورموز فقط',
      userName: 'هذا الحقل يقبل حروف عربية أو انجليزية فقط',
      instrumentNo: 'يجب ادخال رقم صك صحيح مكون من 10 رقم',
      agencyNo: 'يجب ادخال رقم وكالة صحيح وألا يزيد عن 10 أرقام',
      noSpecialCharactersValidator: 'هذا الحقل يقبل حروف أو أرقام عربية او انجليزية فقط',
      areaFiled: 'يجب إدخال قيمة المساحة بشكل صحيح',
    }
  },
  { provide: NgbCalendar, useClass: NgbCalendarIslamicCivil },
  { provide: NgbDatepickerI18n, useClass: IslamicI18n }
];


@NgModule({
  declarations: [
    declarations,
  ],
  imports: [
    imports,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-MKBN3H2'
    }),
    AgmCoreModule.forRoot({
      apiKey:((window as any).dynamic_admin_env ?? (window as any).dynamic_public_env).googleAPIKeys?.dynamicMapsJS
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true } ],
  bootstrap: [CommonAppComponent],
  exports: [
    declarations,
    imports,
    GoogleTagManagerModule,
    AgmCoreModule,
    StatementPreviewComponent,
    NgMultiSelectDropDownModule
  ]
})
export class CommonSharedModule {
  // tslint:disable-next-line:typedef
  static forRoot() {
    return {
      ngModule: CommonSharedModule,
      providers: [providers]
    };
  }
}
