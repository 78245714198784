import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RealEstateType } from '../model';
import { RealEstateTypeService } from '@proxy/lookup/';

@Injectable({
  providedIn: 'root'
})
export class RealEstateTypeApi {
  constructor(private realEstateTypeService: RealEstateTypeService) {
  }

  getRealEstateTypes(): Observable<RealEstateType[]> {
    return this.realEstateTypeService.getList().pipe(
      map((res) => {
        return res.items!;
      })
    );
  }
}
