import { RequestAreaReportDetailsDto, RequestAreaReportDto, RequestReportResponse, UpdateAreaReportCoordinatesDto } from '@proxy/real-estate-ownership-requests/models';

export interface RequestAreaReport extends RequestAreaReportDto {
}

export interface RequestReportRes extends RequestReportResponse {
}

export interface RequestAreaReportDetails extends RequestAreaReportDetailsDto {
}

export interface UpdateAreaReportCoordinates extends UpdateAreaReportCoordinatesDto {
}