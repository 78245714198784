<!-- <div class="mapViewNode" id="#mapViewNode"></div> -->
 <div class="container">
    <div class="row justify-content-start mb-3 mt-3">
        <div class="col-lg-12">
            <div id="mapCanvas" class="shadow">
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg-4 my-auto ps-5 mx-auto">
            <label class="main-text fw-bold my-1">رقم الطلب </label>
            <input class="form-control" type="text" id="Request_No" aria-label="Disabled input" value="34588"
                disabled />
        </div>
    </div> -->
    <!-- <div class="row">
        <div class="col-lg-4 my-auto ps-5 mt-2 mx-auto">
            <label id="region_label" class="main-text fw-bold my-1">المنطقة :  </label>
            <label id="region_value" class="main-text fw-bold my-1" #region> لا يوجد </label>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 my-auto ps-5 mx-auto">
            <label id="area_label" class="main-text fw-bold my-1"> المساحة : </label>
            <label id="area_value" class="main-text fw-bold my-1" #area>لا يوجد </label>
        </div>
    </div> -->
  
    <div class="row">
        <div class="col-lg-6 my-auto ps-5 mx-auto mt-3 text-center" >
            <form enctype="multipart/form-data" method="post" id="uploadForm" class="d-none">
                <div class="field">
                    <label class="file-upload custom-file-upload btn btn-outline-primary" for="inFile">

                        <span class="">Upload Shapefile</span>

                        <input type="file" name="file" id="inFile" />
                    </label>
                </div>
            </form>
            <span class="file-upload-status" style="opacity:1;" id="upload-status"></span>
            <div id="fileInfo">&nbsp;</div>
            <div class="text-center">
                <h6 class="text-danger italic" id="mapError"></h6>
            </div>
            <!-- <button id="SaveShape" class="btn btn-outline-primary fw-bold" type="button" onclick="FunSaveShape();" (click)="onSubmit()"
                disabled>ارسال الطلب</button> -->
        </div>
    </div>


</div>