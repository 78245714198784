import { AttachmentType } from '@proxy/enums';
import {AreaReportAttachmentDto, RealEstateAttachmentDto,RequestRealEstateAttachmentDto,RequestRealEstateReportAttachmentDto} from '@proxy/real-estate-attachments'
import {RequestReportAttachmentResponse} from '@proxy/real-estate-ownership-requests'
export interface RealEstateAttachment extends RealEstateAttachmentDto {
}

export interface RequestRealEstateAttachment extends RequestRealEstateAttachmentDto {
}
export interface RequestRealEstateReportAttachment extends RequestRealEstateReportAttachmentDto {
}

export interface RequestReportAttachmentRes extends RequestReportAttachmentResponse {
}
export interface AreaReportAttachment extends AreaReportAttachmentDto {
}

export interface AttachmentInput {
    attachmentType: AttachmentType;
    desc?:string;
    title: string;
    optional?: boolean;
    attachment?: AreaReportAttachmentDto;
    count?: number;
  }