import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DelayRequestService {
  apiName = 'Default';

  create = (requestId: number) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/delayRequest`,
      params: { requestId: requestId },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
