import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PublicUsersBillsApi } from '../api';
import { PublicUserBill } from '../model';
import { Repository } from './repository';

@Injectable({
  providedIn: 'root',
})
export class PublicUserBillRepository implements Repository<any, any, any> {

  constructor(private publicUsersBillsApi: PublicUsersBillsApi) {
  }

  getpublicuserBill(): Observable<PublicUserBill> {
    return this.publicUsersBillsApi.getPublicUserBillValue();
  }

  update(id: number, input: PublicUserBill): Observable<boolean> {
    return this.publicUsersBillsApi.updatePublicUserBillValue(input);
  }
}
