import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    trigger('popupAnimation', [
      state('void', style({ transform: 'scale(0.5)', opacity: 0 })),
      state('enter', style({ transform: 'scale(1)', opacity: 1 })),
      state('leave', style({ transform: 'scale(0.5)', opacity: 0 })),
      transition('* => *', animate('200ms')),
    ]),
  ],
})
export class PopupComponent implements OnInit {
  animationState = 'enter';
  @ViewChild('content') content: any;
  @Input() title: any;
  @Input() imgUrl: any;

  @Output() openEvent = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal) {}
  ngOnInit(): void {}
  closeModal(): void {
    this.modalService.dismissAll();
  }

  open(): NgbModalRef {
    console.log(this.content);
    this.openEvent.emit(true)
    return this.modalService.open(this.content, {
      ariaLabelledBy: 'modal-basic-title',
    });
 
  }
}
