import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-request-btn',
  templateUrl: './add-request-btn.component.html',
  styleUrls: ['./add-request-btn.component.scss']
})
export class AddRequestBtnComponent implements OnInit {
  @Input() label?: string;
  @Input() isForMobile?= false;
  @Output() LoginClick = new EventEmitter<any>();
  resources: any;

  constructor(private router: Router, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.http.get('assets/data/resources.json').subscribe((value: any) => {
      this.resources = value;
    });
  }

  onClickButton(event: Event): void {
    this.LoginClick.emit(event);
    this.router.navigate(['/', 'real-estate']);
  }

  // tslint:disable-next-line:variable-name
  addRequestClick(_event: Event): void {
  }
}
