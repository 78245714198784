import { mapEnumToOptions } from '@abp/ng.core';

export enum ReviewCommitteeRecommendations {
  NotToOwnDecision = 1,
  NotToOwnDescisionWithReason = 2,
  OwnDecision = 3,
  OwnDecisionWithReason = 4,
  WrongNotToOwnDecisionWithReason = 5,
  WrongOwnDecisionWithReason = 6,
  RollbackToGeneralDirectorateCommittees = 7,
  RollbackToConsiderationCommittee = 8,
  FullTitling = 89,
  OwningPartOfThePropertyWithAnArea = 90,
  NotFullyTitling = 91,
}

export const reviewCommitteeRecommendationsOptions = mapEnumToOptions(ReviewCommitteeRecommendations);
