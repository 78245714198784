import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Repository } from './repository';
import { ToasterService } from '@abp/ng.theme.shared';
import { Router } from '@angular/router';
import { DelayRequestApi } from '../api';
import { DelayRequest } from '../model';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import Status = Confirmation.Status;

@Injectable({
  providedIn: 'root'
})
export class DelayRequestRepository implements Repository<any, any, any> {

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private delayRequestApi: DelayRequestApi,
    private toaster: ToasterService)
  {
  }
  
  create(input: DelayRequest): Observable<void> {
    return new Observable<void>((observer) => {
      this.confirmationService.warn(
        "هل ترغب بتأكيد طلب التريث؟",
        "تنبيه"
      ).subscribe(value => {
        if (value === Status.confirm) {
          this.delayRequestApi.Create(input)
            .pipe(
              tap((res) => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/real-estate-requests/requests']);
                this.toaster.success('تم طلب التريث بالإزالة بنجاح');
              })
            ).subscribe({
              next: () => {
                observer.next();
                observer.complete();
              },
              error: (err) => {
                observer.error(err);
              }
            });
        } else {
          observer.complete();
        }
      });
    });
  }
}
