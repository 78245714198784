import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@abp/ng.core';
import { Router } from '@angular/router';
import { AppAuthService } from '@spga-shared/providers/auth';
import { RealEstateRequestOwnerRepository } from '@data/repository';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  LoginLabel = 'تسجيل الدخول';
  addRequestLabel = 'تقديم طلب';
  isLoggedIn = false;
  @Input() isRequestLayout = false;
  isCreateRequestEnabled:boolean=false;
  public isMenuCollapsed = true;
  currentUser: any;

  constructor(private authService: AppAuthService, private authServiceabp: AuthService, private router: Router,private realEstateRequestOwnerRepository:RealEstateRequestOwnerRepository) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.checkIfLoggedIn();
    this.realEstateRequestOwnerRepository.isCreateNewRequestEnabled().pipe().subscribe((res)=>{
      this.isCreateRequestEnabled=res;
    });
    this.currentUser = this.authService.currentLoggedInUser$;
  }

  onLoginClick(e: MouseEvent) {
    this.authServiceabp.navigateToLogin();
  }

  navigateTo() {
    this.router.navigate(['/']);
  }
}
