import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublishAdsRequest, RealEstateRequestAds ,RealEstateRequestAdsFilters } from '../model';
import { AdsAppServicesService } from '@proxy/ads/ads-app-services.service';
import type { PagedResultDto } from '@abp/ng.core';

@Injectable({
  providedIn: 'root'
})
export class RealEstateRequestAdsApi {
  constructor(private realEstateRequestAdsService: AdsAppServicesService) {
  }

  publishRealEstateRequestAds(input: PublishAdsRequest): Observable<boolean> {
    return this.realEstateRequestAdsService.publish(input).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getRealEstateRequestList(input:RealEstateRequestAdsFilters):Observable<PagedResultDto<RealEstateRequestAds>>{
    return this.realEstateRequestAdsService.getAdsList(input).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
