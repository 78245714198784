import {
  AreaReportCordinatesDto,
  GetAllRequestsForExcelInput,
  GetAllRequestsInputDto,
  ModifyRequestDependOnStatementDto,
  RealEstateRequestSubmitDto,
  RequestDto,
  RequestOwnershipDTO,
  RequestRealEstateEndorsement,
  RequestResponse,
  RequestsListDto,SpecializationMemberAttachmentResponse
} from '@proxy/real-estate-ownership-requests/models';
import { RequestRealestateDTO } from '@proxy/real-estates/models';
import { FileContentResult } from '@proxy/microsoft/asp-net-core/mvc';
import { SpecializationMemberNotesDto } from '@proxy/specialization-member/models';
export interface Request extends RequestDto {
}

export interface RequestsList extends RequestsListDto {
}

export interface RequestsFilterInput extends GetAllRequestsInputDto {
}

export interface AllRequestsForExcelInput extends GetAllRequestsForExcelInput {
}

export interface RequestFileContentResult extends FileContentResult {
}

export interface RequestOwnership extends RequestOwnershipDTO {
}

export interface RequestRealestateLocation extends RequestRealestateDTO {
}

export interface AreaReportCordinates extends AreaReportCordinatesDto {
}

export interface RequestRes extends RequestResponse {
}

export interface RequestRealEstateEndors extends RequestRealEstateEndorsement {
}

export {
  InspectionMemberNotesDto as InspectionMemberNotes,
  ShariaMemberNotesDto as ShariaMemberNotes,
  InspectionSupervisorNotesDto as InspectionSupervisorNotes,
  TechnicalMemberNotesDto as TechnicalMemberNotes
} from '@proxy/real-estate-ownership-requests/models';

export interface RealEstateRequestSubmitInput extends RealEstateRequestSubmitDto {
}
export interface ModifyRequestDependOnStatementInput extends ModifyRequestDependOnStatementDto {
}

export interface SpecializationMemberNotes extends SpecializationMemberNotesDto {
 
} 
export interface SpecializationMemberAttachmentRes extends SpecializationMemberAttachmentResponse {
}