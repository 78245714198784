<div class="container-fluid px-0">
  <div class="app-wrapper">
    <div class="header">
      <div class="header__logo">
        <abp-logo></abp-logo>
      </div>
    </div>
    <div class="app-content">
      <h1 class="app-title">الصفحة غير موجودة</h1>
      <p class="app-sub-title text-semi-dark">الصفحة التي تبحث عنها غير موجودة او ربما تم حذفها من الموقع</p>
      <img alt="404" class="img-fluid not-found-img" src="assets/images/404.svg">
      <a class="btn btn-outline-secondary" href="/">العودة للرئيسية</a>
    </div>
  </div>
</div>
